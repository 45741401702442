<template>
    <textarea
      v-bind:placeholder="title"
      class="
        border-solid
        p-2
        w-full
        text-left
        focus:outline-none
        block
        z-10
        custom_menu_texarea
      "
      @input="$emit('update:modelValue', $event.target.value)"
      :required="isRequired"
      :value="cacheValue"
      :disabled="isDisabled"
      :maxlength="maxLength"
    />
</template>

<script>
export default {
    props: {
        title: { type: String, require: false },
        cacheValue: { type: String, require: false, default: '' },
        isRequired: { type: Boolean, default: false, require: false },
        isDisabled: { type: Boolean, require: false, default: false },
        maxLength: { type: Number, require: false }
    },
    data() {
        return {
            show: false
        }
    }
}
</script>

<style>
textarea[disabled]{
    background-color: #E8F0FF;
    border: none;
}
    textarea:focus{
        border: 1px solid #0D82E3;
        box-shadow: 0px 4px 7px 0px #DAE7FF;
    }
.custom_menu_texarea {
    border: 1px solid #CBDDFF;
    box-sizing: border-box;
    border-radius: 10px;
    outline: none;
    min-height: 40px;
}
</style>
