<template>
    <div class="flex-1 page-container">
        <div class="main-menu bg-white h-full py-24 px-20">
            <div class="main-menu-title">{{textDisplay.mainMenu.title}}</div>
            <div class="flex flex-wrap justify-center mt-16">
                <div class="main-menu-item cursor-pointer mr-16 mb-16">
                    <router-link
                        :to="textDisplay.mainMenu.menuSearchSummary.to"
                        class="h-full flex flex-col justify-center items-center"
                        >
                        <img src="../../../assets/main_menu_search_icon.png" alt=""/>
                        <label class="mt-11">{{textDisplay.mainMenu.menuSearchSummary.label}}</label>
                    </router-link>
                </div>
                <div class="main-menu-item cursor-pointer mr-16 mb-16">
                    <router-link
                        :to="textDisplay.mainMenu.menuCreateSummary.to"
                        class="h-full flex flex-col justify-center items-center">
                        <img src="../../../assets/menu_create_summary.svg" alt=""/>
                        <label class="mt-11">{{textDisplay.mainMenu.menuCreateSummary.label}}</label>
                    </router-link>
                </div>
                <div class="main-menu-item cursor-pointer flex flex-col justify-center items-center" v-if="store.state.user.authority === 1">
                    <router-link
                        :to="textDisplay.mainMenu.menuUser.to"
                        class="h-full flex flex-col justify-center items-center">
                        <img src="../../../assets/menu_user.svg" alt=""/>
                        <label class="mt-11">{{textDisplay.mainMenu.menuUser.label}}</label>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { textDisplay } from '../../../common/TextDisplay'
import { useStore } from 'vuex'

export default {
    name: 'MainMenuConponent',
    data() {
        return {
            textDisplay: textDisplay,
            store: useStore()
        }
    }
}
</script>

<style scoped>
    .main-menu {
        border-radius: 20px;
    }

    .main-menu-title {
        color: #015DB2;
        font-size: 30px;
        font-weight: bold;
    }
    .main-menu-item {
        min-width: 228px!important;
        height: 228px;
        background: #FFFFFF;
        box-shadow: 0px 5px 10px rgba(10, 102, 194, 0.2);
        border-radius: 20px;
    }
    .main-menu-item img {
        width: 65px;
        height: 65px;
    }
    .main-menu-item:hover {
        background-color: rgb(232, 240, 255, 0.7);
    }
    .main-menu-item label {
        color: #015DB2;
        font-weight: bold;
        font-size: 20px;
        letter-spacing: -0.02em;
        display: block;
    }
</style>
