import axios from 'axios'
import { ACTION_TYPE } from './constants'
import { NotificationMessage } from './NotificationMessage'
import { textDisplay } from './TextDisplay'
import store from '@/store/index'
import router from '../router/index'
/**
 * @module interceptors
 */

/**
 * @method getAxiosAuthHeader
 * @description ハンドルエラーレスポンス
 * @param {any} error - apiの応答がOKでない場合のエラー
 * @return {promise} エラー
 */
const getAxiosAuthHeader = () => {
    const user = store.state.user
    if (user.access_token !== '') {
        return {
            authorization: `Bearer ${user.access_token}`
        }
    } else {
        return {}
    }
}

/**
 * @method handleError
 * @description エラー応答を処理する
 * @param {any} error - API応答が正常でない場合のエラー
 * @return {promise} エラー
 */
const handleError = (error) => {
    if (error.response.status === 401 && error.response.data.message === 'invalid token') {
        NotificationMessage('Your session has been expired', 3000, ACTION_TYPE.error)
        store.dispatch('sessionExpired').then(result => {
            router.push('/auth/login')
        })
        return
    }
    if (error.response.data.message.user_id) {
        NotificationMessage(error.response.data.message.user_id, 3000, ACTION_TYPE.error)
    } else if (error.response.data.message) {
        if (error.response.data.message !== 'invalid password') {
            NotificationMessage(error.response.data.message, 3000, ACTION_TYPE.error)
        }
    } else {
        NotificationMessage(textDisplay.error, 3000, ACTION_TYPE.error)
    }
    return Promise.reject(error)
}

// Add a request interceptor
axios.interceptors.request.use((config) => {
    config.headers = {
        ...getAxiosAuthHeader(),
        'Content-Type': 'application/json'
    }
    return config
}, (error) => {
    return handleError(error)
})

// Add a response interceptor
axios.interceptors.response.use((response) => {
    return response
}, (error) => {
    return handleError(error)
})
