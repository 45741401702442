<template>
    <div class="table-container">
        <div class="table-head">
            <div class="thead-row row flex justify-between items-center">
                <div class="thead-th col-1 no-padding-left" v-if="table_type === 1 || table_type === 0"></div>
                <div class="thead-th no-padding-left" v-for="(header, index) in headers"
                     :key="index"
                     :class="[{'no-padding-right': index === headers.length - 1}, header.classCol ? header.classCol : 'col']">{{ header.title }}</div>
                <div class="thead-th text-center col-3 no-padding-right" v-if="table_type === 2">{{ textDisplay.tableLastColumnTitle }}</div>
            </div>
        </div>
        <div class="table-body" :style="{maxHeight: maxHeight + 'px'}" :class="{'overflow-y-auto': maxHeight > 0}">
            <div class="tbody-row row" v-for="(item, index) in data" :key="index">
                <div class="tbody-td flex justify-center items-center col-1 no-padding-left"
                     v-if="table_type === 1">
                    <label class="flex justify-center items-center cursor-pointer"
                    ><input
                        type="radio"
                        id="regular"
                        :name="tableName"
                        @click="radioClick(index)"
                        :checked="radioSelectedIndex === index"
                    /></label>
                </div>
                <div class="tbody-td flex justify-center items-center col-1 no-padding-left"
                     v-if="table_type === 0">
                    <label class="flex justify-center items-center cursor-pointer rounded-full"
                    ><input
                        type="checkbox"
                        class="rounded-full"
                        id="regular"
                        :name="tableName"
                        @click="checkboxClick(index, $event.target.checked)"
                        :checked="checkboxSelectedList.includes(index)"
                    /></label>
                </div>
                <div class="tbody-td no-padding-left" v-for="(header, i) in headers"
                     :key="i"
                     :class="[{'no-padding-right': i === headers.length - 1},
                     header.classCol ? header.classCol : (header.numCol ? 'col-' + countColNumberColumn(table_type, header.numCol) : 'col' )]">
                    <div class="content-col-wrap h-full flex items-center" v-if="header.type === 1" :id="header.field">
                        <div class="content-col relative">
                            {{ item[header.field] && header.maxLength && item[header.field].length > header.maxLength ?
                            item[header.field].substring(0,header.maxLength) + "..." : item[header.field]}}
                        </div>
                    </div>
                    <div class="content-col-wrap h-full flex items-center" v-if="header.type === 6" :id="header.field">
                        <div class="content-col relative">
                            <a :href="item[header.field]" target="_blank">
                                {{ item[header.field] && header.maxLength && item[header.field].length > header.maxLength ?
                                item[header.field].substring(0,header.maxLength) + "..." : item[header.field]}}
                            </a>
                        </div>
                    </div>
                    <div class="content-col-wrap h-full flex items-center min-h-[25px]"
                    @click="clickRow(index)" v-if="header.type === 5" :id="header.field">
                        <div class="content-col relative" v-if="item[header.field] && header.maxLengthEn && !ja2Bit(item[header.field])">
                            {{ item[header.field].length > header.maxLengthEn ?
                            item[header.field].substring(0,header.maxLengthEn) + "..." : item[header.field]}}
                        </div>
                        <div class="content-col relative" v-if="item[header.field] && (!header.maxLengthEn || ja2Bit(item[header.field]))">
                            {{ header.maxLength && item[header.field].length > header.maxLength ?
                            item[header.field].substring(0,header.maxLength) + "..." : item[header.field]}}
                        </div>
                    </div>
                    <div v-if="header.type === 3" class="h-full flex items-center">
                        <input
                            type="text"
                            class="w-full text-left"
                            :value="item[header.field]"
                            @input="input(index, header.field, $event.target.value)"
                        />
                    </div>
                    <div v-if="header.type === 4" class="h-full flex items-start">
                        <textarea
                            class="w-full text-left h-full"
                            :value="item[header.field]"
                            @input="input(index, header.field, $event.target.value)"
                            :maxlength="header.maxLength"
                        ></textarea>
                    </div>
                    <MenuDropdownComponent  v-else-if="header.type === 2"
                                            :data="textDisplay.selectoptionAutority"
                                            :selected="textDisplay.selectoptionAutority.find(option => {
                                                if(option.value === item[header.field]) return option
                                            })"
                                            :bg-color="'transparent'"
                                            width-menu="138"
                                            :isDisabled="true"/>
                </div>
                <div class="tbody-td col-3 no-padding-right flex items-center justify-center" v-if="table_type === 2">
                    <div class="flex justify-center">
                        <div
                            class="mr-2 cursor-pointer table-action text-center"
                            @click="edit(item[primarykey])"
                        >
                            {{ textDisplay.tableEditButton }}
                        </div>
                        <div
                            class="text-center"
                            :class="item[primarykey] !== this.store.state.user.user_id ? 'table-action cursor-pointer'
                            : 'disabled-button cursor-not-allowed'"
                            @click="deleteItem(item[primarykey])"
                        >
                            {{ textDisplay.tableDeleteButton }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { textDisplay } from '../../common/TextDisplay'
import MenuDropdownComponent from './menu/MenuDropdownComponent'
import { useStore } from 'vuex'

/**
 * @module TableComponent
 */
export default {
    components: { MenuDropdownComponent },
    props: {
        headers: { type: Array, require: true },
        primarykey: { type: String, require: true },
        data: { type: Array, require: false },
        table_type: { type: Number, require: false },
        maxHeight: { type: Number, require: false },
        radioSelectedIndex: { type: Number, require: false },
        checkboxSelectedList: { type: Array, require: false }
    },
    data() {
        return {
            textDisplay: textDisplay,
            store: useStore(),
            tableName: (Math.random() + 1).toString(36).substring(7)
        }
    },
    mounted() {
        this.headers.forEach((header) => {
            const div = document.getElementById(header.field)
            if (div && div.clientHeight > 50) {
                header.shrink = true
            }
        })
    },
    methods: {
        ja2Bit(str) {
            return !!(str.match(/^[\u3000-\u303f\u3040-\u309f\u30a0-\u30ff\uff00-\uff9f\u4e00-\u9faf\u3400-\u4dbf1-9]+$/))
        },

        /**
         * ラジオボタンがクリックされたらイベントを発生させる
         * @param {string | number} id -現在のテーブルデータの主キー
         */
        radioClick(id) {
            this.$emit('update:modelValue', id)
        },

        /**
         * チェックボックスボタンがクリックされた場合トリガーイベント
         * @param {string | number} index - リスト内の行インデックス
         * @param {boolean} isChecked - このチェックボックスがすでにチェックされているかどうか
         * */
        checkboxClick(index, isChecked) {
            this.$emit('click-checkbox', { index, isChecked })
        },

        /**
         * 編集ボタンがクリックされた場合のトリガーイベント
         * @param {string | number} id - 現在のテーブルデータの主キー
         * */
        edit(id) {
            this.$emit('edit-value-by-id', id)
        },

        /**
         * 削除ボタンがクリックされたときにイベントをトリガーします
         * @param {string | number} id - 現在のテーブルデータの主キー
         * */
        deleteItem(id) {
            if (id !== this.store.state.user.user_id) {
                this.$emit('delete-by-id', id)
            }
        },

        /**
         * ユーザーがタイピングしている場合、イベントをトリガーし、データを転送する。
         * @param {string | number} index - リスト内の行のインデックス
         * @param {string} field - データ中のフィールド名
         * @param {string} value - 値が入力された
         * */
        input(index, field, value) {
            this.$emit(`input-${field}`, { index, value })
        },

        /**
         * ユーザが任意の行をクリックした場合にイベントを発生させる。
         * @param {string | number} index - リスト内の行のインデックス
         */
        clickRow(index) {
            this.$emit('click-row', index)
        },

        /**
         * セルの幅を計算する
         * @param {number} tableType - テーブルの種類 (値: 1 または 0)
         * @param {number} colNum - grid' の 'span' の個数
         * @return {number} colNum - 計算前の'span'の数
         * */
        countColNumberColumn(tableType, colNum) {
            if (tableType === 1 || tableType === 0) {
                return colNum - 1
            } else if (tableType === 2) {
                return colNum - 3
            }
            return colNum
        }
    }
}
</script>

<style scoped>
    .content-col {
        width: calc(100% - 18px);
        word-break: break-word;
        overflow: hidden;
    }
    .content-col.shrink {
        max-height: 50px;
    }
    .content-col-wrap {
        width: 100%;
        position: relative;
    }
    input[type="radio"]{
        width: 18px;
        height: 18px;
    }
    input[type="checkbox"]{
        width: 18px;
        height: 18px;
    }
    .row {
        margin-right: 0px!important;
        margin-left: 0px!important;
    }
    .thead-row {
        background-color: #015DB2;
        font-size: 16px;
        color: #FBFBFB;
        padding: 10px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        font-weight: bold;
    }
    .table-body .tbody-row {
        padding: 5px 15px;
        margin-top: 1px;
        border: 1px solid #CBDDFF;
    }
    .table-body .tbody-row:nth-of-type(even) {
        background-color: #E8F0FF;
    }
    .table-body .tbody-row:last-child{
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .table-action{
        border: 1px solid #015DB2;
        border-radius: 10px;
        background-color: #fff;
        color: #015DB2;
        font-size: 16px;
        padding: 2px 10px;
        transition: all .3s ease-in-out;
        font-weight: bold;
    }
    .disabled-button{
        border: 1px solid #015DB2;
        border-radius: 10px;
        background-color: #fff;
        opacity: 0.5;
        color: #015DB2;
        font-size: 16px;
        padding: 2px 10px;
        transition: all .3s ease-in-out;
        font-weight: bold;
    }
    .table-action:hover {
        background-color: #015DB2;
        color: #FBFBFB;
    }
    input, textarea {
        background-color: inherit;
        padding-left: 5px;
        padding-right: 5px;
        border: 1px solid transparent;
    }

    input:focus, textarea:focus{
        outline: none !important;
        border-color: #015DB2;
    }
</style>
