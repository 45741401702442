<template>
  <div class="flex-1 page-container">
      <div class="w-full min-h-full h-auto bg-white rounded-2xl relative">
          <router-view />
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
    .page-container {
        padding: 20px 50px 20px 30px;
        background-color: #E8F0FF;
        max-height: 100vh;
        overflow-y: auto;
    }

</style>
