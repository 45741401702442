<template>
  <div class="flex-1 page-container">
    <div class="w-full pb-5 mb-5 bg-white rounded-2xl text-left flex-wrap flex" v-if="isShowSearchMenu">
      <div class="w-full ml-6 mt-5 font-bold flex justify-between mr-[13px] items-center">
        <label class=" text-[20px]">{{ textDisplay.labelSearchTitle }}</label>
        <div class="
                              flex
                              items-center
                              justify-center
                              transform
                              transition-transform
                              ease-in-out
                              duration-500
                               cursor-pointer">
                        <v-icon icon="icon-close-search-menu"
                        @click="hideSearchMenu"
                        id="icon-close-search-menu">
                        </v-icon>
                    </div>
      </div>
      <form
        @submit.prevent="search"
        @reset.prevent="reset"
        class="w-full flex flex-wrap justify-center relative filter-form mt-2.5"
      >
        <div class="mb-4 w-full px-[84px]">
          <div class="row no-margin-right no-margin-left">
            <div class="col no-padding-left no-padding-right">
              <div class="mb-2.5 pr-[22px]">
                <label class="mb-[5px]">{{
                  textDisplay.labelSearchDrugName
                }}</label>
                <div class="w-full">
                  <MenuInputComponent
                    v-model="store.state.formSearchSummary.drug_name"
                    :value="store.state.formSearchSummary.drug_name"
                    type="text"
                    :title="textDisplay.labelSearchDrugName"
                    :input-style="'input_bordered'"
                  />
                </div>
              </div>
              <div class="mb-2.5 pr-[22px]">
                <label class="mb-[5px]">{{
                  textDisplay.labelSearchMajorDrugName
                }}</label>
                <div class="w-full">
                  <MenuInputComponent
                    v-model="store.state.formSearchSummary.major_drug_name"
                    :value="store.state.formSearchSummary.major_drug_name"
                    type="text"
                    :title="textDisplay.labelSearchMajorDrugName"
                    :input-style="'input_bordered'"
                  />
                </div>
              </div>
            </div>
            <div class="col no-padding-left no-padding-right">
              <div class="mb-2.5 pr-[22px] pl-[22px]">
                <label class="mb-[5px]">{{
                  textDisplay.labelSearchPharmacologicalName
                }}</label>
                <div class="w-full">
                  <MenuInputComponent
                    v-model="store.state.formSearchSummary.pharmacological_name"
                    :value="store.state.formSearchSummary.pharmacological_name"
                    type="text"
                    :title="textDisplay.labelSearchPharmacologicalName"
                    :input-style="'input_bordered'"
                  />
                </div>
              </div>
              <div class="mb-2.5 pr-[22px] pl-[22px]">
                <label class="mb-[5px]">{{
                  textDisplay.labelSearchClassification
                }}</label>
                <div class="w-full">
                  <MenuInputComponent
                    v-model="store.state.formSearchSummary.classification"
                    :value="store.state.formSearchSummary.classification"
                    type="text"
                    :title="textDisplay.labelSearchClassification"
                    :input-style="'input_bordered'"
                  />
                </div>
              </div>
            </div>
            <div class="col no-padding-left no-padding-right">
              <div class="mb-[7px] pr-[22px] pl-[22px]">
                <label class="mb-[5px]">{{
                  textDisplay.labelSearchStatus
                }}</label>
                <div class="w-full">
                  <MenuDropdownComponent
                    class="h-[43px]"
                    v-model="store.state.formSearchSummary.status"
                    :data="textDisplay.selectOptionSearchStatus"
                    :placeholder="textDisplay.labelSearchSelectOptionTitle"
                    :selected="store.state.formSearchSummary.status"
                    bgColor="#fff"
                    :isBorder="true"
                  />
                </div>
              </div>
              <div class="mb-2.5 pr-[22px] pl-[22px]">
                <label class="w-full mb-[5px]">{{
                  textDisplay.labelSearchSort
                }}</label>
                <div class="w-full">
                  <MenuDropdownComponent
                    :data="textDisplay.selectOptionSearch1"
                    v-model="store.state.formSearchSummary.sort_by"
                    class="h-[46px]"
                    :placeholder="textDisplay.labelSearchSelectOptionTitle"
                    :selected="store.state.formSearchSummary.sort_by"
                    bgColor="#fff"
                    :isBorder="true"
                  />
                </div>
              </div>
            </div>
            <div class="flex items-end col-2 no-padding-left no-padding-right">
              <div class="mb-2.5 w-full pl-2">
                <MenuDropdownComponent
                  :data="textDisplay.selectOptionSearch2"
                  v-model="store.state.formSearchSummary.order_by"
                  class="h-[46px]"
                  :placeholder="textDisplay.labelSearchSelectOptionTitle"
                  :selected="store.state.formSearchSummary.order_by"
                  bgColor="#fff"
                  :isBorder="true"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="w-full text-center flex justify-center">
          <MenuButtonComponent
            type="reset"
            class="mr-4 h-11"
            @click="isClickBeforeSelect=false"
            :btn-style="'btn_bordered'"
            :title="textDisplay.buttonSearchClear"
          />
          <MenuButtonComponent
            type="submit"
            class="h-11"
            :title="textDisplay.buttonSearchSearch"
          />
        </div>
      </form>
    </div>
    <div class="w-full pb-5 mb-5 bg-white rounded-2xl text-left flex-wrap flex" v-else>
      <div class="w-full ml-6 mt-5 font-bold flex justify-between mr-[13px] items-center">
        <label>{{ textDisplay.labelSearchTitle }}</label>
        <div class="
                              flex
                              items-center
                              justify-center
                              transform
                              transition-transform
                              ease-in-out
                              duration-500
                               cursor-pointer">
                        <v-icon icon="icon-open-search-menu"
                        @click="showSearchMenu"
                        id="icon-open-search-menu">
                        </v-icon>
                    </div>
      </div>
    </div>
    <div
      class="
        w-full
        pb-5
        bg-white
        rounded-2xl
        text-left
        flex-wrap flex
        justify-center
      "
    >
      <div class="w-full mt-5 font-bold">
        <div class="ml-6">
          <label>{{ textDisplay.labelSearchResult }}</label>
        </div>
        <div class="px-[84px] w-full flex flex-wrap justify-center">
          <div class="w-3/4 error-msg mb-2.5 text-center">
            <label v-if="isClickBeforeSelect">{{
              textDisplay.labelSearchDonotSelect
            }}</label>
            <br v-else />
          </div>
          <TableComponent
            :table_type="1"
            :headers="textDisplay.tableSearchResult"
            :data="summaries"
            v-model="store.state.currentSummarySelectedIndex"
            :radioSelectedIndex="store.state.currentSummarySelectedIndex"
            primarykey="id"
            class="w-full"
            :maxHeight="500"
          />
        </div>
        <div
          class="flex justify-center mt-2.5"
          v-if="store.getters.summaries.page_count > 1"
        >
          <PaginationComponent
            :page-count="store.getters.summaries.page_count"
            :page-range="3"
            :click-handler="changePage"
            :value="store.state.currentSummaryPage"
          ></PaginationComponent>
        </div>
        <div class="w-full flex justify-center mt-[15px]">
          <MenuButtonComponent
            type="button"
            @click="editSummary"
            class="mr-4 h-11"
            :btn-style="'btn_bordered'"
            :title="textDisplay.buttonSearchEdit"
          />
          <MenuButtonComponent
            type="button"
            @click="viewSummary"
            class="mr-4 h-11"
            :title="textDisplay.buttonSearchView"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="absolute left-1/2 top-1/3 z-20" v-if="isLoading">
          <LoadingComponent />
        </div>
</template>

<script>
import { textDisplay } from '../../../common/TextDisplay'
import MenuButtonComponent from '../../common/menu/MenuButtonComponent.vue'
import MenuInputComponent from '../../common/menu/MenuInputComponent.vue'
import MenuDropdownComponent from '../../common/menu/MenuDropdownComponent'
import TableComponent from '../../common/TableComponent.vue'
import PaginationComponent from '../../common/PaginationComponent.vue'
import LoadingComponent from '../../common/LoadingComponent.vue'
import SVGIconComponent from '../../common/SVGIconComponent'
import { useStore } from 'vuex'
import { NotificationMessage } from '../../../common/NotificationMessage'
import { ACTION_TYPE } from '../../../common/constants'

/**
 * @module SearchSummaryComponent
 */
export default {
    components: {
        MenuButtonComponent,
        MenuInputComponent,
        TableComponent,
        PaginationComponent,
        LoadingComponent,
        MenuDropdownComponent,
        vIcon: SVGIconComponent
    },
    computed: {
        summaries: function () {
            if (this.store.getters.summaries.items) {
                return this.store.getters.summaries.items.map((value) => {
                    const data = {
                        id: value.id,
                        drug_name: value.drug_name,
                        classification: value.classification,
                        pharmacological_name: value.pharmacological_name,
                        major_drug_name: value.major_drug_name,
                        status: ''
                    }
                    switch (value.status) {
                    case 0:
                        data.status = '一時保存'
                        break
                    case 1:
                        data.status = '確認待ち'
                        break
                    case 2:
                        data.status = '公開済み'
                        break
                    }
                    return data
                })
            }
            return {}
        }
    },
    watch: {
        'store.state.currentSummarySelectedIndex': {
            handler(v) {
                this.isClickBeforeSelect = false
            },
            deep: true
        }
    },
    data() {
        return {
            textDisplay,
            store: useStore(),
            per_page: 10,
            isLoading: false,
            isClickBeforeSelect: false,
            isShowSearchMenu: localStorage.getItem('isSearchMenuShow') ? JSON.parse(localStorage.getItem('isSearchMenuShow')).isShow : false
        }
    },
    methods: {
        /**
         * 検索メニューを非表示にする
         */
        hideSearchMenu() {
            this.isShowSearchMenu = false
            localStorage.setItem('isSearchMenuShow', JSON.stringify({ isShow: false }))
        },

        /**
         * 検索メニューを表示する
         */
        showSearchMenu() {
            this.isShowSearchMenu = true
            localStorage.setItem('isSearchMenuShow', JSON.stringify({ isShow: true }))
        },

        /**
         * 検索フォームの全データをクリアする
         */
        reset() {
            this.store.state.formSearchSummary = {
                drug_name: '',
                pharmacological_name: '',
                major_drug_name: '',
                classification: '',
                sort_by: textDisplay.selectOptionSearch1[0],
                order_by: textDisplay.selectOptionSearch2[0],
                status: textDisplay.selectOptionSearchStatus[0]
            }
        },

        /**
         * 検索キーワードでサマリーを検索するvuexアクションを呼び出す
         */
        search() {
            this.isLoading = true
            this.store.state.currentSummaryPage = 1
            this.store.state.currentSummarySelectedIndex = -1
            this.isClickBeforeSelect = false
            this.store
                .dispatch('listSummaries', {
                    drug_name: this.store.state.formSearchSummary.drug_name,
                    pharmacological_name:
            this.store.state.formSearchSummary.pharmacological_name,
                    major_drug_name: this.store.state.formSearchSummary.major_drug_name,
                    classification: this.store.state.formSearchSummary.classification,
                    sort_by: this.store.state.formSearchSummary.sort_by.value,
                    order_by: this.store.state.formSearchSummary.order_by.value,
                    status: +this.store.state.formSearchSummary.status.value,
                    page: 1,
                    per_page: this.per_page
                })
                .then((value) => {
                    if (this.summaries.length === 0) {
                        NotificationMessage(this.textDisplay.msg_020, 3000, ACTION_TYPE.info)
                    }
                })
                .catch((e) => {
                    NotificationMessage(e.response.data.message, 3000, ACTION_TYPE.error)
                })
                .finally(() => {
                    this.isLoading = false
                })
        },

        /**
         * 次のページから要約を取得するvuexアクションを呼び出す
         * @param {number} pageNum - 現在のページインデックス
         */
        changePage(pageNum) {
            this.isLoading = true
            this.store.state.currentSummaryPage = pageNum
            this.store.state.currentSummarySelectedIndex = -1
            this.store
                .dispatch('listSummaries', {
                    drug_name: this.store.state.formSearchSummary.drug_name,
                    pharmacological_name:
            this.store.state.formSearchSummary.pharmacological_name,
                    major_drug_name: this.store.state.formSearchSummary.major_drug_name,
                    classification: this.store.state.formSearchSummary.classification,
                    sort_by: this.store.state.formSearchSummary.sort_by.value,
                    order_by: this.store.state.formSearchSummary.order_by.value,
                    status: this.store.state.formSearchSummary.status.value,
                    page: pageNum,
                    per_page: this.per_page
                })
                .then((value) => {})
                .catch((e) => {
                    NotificationMessage(e.response.data.message, 3000, ACTION_TYPE.error)
                })
                .finally(() => {
                    this.isLoading = false
                })
        },

        /**
         * 詳細サマリー画面へ
         */
        viewSummary() {
            if (this.store.state.currentSummarySelectedIndex !== '-1') {
                if (
                    this.store.getters.summaries.items[
                        this.store.state.currentSummarySelectedIndex
                    ] &&
                    this.store.getters.summaries.items[
                        this.store.state.currentSummarySelectedIndex
                    ].summary_id
                ) {
                    this.$router.push(
                        `/summary/view-summary/${
                            this.store.getters.summaries.items[
                                this.store.state.currentSummarySelectedIndex
                            ].summary_id
                        }`
                    )
                } else {
                    this.isClickBeforeSelect = true
                }
            } else {
                this.isClickBeforeSelect = true
            }
        },

        /**
         * 編集サマリー画面へ
         */
        editSummary() {
            if (this.store.state.currentSummarySelectedIndex !== '-1') {
                if (
                    this.store.getters.summaries.items[
                        this.store.state.currentSummarySelectedIndex
                    ] &&
                    this.store.getters.summaries.items[
                        this.store.state.currentSummarySelectedIndex
                    ].summary_id
                ) {
                    this.$router.push(
                        `/summary/edit-summary/${
                            this.store.getters.summaries.items[
                                this.store.state.currentSummarySelectedIndex
                            ].summary_id
                        }`
                    )
                } else {
                    this.isClickBeforeSelect = true
                }
            } else {
                this.isClickBeforeSelect = true
            }
        }
    }
}
</script>

<style scoped>
.error-msg label {
  color: #ff4e43;
  font-weight: normal;
  font-size: 16px;
}
.filter-form label {
  font-size: 16px;
  color: #1f2533;
}

.first-page {
  height: 40.4%;
  min-height: 414px;
}

.grid-width {
  width: 75%;
}

.second-page {
  height: 53.5%;
  min-height: 548px;
}

.page-container {
  padding: 25px 50px 20px 30px;
  background-color: #e8f0ff;
  max-height: 100vh;
  overflow-y: auto;
}

label {
  display: block;
}
svg#icon-open-search-menu,
    svg#icon-close-search-menu{
        width: 40px;
        height: 40px;
    }

</style>
