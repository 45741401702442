import { textDisplay } from '../common/TextDisplay'
/**
 * @module Store.Mutations
 */
export default {
    increaseCounter: (state, payload) => {
        state.counter++
    },
    decreaseCounter: (state, payload) => {
        state.counter--
    },
    setName: (state, payload) => {
        state.name = payload
    },
    updateDataTable: (state, payload) => {
        state.dataTableTest = payload
    },
    /**
     * @method setUser
     * @description state.userの現在のユーザー情報を変更します
     * @param {Object} payload
     * @param {string} payload.username
     * @param {string} payload.access_token
     * @param {string} payload.expired_at
     * @param {string} payload.authority
     * @param {string} payload.User_id
     */
    setUser: (state, payload) => {
        state.user = payload
    },

    /**
     * @method setListUser
     * @description state.userListにユーザーリストを設定する
     * @param {Array.<{username: string, access_token: string, expired_at: string, authority: string, User_id: string | number}>} payload
     * ユーザ一覧
     * */
    setListUser: (state, payload) => {
        state.userList = payload
    },

    /**
     * @method clearListUser
     * @description state.userListのuserリストをクリアする
     */
    clearListUser: (state) => {
        state.userList = {}
    },
    /**
     * @method pushUser
     * @description ユーザーをstate.userListにプッシュする
     * @param {Object} payload
     * @param {string} payload.username
     * @param {string} payload.access_token
     * @param {string} payload.expired_at
     * @param {string} payload.authority
     * @param {string} payload.user_id
     */
    pushUser: (state, payload) => {
        if (state.userList.items) {
            state.userList.items.push(payload)
        }
    },

    /**
     * @method deleteUser
     * @description state.userListのユーザーを削除する
     * @param {Object} payload
     * @param {string} payload.user_id
     * */
    deleteUser: (state, payload) => {
        state.userList.items = state.userList.items.filter((value) => {
            if (value.user_id !== payload) {
                return value
            }
        })
    },

    /**
     * @method updateUser
     * @description state.userList内のユーザーを更新する
     * @param {Object} payload
     * @param {string} payload.authority
     * @param {string} payload.user_id
     * */
    updateUser: (state, payload) => {
        for (let i = 0; i < state.userList.length; i++) {
            if (state.userList[i].user_id === payload.user_id) {
                state.userList[i].authority = payload.authority
                return
            }
        }
    },

    /**
     * @method setSummaries
     * @description サマリーセット一覧
     * @param {Array<Object>} payload
     * @param {string} payload.drug_name -医薬品名
     * @param {string} payload.drug_name - 医薬品名カナ
     * @param {string} payload.general - 一般情報
     * @param {string} payload.effect_for_anomaly - 先天異常への影響
     * @param {string} payload.effect_for_baby - 先天異常以外の胎児への影響
     * @param {string} payload.other - その他
     * @param {string} payload.placenta_passage - 胎盤通過性
     * @param {string} payload.animal_test - 動物試験
     * @param {string} payload.attached - 添付文書記載内容
     * @param {string} payload.summarize - まとめ
     * @param {string} payload.pharmacological_name - 薬理名
     * @param {string} payload.major_drug_name - 主要商品名
     * @param {string} payload.classification - 分類
     * @param {Array} payload.humanResearchData - 人間での疫学研究
     * @param {Array} payload.humanReportedData - 人間での症例報告
     * @param {Array} payload.animalResearchResultData - 動物実験結果
     * @param {Array} payload.interviewData - インタビューフォーム
     * @param {Array} payload.packageInsertData - 添付文書
     * @param {Array} payload.otherMaterialsData - その他資料
     * @param {Array} payload.motherRiskStatementData - マザーリスクステートメント
     * */
    setSummaries: (state, payload) => {
        state.summaries = payload
    },

    /**
     * @method editSummary
     * @description サマリーをなしとする。
     */
    editSummary: (state) => {
        state.summaries = {}
    },

    /**
     * @method clearGlobalState
     * @description すべての状態をデフォルト値に設定する。
     */
    clearGlobalState: (state) => {
        state.summaries = {
            items: []
        }
        state.isSummaryBeingCreate = false
        state.isSummaryBeingEdit = false
        state.isUserBeingEdit = false
        state.isUserBeingCreate = false
        state.currentSummaryPage = 1
        state.currentSummarySelectedIndex = -1
        state.formSearchSummary = {
            drug_name: '',
            pharmacological_name: '',
            major_drug_name: '',
            classification: '',
            sort_by: textDisplay.selectOptionSearch1[0],
            order_by: textDisplay.selectOptionSearch2[0],
            status: textDisplay.selectOptionSearchStatus[0]
        }
        state.formUpdateSummary = {
            id: '',
            summary_id: '',
            // Public
            drug_name: '',
            drug_kana: '',
            general: '',
            effect_for_anomaly: '',
            effect_for_baby: '',
            other: '',
            placenta_passage: '',
            animal_test: '',
            attached: '',
            summarize: '',
            update_user: '',
            update_date: '',
            update_username: '',
            version: '',
            status: '',
            // All Info
            pharmacological_name: '',
            major_drug_name: '',
            classification: '',
            humanResearchData: [],
            humanReportedData: [],
            animalResearchResultData: [],
            interviewData: [],
            packageInsertData: [],
            otherMaterialsData: [],
            motherRiskStatementData: []
        }
        state.formCreateSummary = {
            // Public
            drug_name: '',
            drug_kana: '',
            general: '',
            effect_for_anomaly: '',
            effect_for_baby: '',
            other: '',
            placenta_passage: '',
            animal_test: '',
            attached: '',
            summarize: '',
            // All Info
            pharmacological_name: '',
            major_drug_name: '',
            classification: '',
            humanResearchData: [],
            humanReportedData: [],
            animalResearchResultData: [],
            interviewData: [],
            packageInsertData: [],
            otherMaterialsData: [],
            motherRiskStatementData: []
        }
    }
}
