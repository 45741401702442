/**
 * @module UsefulFunction
 */
/**
 * @method formatDatetime
 * @description ISO 8601の日時形式をYYYY/MM/DDに変換する
 * @param {string} date: ISO 8601 (例: 2022-03-22T20:16:37)
 * @return {string} YYYY/MM/DD hh:mm:ss 形式の日付(例: 2022/03/22 20:16:37)
 */
export const formatDatetime = (date) => {
    return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`
}

/**
 * @method formatDate
 * @description 日付をYYYY/MM/DD形式に変換する。
 * @param {string} date: (例: 2022-03-22)
 * @return {string} YYYY/MM/DD hh:mm:ss 形式の日付 (例: 2022/03/22)
 */
export const formatDate = (dateString) => {
    const date = new Date(dateString)
    return `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`
}

/**
 * @method isValidHttpUrl
 * @description urlのフォーマットが有効かどうか確認する
 * @param {string} string - urlをチェックする必要があります
 * @return {boolean} url 有効か無効か
 */
export const isValidHttpUrl = (string) => {
    let url
    try {
        url = new URL(string)
    } catch (_) {
        return false
    }
    return url.protocol === 'http:' || url.protocol === 'https:'
}
