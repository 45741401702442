<template>
  <div
    class="
      py-12
      ml-40
      bg-white
      rounded-xl
      flex flex-wrap
      justify-center
      login-inner
    "
  >
    <div class="text-3xl m-auto w-full mb-12 login-title">
      {{ textDisplay.loginHeader }}
    </div>
    <form class="w-2/3" @submit.prevent="login()">
      <div
        :class="{
          'mb-6': text1 && text1.length > 0,
          'mb-12': !text1 || (text1 && text1.length === 0),
        }"
      >
        <div class="title-input-login text-left mb-2.5 font-normal">
          {{ textDisplay.userIdInputTitleLogin }}<sup>*</sup>
        </div>
        <AuthInputComponent
          type="text"
          :title="textDisplay.inputUserId"
          :isRequired="false"
          @input="userIdChange"
          v-model="form.user_id"
          :maxLength="6"
          :value="form.user_id"
        />
        <div class="mt-2" v-if="text1 && text1.length > 0">
          <div class="text-left error-msg">{{ text1 }}</div>
        </div>
      </div>
      <div
        :class="{
          'mb-6': text2 && text2.length > 0,
          'mb-12': !text2 || (text2 && text2.length === 0),
        }"
      >
        <div class="title-input-login text-left mb-2.5 font-normal">
          {{ textDisplay.passwordInputTitleLogin }}<sup>*</sup>
        </div>
        <AuthInputComponent
          type="password"
          :title="textDisplay.inputPassword"
          :isRequired="false"
          v-model="form.password"
          @input="passwordChange"
          :maxLength="10"
          :value="form.password"
        />
        <div class="mt-2" v-if="text2 && text2.length > 0">
          <div class="text-left error-msg">{{ text2 }}</div>
        </div>
      </div>
      <AuthButtonComponent type="submit" :title="textDisplay.buttonLogin" />
    </form>
    <div class="z-20 absolute inset-y-64" v-if="isLoading">
      <LoadingComponent />
    </div>
  </div>
</template>

<script>
import AuthInputComponent from '../common/auth/AuthInputComponent.vue'
import AuthButtonComponent from '../common/auth/AuthButtonComponent.vue'
import LoadingComponent from '../common/LoadingComponent.vue'
import { NotificationMessage } from '../../common/NotificationMessage'
import { ACTION_TYPE } from '../../common/constants'

import { textDisplay } from '../../common/TextDisplay'
import { useStore } from 'vuex'
/**
 * @module LoginComponent
 */
export default {
    components: { AuthButtonComponent, AuthInputComponent, LoadingComponent },
    data() {
        return {
            textDisplay: textDisplay,
            form: {
                user_id: '',
                password: ''
            },
            text1: '',
            text2: '',
            isLoading: false,
            store: useStore()
        }
    },
    methods: {
        /**
         * @async
         * @description アプリにログインする。
         */
        async login() {
            this.isLoading = true
            let isValidReq = true
            this.text1 = ''
            this.text2 = ''
            if (this.form.user_id === '') {
                isValidReq = false
                this.text1 = this.textDisplay.nullUserId
            }

            if (this.form.password === '') {
                isValidReq = false
                this.text2 = this.textDisplay.nullPassword
            }
            const user = JSON.parse(localStorage.getItem('user'))
            if (user) {
                const expiredAt = new Date(user.expired_at)
                if (new Date().getTime() < expiredAt.getTime()) {
                    this.$store.commit('setUser', user)
                    NotificationMessage(this.textDisplay.msg_logged_in, 3000, ACTION_TYPE.warning)
                    isValidReq = false
                    this.$router.push('/')
                }
            }
            if (isValidReq) {
                const isLogin = await this.store
                    .dispatch('login', this.form)
                    .then((result) => {
                        return result
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
                if (!isLogin) {
                    this.text2 = this.textDisplay.errorLogin
                } else {
                    if (
                        this.store.state.isSummaryBeingCreate ||
            this.store.state.isSummaryBeingEdit ||
            this.store.state.isUserBeingEdit ||
            this.store.state.isUserBeingCreate
                    ) {
                        this.store.state.isRenewToken = true
                        this.$router.go(-1)
                    } else {
                        this.$router.push('/')
                    }
                }
            } else {
                this.isLoading = false
            }
        },

        /**
         * @description User_idの入力の変化をキャッチする。
         */
        userIdChange() {
            if (this.form.user_id !== '') {
                this.text1 = ''
            } else {
                this.text1 = this.textDisplay.nullUserId
            }
        },

        /**
         * @description パスワードの変更入力をキャッチする。
         */
        passwordChange() {
            if (this.form.password !== '') {
                this.text2 = ''
            } else {
                this.text2 = this.textDisplay.nullPassword
            }
        }
    }
}
</script>

<style scoped>
.login-title {
  line-height: 39px;
  color: #015db2;
  font-weight: bold;
}

.title-input-login {
  color: #1f2533;
  font-size: 16px;
  font-weight: 400;
}

sup {
  color: #ff4e43;
}

.error-msg {
  color: #ff4e43;
  font-size: 16px;
  line-height: 21px;
}
</style>
