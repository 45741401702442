<template>
  <button
    :type="type"
    :class="{'btn-bordered': btnStyle === 'btn_bordered', 'custom_menu_button': !btnStyle || btnStyle !== 'btn_bordered'}"
    class="flex items-center justify-center text-center
        p-3
        border-solid
        custom_menu_button
    "
    :disabled="isDisabled"
    @click="$emit('click-menu-button')"
  >
    <span :style="isDisabled ? 'opacity: 0.3' : ''">{{ title }}</span>
  </button>
</template>

<script>
export default {
    props: {
        title: { type: String, required: true },
        type: { type: String, required: true },
        btnStyle: { type: String, required: false },
        isDisabled: { type: Boolean, required: false, default: false }
    }
}
</script>

<style scoped>
    button {
        font-style: normal;
        box-sizing: border-box;
        border-radius: 10px;
        letter-spacing: -0.02em;
        min-width: 145px;
        font-size: 16px;
    }

    .custom_menu_button {
        background-color: #015DB2;
        color: #FBFBFB;
        font-weight: bold;
    }

    .btn-bordered {
        background-color: #fff;
        color: #015DB2;
        border: 1px solid #015DB2;
        font-weight: bold;
    }
</style>
