import html2pdf from 'html2pdf.js'
import { textDisplay } from './TextDisplay'
import { formatDatetime } from './UsefulFunction'

/**
 * @module ExportPDF
 */

/**
 * @method formattedUpdateDate
 * @description datetime (ISO 8601) の updateDate を YYYY/MM/DD に変換する。
 * @param {string} updateDate - ISO 8601 (例: 2022-03-22T20:16:37)
 * @return {string} YYYY/MM/DD hh:mm:ss 形式の datetime (例: 2022/03/22 20:16:27)
*/
function formattedUpdateDate(updateDate) {
    return formatDatetime(new Date(updateDate))
}

/**
 * @method getStatusText
 * @description ステータス値によるステータス文字列の取得
 * @param {int} status: サマリーステータスのステータス値 (例: 1)
 * @return {string} status: ステータスのテキスト (例: 確認待ち)
 */
function getStatusText(status) {
    return textDisplay.selectOptionSummaryInDBStatus.find(item => {
        if (+item.value === +status) {
            return item
        }
    })
}

/**
 * @method htmlEntities
 * @description HTML形式を変換する
 * @param {string} str - html コード (例: <div>Hello</div>)
 * @return {string} str - html エンコード (例: &lt;div&gt;Hello&lt;div&gt;)
 */
function htmlEntities(str) {
    return String(str)
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
}

/**
 * @method formatText
 * @description pdf書き出し前にhtmlコードをdivで分割し、""テキストが途中で切れる ""のを防止。
 * @param {string} text - html コードを分割する必要がある (例: <div>Hello World </div>)
 * @param {number} expectedLength -div に含まれる文字の最大長
 * @return {string} 分割前のhtmlコード (例: <div>Hello</div> <div>World </div>)
 */
function formatText(text, expectedLength) {
    let res = ''
    const textArr = text.split('\n')
    for (let i = 0; i < textArr.length; i++) {
        res += `<div class="pb-2 ml-[50px] mr-8 text-[14px]" 
        style="page-break-inside: avoid; word-wrap: break-word; font-family: monospace;">${textArr[i]}</div>`
    }
    return res
}

/**
 * @method exportPublic
 * @description サマリー公開タブのpdfをエクスポート
 * @param {Object} form - 公開タブのサマリー情報
 * @param {number} form.status - ステータス
 * @param {string} form.update_username - 更新username
 * @param {string} form.update_date - 更新日
 * @param {string} form.approve_username - 承認ユーザ名
 * @param {string} form.approve_date - 承認日
 * @param {string} form.drug_name -医薬品名
 * @param {string} form.drug_kana - 医薬品名カナ
 * @param {string} form.general - 一般情報
 * @param {string} form.effect_for_anomaly - 先天異常への影響
 * @param {string} form.effect_for_baby - 先天異常以外の胎児への影響
 * @param {string} form.other - その他
 * @param {string} form.placenta_passage - 胎盤通過性
 * @param {string} form.animal_test - 動物試験
 * @param {string} form.attached - 添付文書記載内容
 * @param {string} form.summarize - まとめ
 * @return {boolean} - エクスポートに成功したか失敗した場合
 */
export const exportPublic = async (form) => {
    await html2pdf().from(`
    <div class="pb-2 flex text-[14px] flex-wrap w-full" style="color: #015DB2">
        <div class="pb-2 flex w-11/12">
          <div class="pb-2  w-2/5 flex justify-center">
            ${textDisplay.labelStatus}: ${getStatusText(form.status).title}
          </div>
          <div class="pb-2 w-3/5 flex">
            <div class="flex w-1/2">
              ${textDisplay.labelUpdatedBy}: ${form.update_username ? form.update_username : ''}
            </div>
            <div class="flex w-1/2">
              ${textDisplay.labelUpdatedAt}: ${form.update_date ? formattedUpdateDate(form.update_date) : ''}
            </div>
          </div>
        </div>
        <div class="pb-2 flex w-11/12">
        <div class="pb-2 w-2/5">
        </div>
        <div class="pb-2 w-3/5 flex">
        <div class="flex w-1/2">
          ${textDisplay.labelApprovedBy}: ${form.approve_username ? form.approve_username : ''}
        </div>
        <div class="flex w-1/2">
          ${textDisplay.labelApprovedAt}: ${form.approve_date ? formattedUpdateDate(form.approve_date) : ''}
        </div>
        </div>
      </div>
      <br>
      <br>
      <hr class="pb-2  border-blue-300 w-full">
    </div>
    <div class="pb-2  ml-[30px] mr-8 text-[16px] text-[#015DB2] font-bold">公開</div>
    <div class="pb-2  ml-[30px] mr-8 text-[16px] font-bold">医薬品名</div>
    <div class="pb-2  ml-[50px] mr-8 text-[14px]" style="page-break-inside: avoid; word-wrap: break-word;">${htmlEntities(form.drug_name)}</div>
    <br>
    <div class="pb-2  ml-[30px] mr-8 text-[16px] font-bold">医薬品名カナ</div>
    <div class="pb-2  ml-[50px] mr-8 text-[14px]" style="page-break-inside: avoid; word-wrap: break-word;">
    ${form.drug_kana ? htmlEntities(form.drug_kana) : ''}
    </div>
    <br>
    <div class="pb-2  ml-[30px] mr-8 text-[16px] font-bold">一般情報</div>
    ${form.general ? formatText(htmlEntities(form.general), 220) : ''}
    <br>
    <div class="pb-2  ml-[30px] mr-8 text-[16px] font-bold">ヒトでの主な情報</div>
    <div class="pb-2  ml-[50px] mr-8 text-[14px] font-bold">先天異常への影響</div>
    ${form.effect_for_anomaly ? formatText(htmlEntities(form.effect_for_anomaly), 220) : ''}
    <br>
    <div class="pb-2  ml-[50px] mr-8 text-[14px] font-bold">先天異常以外の胎児への影響</div>
    ${form.effect_for_baby ? formatText(htmlEntities(form.effect_for_baby), 220) : ''}
    <br>
    <div class="pb-2  ml-[30px] mr-8 text-[16px] font-bold">その他</div>
    ${form.other ? formatText(htmlEntities(form.other), 220) : ''}
    <br>
    <div class="pb-2  ml-[30px] mr-8 text-[16px] font-bold">胎盤通過性</div>
    ${form.placenta_passage ? formatText(htmlEntities(form.placenta_passage), 220) : ''}
    <br>
    <div class="pb-2  ml-[30px] mr-8 text-[16px] font-bold">動物試験</div>
    ${form.animal_test ? formatText(htmlEntities(form.animal_test), 220) : ''}
    <br>
    <div class="pb-2  ml-[30px] mr-8 text-[16px] font-bold">添付文書記載内容</div>
    ${form.attached ? formatText(htmlEntities(form.attached), 220) : ''}
    <br>
    <div class="pb-2  ml-[30px] mr-8 text-[16px] font-bold">まとめ</div>
    ${form.summarize ? formatText(htmlEntities(form.summarize), 220) : ''}
    <br>
    `).set(
        {
            margin: [10, 0, 25, 5],
            filename: `public-${formattedUpdateDate(form.update_date)}`,
            image: { type: 'jpeg', quality: 1 },
            html2canvas: {
                dpi: 96,
                letterRendering: true,
                scale: 4
            },
            pagebreak: { mode: 'avoid-all', before: '#pageX' },
            jsPDF: { format: 'A4', orientation: 'portrait' }
        }
    ).toPdf()
        .get('pdf')
        .then(function (pdf) {
            const totalPages = pdf.internal.getNumberOfPages()
            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i)
                pdf.setFontSize(10)
                pdf.setTextColor(50)
                pdf.text(
                    'Page ' + i + ' of ' + totalPages,
                    pdf.internal.pageSize.getWidth() - 105,
                    pdf.internal.pageSize.getHeight() - 15
                )
            }
        }).save()
    return false
}

/**
 * @method exportAllInfoV2
 * @description 全情報タブのサマリーをPDFで出力
 * @param {Object} form - すべての情報タブに情報を表示
 * @param {number} form.status - ステータス
 * @param {string} form.update_username - 更新ユーザ名
 * @param {string} form.update_date - 更新日
 * @param {string} form.approve_username - 承認ユーザ名
 * @param {string} form.approve_date - 承認日
 * @param {string} form.pharmacological_name - 薬理名
 * @param {string} form.major_drug_name - 主要商品名
 * @param {string} form.classification - 分類
 * @param {Array} form.humanResearchData - 人間での疫学研究
 * @param {Array} form.humanReportedData - 人間での症例報告
 * @param {Array} form.animalResearchResultData - 動物実験結果
 * @param {Array} form.interviewData - インタビューフォーム
 * @param {Array} form.packageInsertData - 添付文書
 * @param {Array} form.otherMaterialsData - その他資料
 * @param {Array} form.motherRiskStatementData - マザーリスクステートメント
 * @return {boolean} - エクスポートに成功したか失敗した場合
 */
export const exportAllInfoV2 = async (form) => {
    const htmlList = [`
    <div class="pb-2 flex text-[14px] flex-wrap w-full" style="color: #015DB2">
        <div class="pb-2 flex w-11/12">
          <div class="pb-2 w-2/5 flex justify-center">
            ${textDisplay.labelStatus}: ${getStatusText(form.status).title}
          </div>
          <div class="pb-2 w-3/5 flex">
            <div class="flex w-1/2">
              ${textDisplay.labelUpdatedBy}: ${form.update_username ? form.update_username : ''}
            </div>
            <div class="flex w-1/2">
              ${textDisplay.labelUpdatedAt}: ${form.update_date ? formattedUpdateDate(form.update_date) : ''}
            </div>
          </div>
        </div>
        <div class="pb-2 flex w-11/12">
        <div class="pb-2 w-2/5">
        </div>
        <div class="pb-2 w-3/5 flex">
        <div class="flex w-1/2">
          ${textDisplay.labelApprovedBy}: ${form.approve_username ? form.approve_username : ''}
        </div>
        <div class="flex w-1/2">
          ${textDisplay.labelApprovedAt}: ${form.approve_date ? formattedUpdateDate(form.approve_date) : ''}
        </div>
        </div>
      </div>
      <br>
      <br>
      <hr class="pb-2  border-blue-300 w-full">
    </div>
    <div class="pb-2  ml-[30px] mr-8 text-[16px] text-[#015DB2] font-bold">全情報</div>
    <br>
    <div class="pb-2  ml-[30px] mr-8 text-[16px] font-bold">1. 薬理名</div>
    <div class="pb-2  ml-[50px] mr-8 text-[14px]" style="page-break-inside: avoid; word-wrap: break-word;">
      ${form.pharmacological_name ? htmlEntities(form.pharmacological_name) : ''}
    </div>
    <br>
    <div class="pb-2  ml-[30px] mr-8 text-[16px] font-bold">2. 主要商品名</div>
    <div class="pb-2  ml-[50px] mr-8 text-[14px]" style="page-break-inside: avoid; word-wrap: break-word;">
      ${form.major_drug_name ? htmlEntities(form.major_drug_name) : ''}
    </div>
    <br>
    <div class="pb-2  ml-[30px] mr-8 text-[16px] font-bold">3. 分類</div>
    <div class="pb-2  ml-[50px] mr-8 text-[14px]" style="page-break-inside: avoid; word-wrap: break-word;">
      ${form.classification ? htmlEntities(form.classification) : ''}
    </div>
    <br>
    <div class="pb-2  ml-[30px] mr-8 text-[16px] font-bold">4. 文献的考察</div>
    <div class="pb-2  ml-[30px] mr-8 text-[16px] font-bold">4-1. 人間での疫学研究</div>
    <div class=" pb-3 pl-2 ml-[30px] mr-6 grid grid-cols-4 bg-[#015DB2] font-bold text-[#FBFBFB]">
      <div class="text-[14px] text-left">掲載誌</div>
      <div class="text-[14px] text-left">タイトル</div>
      <div class="text-[14px] text-left">著者</div>
      <div class="text-[14px] text-left">EndNoteリンク</div>
    </div>
    `]

    // Handle human research
    for (let i = 0; i < form.humanResearchData.length; i++) {
        htmlList[0] += `
        <div class=" pb-3 pl-2 ml-[30px] mr-6 grid grid-cols-4 border-2 gap-x-1">
          <div class="text-[14px] text-left" style="word-wrap: break-word;">
          ${form.humanResearchData[i].publication_magazine ? htmlEntities(form.humanResearchData[i].publication_magazine) : ''}
          </div>
          <div class="text-[14px] text-left" style="word-wrap: break-word;">
          ${form.humanResearchData[i].title ? htmlEntities(form.humanResearchData[i].title) : ''}
          </div>
          <div class="text-[14px] text-left" style="word-wrap: break-word;">
          ${form.humanResearchData[i].author ? htmlEntities(form.humanResearchData[i].author) : ''}
          </div>
          <div class="text-[14px] text-left text-[#015DB2] underline" style="word-wrap: break-word;">
          ${form.humanResearchData[i].endnote_link ? form.humanResearchData[i].endnote_link : ''}
          </div>
        </div>
        `
    }

    // Handle human reported
    htmlList[0] += `
    <br>
    <div class="pb-2  ml-[30px] mr-8 text-[16px] font-bold">4-2. 人間での症例報告</div>
    <div class=" pb-3 pl-2 ml-[30px] mr-6 grid grid-cols-4 font-bold bg-[#015DB2] text-[#FBFBFB]">
      <div class="text-[14px] text-left">掲載誌</div>
      <div class="text-[14px] text-left">タイトル</div>
      <div class="text-[14px] text-left">著者</div>
      <div class="text-[14px] text-left">EndNoteリンク</div>
    </div>
    `
    for (let i = 0; i < form.humanReportedData.length; i++) {
        htmlList[0] += `
      <div class=" pb-3 pl-2 ml-[30px] mr-6 grid grid-cols-4 border-2 gap-x-1">
        <div class="text-[14px] text-left" style="word-wrap: break-word;">
          ${form.humanReportedData[i].publication_magazine ? htmlEntities(form.humanReportedData[i].publication_magazine) : ''}
        </div>
        <div class="text-[14px] text-left" style="word-wrap: break-word;">
        ${form.humanReportedData[i].title ? htmlEntities(form.humanReportedData[i].title) : ''}
        </div>
        <div class="text-[14px] text-left" style="word-wrap: break-word;">
        ${form.humanReportedData[i].author ? htmlEntities(form.humanReportedData[i].author) : ''}
        </div>
        <div class="text-[14px] text-left text-[#015DB2] underline" style="word-wrap: break-word;">
        ${form.humanReportedData[i].endnote_link ? htmlEntities(form.humanReportedData[i].endnote_link) : ''}
        </div>
      </div>
      `
    }

    // Handle animal
    htmlList[0] += `
    <br>
    <div class="pb-2  ml-[30px] mr-8 text-[16px] font-bold">4-3. 動物実験結果</div>
    <div class=" pb-3 pl-2 ml-[30px] mr-6 grid grid-cols-4 font-bold bg-[#015DB2] text-[#FBFBFB]">
      <div class="text-[14px] text-left">掲載誌</div>
      <div class="text-[14px] text-left">タイトル</div>
      <div class="text-[14px] text-left">著者</div>
      <div class="text-[14px] text-left">EndNoteリンク</div>
    </div>
    `
    for (let i = 0; i < form.animalResearchResultData.length; i++) {
        htmlList[0] += `
      <div class=" pb-3 pl-2 ml-[30px] mr-6 grid grid-cols-4 border-2 gap-x-1">
        <div class="text-[14px] text-left" style="word-wrap: break-word;">
        ${form.animalResearchResultData[i].publication_magazine ? htmlEntities(form.animalResearchResultData[i].publication_magazine) : ''}
        </div>
        <div class="text-[14px] text-left" style="word-wrap: break-word;">
        ${form.animalResearchResultData[i].title ? htmlEntities(form.animalResearchResultData[i].title) : ''}
        </div>
        <div class="text-[14px] text-left" style="word-wrap: break-word;">
        ${form.animalResearchResultData[i].author ? htmlEntities(form.animalResearchResultData[i].author) : ''}
        </div>
        <div class="text-[14px] text-left text-[#015DB2] underline" style="word-wrap: break-word;">
        ${form.animalResearchResultData[i].endnote_link ? htmlEntities(form.animalResearchResultData[i].endnote_link) : ''}
        </div>
      </div>
      `
    }

    // Handle interview
    htmlList[0] += `
    <br>
    <div class="pb-2  ml-[30px] mr-8 text-[16px] font-bold">4-4. インタビューフォーム</div>
    <div class=" pb-3 pl-2 ml-[30px] mr-6 grid grid-cols-4 font-bold bg-[#015DB2] text-[#FBFBFB]">
      <div class="text-[14px] text-left">追記年月日</div>
      <div class="text-[14px] text-left">商品名</div>
      <div class="text-[14px] text-left">版数</div>
      <div class="text-[14px] text-left">PDFリンク先</div>
    </div>
    `
    for (let i = 0; i < form.interviewData.length; i++) {
        htmlList[0] += `
      <div class=" pb-3 pl-2 ml-[30px] mr-6 grid grid-cols-4 border-2 gap-x-1">
        <div class="text-[14px] text-left" style="word-wrap: break-word;">
        ${form.interviewData[i].postscript_date ? htmlEntities(form.interviewData[i].postscript_date) : ''}
        </div>
        <div class="text-[14px] text-left" style="word-wrap: break-word;">
        ${form.interviewData[i].product_name ? htmlEntities(form.interviewData[i].product_name) : ''}
        </div>
        <div class="text-[14px] text-left" style="word-wrap: break-word;">
        ${form.interviewData[i].version ? htmlEntities(form.interviewData[i].version) : ''}
        </div>
        <div class="text-[14px] text-left text-[#015DB2] underline" style="word-wrap: break-word;">
        ${form.interviewData[i].pdf_link ? htmlEntities(form.interviewData[i].pdf_link) : ''}
        </div>
      </div>
      `
    }

    // Handle Package Insert
    htmlList[0] += `
    <br>
    <div class="pb-2  ml-[30px] mr-8 text-[16px] font-bold">4-5. 添付文書</div>
    <div class=" pb-3 pl-2 ml-[30px] mr-6 grid grid-cols-4 font-bold bg-[#015DB2] text-[#FBFBFB]">
      <div class="text-[14px] text-left">追記年月日</div>
      <div class="text-[14px] text-left">参考頁</div>
      <div class="text-[14px] text-left">版数</div>
      <div class="text-[14px] text-left">PDFリンク先</div>
    </div>
    `
    for (let i = 0; i < form.packageInsertData.length; i++) {
        htmlList[0] += `
      <div class=" pb-3 pl-2 ml-[30px] mr-6 grid grid-cols-4 border-2 gap-x-1">
        <div class="text-[14px] text-left" style="word-wrap: break-word;">
        ${form.packageInsertData[i].postscript_date ? htmlEntities(form.packageInsertData[i].postscript_date) : ''}
        </div>
        <div class="text-[14px] text-left" style="word-wrap: break-word;">
        ${form.packageInsertData[i].reference_page ? htmlEntities(form.packageInsertData[i].reference_page) : ''}</div>
        <div class="text-[14px] text-left" style="word-wrap: break-word;">
        ${form.packageInsertData[i].version ? htmlEntities(form.packageInsertData[i].version) : ''}
        </div>
        <div class="text-[14px] text-left text-[#015DB2] underline" style="word-wrap: break-word;">
        ${form.packageInsertData[i].pdf_link ? htmlEntities(form.packageInsertData[i].pdf_link) : ''}
        </div>
      </div>
      `
    }

    // Handle Other Materials
    htmlList[0] += `
    <br>
    <div class="pb-2  ml-[30px] mr-8 text-[16px] font-bold">4-6. その他資料</div>
    <div class=" pb-3 pl-2 ml-[30px] mr-6 grid grid-cols-3 font-bold bg-[#015DB2] text-[#FBFBFB]">
      <div class="text-[14px] text-left">追記年月日</div>
      <div class="text-[14px] text-left">資料内容</div>
      <div class="text-[14px] text-left">リンク先</div>
    </div>
    `
    for (let i = 0; i < form.otherMaterialsData.length; i++) {
        htmlList[0] += `
      <div class=" pb-3 pl-2 ml-[30px] mr-6 grid grid-cols-3 border-2 gap-x-1">
        <div class="text-[14px] text-left" style="word-wrap: break-word;">
        ${form.otherMaterialsData[i].postscript_date ? htmlEntities(form.otherMaterialsData[i].postscript_date) : ''}
        </div>
        <div class="text-[14px] text-left" style="word-wrap: break-word;">
        ${form.otherMaterialsData[i].document_content ? htmlEntities(form.otherMaterialsData[i].document_content) : ''}
        </div>
        <div class="text-[14px] text-left text-[#015DB2] underline" style="word-wrap: break-word;">
        ${form.otherMaterialsData[i].destination_link ? htmlEntities(form.otherMaterialsData[i].destination_link) : ''}
        </div>
      </div>
      `
    }

    // Handle Mother risk statement
    htmlList[0] += `
     <br>
     <div class="pb-2  ml-[30px] mr-8 text-[16px] font-bold">5. マザーリスクステートメント</div>
     <div class=" pb-3 pl-2 ml-[30px] mr-6 grid grid-cols-3 font-bold bg-[#015DB2] text-[#FBFBFB]">
       <div class="text-[14px] text-left">追記年月日</div>
       <div class="text-[14px] text-left">年</div>
       <div class="text-[14px] text-left">リンク先</div>
     </div>
     `
    for (let i = 0; i < form.motherRiskStatementData.length; i++) {
        htmlList[0] += `
       <div class=" pb-3 pl-2 ml-[30px] mr-6 grid grid-cols-3 border-2 gap-x-1">
         <div class="text-[14px] text-left" style="word-wrap: break-word;">
         ${form.motherRiskStatementData[i].postscript_date ? htmlEntities(form.motherRiskStatementData[i].postscript_date) : ''}
         </div>
         <div class="text-[14px] text-left" style="word-wrap: break-word;">
         ${form.motherRiskStatementData[i].year ? form.motherRiskStatementData[i].year : ''}</div>
         <div class="text-[14px] text-left text-[#015DB2] underline" style="word-wrap: break-word;">
         ${form.motherRiskStatementData[i].destination_link ? htmlEntities(form.motherRiskStatementData[i].destination_link) : ''}
         </div>
       </div>
       `
    }
    await html2pdf().from(htmlList[0]).set(
        {
            margin: [10, 0, 25, 5],
            filename: `allinfo-${formattedUpdateDate(form.update_date)}`,
            image: { type: 'jpeg', quality: 1 },
            html2canvas: {
                dpi: 96,
                letterRendering: true,
                scale: 4
            },
            pagebreak: { mode: 'avoid-all', before: '#pageX' },
            jsPDF: { format: 'A4', orientation: 'portrait' }
        }
    ).toPdf()
        .get('pdf')
        .then(function (pdf) {
            const totalPages = pdf.internal.getNumberOfPages()
            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i)
                pdf.setFontSize(10)
                pdf.setTextColor(50)
                pdf.text(
                    'Page ' + i + ' of ' + totalPages,
                    pdf.internal.pageSize.getWidth() - 105,
                    pdf.internal.pageSize.getHeight() - 15
                )
            }
        }).save()
    return false
}
