<template>
  <ModalComponent @close="$emit('close-modal')">
    <template v-slot:body>
      <div class="mb-5 w-full ml-[68px]">
        <label class="font-bold w-full">{{
          textDisplay.labelAnimalResearchResult
        }}</label>
      </div>
      <div class="mb-5">
        <label class="w-full">{{
          textDisplay.modalAnimalResearchResult.labelYearCountry
        }}</label>
        <MenuInputComponent
          class="w-[685px] ml-[30px]"
          type="text"
          @input="$emit('input-year_country-modal', $event.target.value.trim())"
          :input-style="'input_bordered'"
          :value="year_country"
          :isDisabled="isView"
          :maxLength="256"
        />
      </div>
      <div class="mb-5">
        <label class="w-full">{{
          textDisplay.modalAnimalResearchResult.labelAuthor
        }}</label>
        <MenuInputComponent
          class="w-[685px] ml-[30px]"
          type="text"
          @input="$emit('input-author-modal', $event.target.value.trim())"
          :input-style="'input_bordered'"
          :value="author"
          :isDisabled="isView"
          :maxLength="256"
        />
      </div>
      <div class="mb-5">
        <label class="w-full">{{
          textDisplay.modalAnimalResearchResult.labelPublicationMagazine
        }}</label>
        <MenuInputComponent
          class="w-[685px] ml-[30px]"
          type="text"
          @input="$emit('input-publication_magazine-modal', $event.target.value.trim())"
          :input-style="'input_bordered'"
          :value="publication_magazine"
          :isDisabled="isView"
          :maxLength="256"
        />
      </div>
      <div class="mb-5">
        <label class="w-full">{{
          textDisplay.modalAnimalResearchResult.labelTitle
        }}<sup>*</sup></label>
        <MenuInputComponent
          class="w-[685px] ml-[30px]"
          type="text"
          @input="$emit('input-title-modal', $event.target.value.trim())"
          :input-style="'input_bordered'"
          :value="title"
          :isDisabled="isView"
          :maxLength="256"
        />
        <p v-if="isShowTitleNullMessage" class="text-red-500 text-left ml-8">
            {{ textDisplay.msg_popup_title_is_null }}
        </p>
      </div>
      <div class="mb-5">
        <label class="w-full">{{
          textDisplay.modalAnimalResearchResult.labelAnimalSpecies
        }}</label>
        <MenuInputComponent
          class="w-[685px] ml-[30px]"
          type="text"
          @input="$emit('input-animal_species-modal', $event.target.value.trim())"
          :input-style="'input_bordered'"
          :value="animal_species"
          :isDisabled="isView"
          :maxLength="256"
        />
      </div>
      <div class="mb-5">
        <label class="w-full">{{
          textDisplay.modalAnimalResearchResult.labelOutcomes
        }}</label>
        <MenuInputComponent
          class="w-[685px] ml-[30px]"
          type="text"
          @input="$emit('input-outcome-modal', $event.target.value.trim())"
          :input-style="'input_bordered'"
          :value="outcome"
          :isDisabled="isView"
          :maxLength="256"
        />
      </div>
      <div class="mb-5">
        <label class="w-full">{{
          textDisplay.modalAnimalResearchResult.labelDose
        }}</label>
        <MenuInputComponent
          class="w-[685px] ml-[30px]"
          type="text"
          @input="$emit('input-dose-modal', $event.target.value.trim())"
          :input-style="'input_bordered'"
          :value="dose"
          :isDisabled="isView"
          :maxLength="256"
        />
      </div>
      <div class="mb-5">
        <label class="w-full">{{
          textDisplay.modalAnimalResearchResult.labelResult
        }}</label>
        <MenuTextAreaComponent
          class="w-[685px] ml-[30px] h-[80px]"
          @input="$emit('input-result-modal', $event.target.value.trim())"
          :cacheValue="result"
          :isDisabled="isView"
        />
      </div>
      <div class="mb-5">
        <label class="w-full">{{
          textDisplay.modalAnimalResearchResult.labelBRTM
        }}</label>
        <MenuInputComponent
          class="w-[685px] ml-[30px]"
          type="text"
          @input="$emit('input-brtm-modal', $event.target.value.trim())"
          :input-style="'input_bordered'"
          :value="brtm"
          :isDisabled="isView"
          :maxLength="256"
        />
      </div>
      <div class="mb-5">
        <label class="w-full">{{
          textDisplay.modalAnimalResearchResult.labelMemo
        }}</label>
        <MenuTextAreaComponent
          class="w-[685px] ml-[30px] h-[80px]"
          @input="$emit('input-memo-modal', $event.target.value.trim())"
          :cacheValue="memo"
          :isDisabled="isView"
        />
      </div>
      <div class="mb-5">
        <label class="w-full">{{
          textDisplay.modalAnimalResearchResult.labelEndNoteLink
        }}</label>
        <MenuInputComponent
          class="w-[685px] ml-[30px]"
          type="text"
          @input="$emit('input-endnote_link-modal', $event.target.value.trim())"
          :input-style="'input_bordered'"
          :value="endnote_link"
          :isDisabled="isView"
          :maxLength="256"
        />
        <p class="text-red-500 text-left ml-8" v-if="isInvalidEndnoteLink">
          {{ textDisplay.modalAnimalResearchResult.labelEndNoteLink + textDisplay.msg_invalid_url }}
        </p>
      </div>
      <div class="flex justify-center">
        <MenuButtonComponent
          class="mr-5"
          btn-style="btn_bordered"
          :title="textDisplay.buttonModalCancel"
          @click="cancel"
        />
        <MenuButtonComponent
          :title="textDisplay.buttonModalSave"
          v-if="!isView"
          @click="save"
          :isDisabled="isInvalidEndnoteLink"
        />
      </div>
    </template>
  </ModalComponent>
</template>

<script>
import ModalComponent from '../../../common/ModalComponent.vue'
import MenuTextAreaComponent from '../../../common/menu/MenuTextAreaComponent.vue'
import MenuInputComponent from '../../../common/menu/MenuInputComponent.vue'
import MenuButtonComponent from '../../../common/menu/MenuButtonComponent.vue'
import { isValidHttpUrl } from '../../../../common/UsefulFunction'
import { textDisplay } from '../../../../common/TextDisplay'
import { NotificationMessage } from '../../../../common/NotificationMessage'
import { ACTION_TYPE } from '../../../../common/constants'

/**
 * @module AnimalTestModalComponent
 */
export default {
    components: {
        ModalComponent,
        MenuTextAreaComponent,
        MenuButtonComponent,
        MenuInputComponent
    },
    props: {
        year_country: { type: String, require: false },
        author: { type: String, require: false },
        publication_magazine: { type: String, require: false },
        title: { type: String, require: false },
        animal_species: { type: String, require: false },
        outcome: { type: String, require: false },
        dose: { type: String, require: false },
        result: { type: String, require: false },
        brtm: { type: String, require: false },
        memo: { type: String, require: false },
        endnote_link: { type: String, require: false },
        isView: { type: Boolean, default: false, require: false }
    },
    data() {
        return {
            textDisplay,
            isInvalidEndnoteLink: false,
            isTitleNull: this.$props.title === '',
            isShowTitleNullMessage: false
        }
    },
    watch: {
        title: function (val) {
            if (val === '') {
                this.isTitleNull = true
                this.isShowTitleNullMessage = true
            } else {
                this.isTitleNull = false
                this.isShowTitleNullMessage = false
            }
        },
        endnote_link: function (val) {
            if (!isValidHttpUrl(val)) {
                this.isInvalidEndnoteLink = true
            } else {
                this.isInvalidEndnoteLink = false
            }
        }
    },
    methods: {
        /**
         * 保存ボタンがクリックされたときのトリガーイベント
         */
        save() {
            if (this.isTitleNull) {
                this.isShowTitleNullMessage = true
                NotificationMessage(this.textDisplay.msg_popup_title_is_null, 3000, ACTION_TYPE.error)
            } else {
                this.$emit('save-modal')
            }
        },

        /**
         * キャンセルボタンがクリックされたときのトリガーイベント
         */
        cancel() {
            this.$emit('cancel-modal')
        }
    }
}
</script>

<style scoped>
   sup,
    .error-msg {
    color: #ff4e43;
    }
    sup {
    font-size: 16px;
    }
    label {
        color: #1F2533;
        font-size: 16px;
        display: block;
    }
</style>
