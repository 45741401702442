<template>
    <router-view/>
</template>
<script>
export default {
    beforeMount() {
        const user = JSON.parse(localStorage.getItem('user'))
        if (user) {
            this.$store.commit('setUser', user)
        }
    }
}
</script>
<style>
#app {
    font-family: 'DM Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #1F2533;
    overflow: hidden;
    background-color: #E8F0FF;
}

#nav {
    padding: 30px;
}

#nav a {
    font-weight: bold;
    color: #2c3e50;
}

#nav a.router-link-exact-active {
    color: #42b983;
}

.ion__svg {
    user-select: none;
}

.ion {
    vertical-align: middle;
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #2a2e31;
    border-radius: 100vh;
    border: 2px solid #edf2f7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #797e85;
}
::-webkit-scrollbar-corner {
    background-color: transparent;
}
</style>
