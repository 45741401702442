<template>
    <div class="w-full h-full list-user-container">
        <div class="m-auto w-4/5 flex-wrap flex justify-start list-user-inner">
            <menu-button-component
                @click="clickCreateUserButton"
                :title="textDisplay.buttonRouteToCreateUser"
            />
            <TableComponent
                :headers="textDisplay.tableUserListHeader"
                :data="store.getters.userList.items"
                :table_type="2"
                primarykey="user_id"
                class="w-full mt-2"
                @edit-value-by-id="editUser"
                @delete-by-id="deleteUser"
                @input-password="inputPassword"
            />
        </div>
        <div class="flex justify-center" v-if="store.getters.userList.page_count > 1">
            <pagination-component
                :page-count="store.getters.userList.page_count"
                :page-range="3"
                :click-handler="changePage"
            ></pagination-component>
        </div>
    </div>
    <div class="z-20 absolute left-1/2 top-1/3" v-if="isLoading">
      <LoadingComponent />
    </div>
</template>

<script>
import { useStore } from 'vuex'
import TableComponent from '../../common/TableComponent.vue'
import MenuButtonComponent from '../../common/menu/MenuButtonComponent'
import { textDisplay } from '../../../common/TextDisplay'
import { ConfirmBox } from '../../../common/ConfirmBox'
import { NotificationMessage } from '../../../common/NotificationMessage'
import { ACTION_TYPE } from '../../../common/constants'
import PaginationComponent from '../../common/PaginationComponent'
import LoadingComponent from '../../common/LoadingComponent.vue'

/**
 * @module ListUserComponent
 */
export default {
    components: { TableComponent, PaginationComponent, MenuButtonComponent, LoadingComponent },
    data() {
        return {
            store: useStore(),
            textDisplay: textDisplay,
            currentChange: [],
            isLoading: false,
            per_page: 20
        }
    },
    mounted() {
        this.isLoading = true
        this.store
            .dispatch('getListUser', { page: 1, per_page: this.per_page })
            .then((value) => {})
            .catch((e) => {})
            .finally(() => {
                this.isLoading = false
            })
    },
    methods: {
        /**
         * ユーザー作成画面へ
         */
        clickCreateUserButton() {
            this.$router.push('/users/create')
        },

        /**
         * ユーザー編集画面へ
         * @param {number | text} id - user_id
         * */
        editUser(id) {
            this.$router.push(`/users/edit/${id}`)
        },

        /**
         * @async
         * ユーザーを削除するためのvuexアクションを呼び出す
         * @param {number | text} id - user_id
         */
        async deleteUser(id) {
            const res = await ConfirmBox(`${id}${textDisplay.msg_006}`)
            if (res.isConfirmed) {
                this.store
                    .dispatch('deleteUser', id)
                    .then((res) => {
                        NotificationMessage(this.textDisplay.msg_delete_user_successfully, 3000, ACTION_TYPE.success)
                    })
                    .catch((e) => {
                        NotificationMessage(e, 3000, ACTION_TYPE.error)
                    })
            } else {
            }
        },
        inputPassword(data) {
        },

        /**
         * 次のページからユーザーを呼び出すためのvuexアクションを呼び出す
         * @param {number} pageNum - 現在のページインデックス"
         * */
        changePage(pageNum) {
            this.store
                .dispatch('getListUser', { page: pageNum, per_page: this.per_page })
                .then((value) => {})
                .catch((e) => {})
        }
    }
}
</script>

<style scoped>
    .list-user-inner {
        margin-bottom: 20px;
        margin-top: 15px;
    }
    .list-user-container {
        padding: 20px 0px;
    }
</style>
