<template>
    <div class="relative flex flex-row items-center input-container">
        <input
            v-bind:type="type === 'password' && show ? 'text' : type"
            v-bind:placeholder="title"
            class="p-3.5 text-left flex-1 custom_auth_input"
            :class="border"
            @input="$emit('update:modelValue', $event.target.value)"
            :required="isRequired"
            :maxlength="maxLength"
            :value="value"
        />
        <v-icon classNames="mx-3 z-20 my-1 cursor-pointer"
                v-if="type === 'password' && !show"
                @click="toggle()"
                icon="icon-eye-closed"></v-icon>
        <v-icon classNames="mx-3 z-20 my-1 cursor-pointer"
                v-if="type === 'password' && show"
                @click="toggle()"
                icon="icon-eye-opened"></v-icon>
    </div>
</template>

<script>

import SVGIconComponent from '../SVGIconComponent'

/**
 * @module AuthInputComponent
 */
export default {
    props: {
        title: { type: String, require: false },
        type: { type: String, require: true },
        value: { type: String, require: false, default: '' },
        color: { type: String, default: '', require: false },
        isRequired: { type: Boolean, default: false, require: false },
        maxLength: { type: Number, require: false }
    },
    data() {
        return {
            show: false,
            border: this.$props.color ? `border-${this.$props.color}-500` : ''
        }
    },
    methods: {
        /**
         * @description クリックするとパスワードの表示/非表示が切り替わります。
         */
        toggle() {
            this.show = !this.show
        }
    },
    components: {
        vIcon: SVGIconComponent
    }
}
</script>

<style>
    .input-container {
        background-color: #E8F0FF;
        border-radius: 10px;
        overflow: hidden;
    }

    .input-container svg{
        width: 25px;
        height: 25px;
    }

    .custom_auth_input {
        border: none;
        outline: none;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        height: 45px;
        background-color:  inherit;
    }

    .custom_auth_input::placeholder {
        color: #CDCDCD;
    }
</style>
