<template>
  <section
    @click="close"
    class="z-20 h-screen w-screen bg-gray-500 fixed top-0 left-0 opacity-50"
  ></section>
  <div class="absolute inset-0 h-screen overflow-scroll">
    <div class="flex h-full">
      <div class="m-auto bg-white p-2 rounded-[20px] shadow z-30 w-[884px]">
        <div class="p-2 text-left">
          <h1 class="text-2xl">
            <slot name="title"></slot>
          </h1>
          <main class=" mt-[50px] mb-[50px] flex justify-center flex-wrap">
            <slot name="body"></slot>
          </main>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    setup(_, { emit }) {
        function close() {
            emit('close')
        }
        return { close }
    }
}
</script>

<style>
</style>
