import Swal from 'sweetalert2'
import pdfIcon from '../assets/pdf_export.png'
import { textDisplay } from '../common/TextDisplay'
import svgSymbol from '../assets/svg_symbol.svg'

/**
 * @module ConfirmBox
 */

/**
 * @method ConfirmBox
 * @description 確認ボックス
 * @param {string} title - 確認ボックスのタイトル
 * @param {boolean} showDenyButton - 拒否ボタンの表示／非表示
 * @param {boolean} showCancelButton - 拒否ボタンの表示／非表示
 * @param {string} confirmButtonText - 確認ボタンの表示テキスト
 * @param {string} denyButtonText - 拒否ボタンの表示テキスト
 * @param {string} cancelButtonText -キャンセルボタンの表示テキスト
 * @return a {promise} 確認待ち"
 */
export const ConfirmBox = async (
    title,
    showDenyButton = false,
    showCancelButton = true,
    confirmButtonText = 'はい',
    denyButtonText = 'いいえ',
    cancelButtonText = 'いいえ'
) => {
    const html = `
        <div class="title-confirm">${title}</div>
    `
    return await Swal.fire({
        html: html,
        showDenyButton,
        showCancelButton,
        confirmButtonText,
        cancelButtonText,
        denyButtonText,
        reverseButtons: true,
        customClass: {
            popup: 'custom-container customer-confirm',
            icon: 'custom-icon',
            htmlContainer: 'custom-html',
            confirmButton: 'confirm-btn',
            cancelButton: 'cancel-btn-password',
            denyButton: 'cancel-btn-password'
        }
    })
}

/**
 * @method
 * ConfirmExportPDF
 * @description PDF書き出し画面の確認ボックス
 * @return {promise} 確認待ち
 */
export const ConfirmExportPDF = async () => {
    const html = `
        <div class="title-confirm mb-4">${textDisplay.titleBoxConfirmExportPdf}</div>
        <div class="flex justify-center">
            <div class="flex flex-col items-start">
                <div class="mb-4 flex items-center">
                        <input
                            type="radio"
                            id="export-public"
                            name="exportPdf"
                            value="1"
                            checked
                        />
                    <label class="cursor-pointer ml-2" for="export-public">${textDisplay.radioExportPublicInfo} </label>
                </div>
                <div class="flex items-center">
                    <input
                                type="radio"
                                id="export-all-info"
                                name="exportPdf"
                                value="2"
                            />
                    <label class="cursor-pointer ml-2" for="export-all-info">${textDisplay.radioExportAllInfo}</label>
                </div>
            </div>

        </div>

    `
    const iconHtml = `
        <img src="${pdfIcon}" style="width: 80px; height: 80px;"/>
    `
    const closeBtnHtml = `
        <svg width="14" height="14">
            <use xlink:href="${svgSymbol}#icon-times" xmlns:xlink="http://www.w3.org/1999/xlink"></use>
        </svg>
    `
    return await Swal.fire({
        html: html,
        iconHtml: iconHtml,
        showCancelButton: false,
        showCloseButton: true,
        confirmButtonText: textDisplay.btnExportPdf,
        buttonsStyling: false,
        closeButtonHtml: closeBtnHtml,
        customClass: {
            popup: 'custom-container',
            icon: 'custom-icon',
            htmlContainer: 'custom-html',
            confirmButton: 'confirm-btn',
            closeButton: 'custom-close-btn'
        },
        preConfirm: function (result) {
            const radioChecked = document.querySelector('input[name="exportPdf"]:checked')
            if (radioChecked) {
                return radioChecked.value
            } else {
                return null
            }
        }
    })
}

/**
 * @method
 * PasswordBox
 * @description パスワード変更画面の確認ボックス
 * @param {object} data
 * @param {string} data.title - 確認ボックスのタイトル
 * @param {string} data.cancelBtnText - 確認ボックスのタイトル
 * @param {string} data.confirmBtnText - 確認ボタンの表示テキスト
 * @param {bool} data.showCancelBtn - 表示／非表示 キャンセルボタン
 * @param {bool} data.allowOutsideClick - 確認ボックスの外をクリックして確認ボックスをキャンセルすることを許可または禁止する
 * @return {promise} 確認待ち
 */
export const PasswordBox = async (data) => {
    const { title, cancelBtnText, confirmBtnText, showCancelBtn, allowOutsideClick } = data
    const html = `
        <div class="title-confirm mb-4">${title}</div>
    `
    const iconHtml = `
        <svg width="80" height="80">
            <use xlink:href="${svgSymbol}#icon-password-box" xmlns:xlink="http://www.w3.org/1999/xlink"></use>
        </svg>
    `
    const closeBtnHtml = `
        <svg width="14" height="14">
            <use xlink:href="${svgSymbol}#icon-times" xmlns:xlink="http://www.w3.org/1999/xlink"></use>
        </svg>
    `
    return await Swal.fire({
        html: html,
        iconHtml: iconHtml,
        showCancelButton: showCancelBtn,
        allowOutsideClick: allowOutsideClick,
        showCloseButton: true,
        confirmButtonText: confirmBtnText,
        cancelButtonText: cancelBtnText,
        buttonsStyling: false,
        reverseButtons: true,
        closeButtonHtml: closeBtnHtml,
        customClass: {
            popup: 'custom-container',
            icon: 'custom-icon',
            htmlContainer: 'custom-html-password',
            confirmButton: 'confirm-btn',
            closeButton: 'custom-close-btn',
            cancelButton: 'cancel-btn-password'
        }
    })
}
