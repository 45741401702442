<template>
    <svg :width="width" :height="height" :id="id" :class="classNames">
        <use :xlink:href="urlIcon" xmlns:xlink="http://www.w3.org/1999/xlink"></use>
    </svg>
</template>

<script>
import svgSymbol from '../../assets/svg_symbol.svg'
export default {
    name: 'SVGIconComponent',
    props: {
        icon: {
            type: String,
            require: true
        },
        width: {
            type: Number,
            require: false
        },
        height: {
            type: Number,
            require: false
        },
        id: {
            type: String,
            require: true
        },
        classNames: {
            type: String
        }
    },
    computed: {
        urlIcon() {
            return svgSymbol + '#' + this.icon
        }
    }
}
</script>

<style scoped>

</style>
