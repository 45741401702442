<template>
  <div class="flex-1 page-container">
    <div class="w-full bg-white rounded-2xl flex-wrap relative">
      <div
        class="
          flex
          w-full
          header-create-summary
          border-bottom
          pt-5
          pl-5
          pr-10
          pb-5
          flex-wrap
        "
      >
        <div class="w-full flex">
          <div class="w-1/2 text-left">
            {{ textDisplay.labelStatus }}: {{ status.title }}
          </div>
          <div class="flex w-1/2">
            <div class=" w-1/2 text-left">
              {{ textDisplay.labelUpdatedBy }}:
              {{ form.update_username ? form.update_username : "" }}
            </div>
            <div class=" w-1/2 text-left">
              {{ textDisplay.labelUpdatedAt }}:
              {{ form.update_date ? formattedUpdateDate : "" }}
            </div>
          </div>
        </div>
        <div class="w-full flex">
          <div class="w-1/2 text-left"></div>
          <div class="flex w-1/2">
            <div class=" w-1/2 text-left">{{ textDisplay.labelApprovedBy }}: {{ form.approve_username ? form.approve_username : "" }}</div>
            <div class=" w-1/2 text-left">{{ textDisplay.labelApprovedAt }}: {{ form.approve_date ? formattedApproveDate : "" }}</div>
          </div>
        </div>
      </div>
      <div class="w-full pt-5 tab-summary px-5 h-[71px]">
        <div class="flex">
          <div
            class="cursor-pointer mr-1 tab-item"
            :class="isPublic ? 'active' : ''"
            @click="isPublic = true"
          >
            <span>{{ textDisplay.labelPublic }}</span>
          </div>
          <div
            class="cursor-pointer tab-item"
            :class="!isPublic ? 'active' : ''"
            @click="isPublic = false"
          >
            <span>{{ textDisplay.labelAllInfo }}</span>
          </div>
        </div>
      </div>
      <form class="w-full pt-5 pl-5 pr-10 pb-[50px]" @submit.prevent="">
        <PublicSummaryComponent
          v-if="isPublic"
          class="ml-3"
          :isView="isView"
          :general="form.general"
          :drug_name="form.drug_name"
          :drug_kana="form.drug_kana"
          :effect_for_anomaly="form.effect_for_anomaly"
          :effect_for_baby="form.effect_for_baby"
          :other="form.other"
          :animal_test="form.animal_test"
          :attached="form.attached"
          :placenta_passage="form.placenta_passage"
          :summarize="form.summarize"
        />
        <AllInfoSummaryComponent
          v-else
          class="ml-3 mb-[20px]"
          :isView="isView"
          :pharmacological_name="form.pharmacological_name"
          :classification="form.classification"
          :major_drug_name="form.major_drug_name"
          :animalResearchResultData="form.animalResearchResultData"
          :humanReportedData="form.humanReportedData"
          :humanResearchData="form.humanResearchData"
          :interviewData="form.interviewData"
          :packageInsertData="form.packageInsertData"
          :otherMaterialsData="form.otherMaterialsData"
          :motherRiskStatementData="form.motherRiskStatementData"
          @click-humanResearchData-row="clickHumanResearchDataRow($event)"
          @click-humanReportedData-row="clickHumanReportedDataRow($event)"
          @click-animalResearchResultData-row="
            clickAnimalResearchResultDataRow($event)
          "
          @click-interviewData-row="clickInterviewDataRow($event)"
          @click-packageInsertData-row="clickPackageInsertDataRow($event)"
          @click-otherMaterialsData-row="clickOtherMaterialsDataRow($event)"
          @click-motherRiskStatementData-row="
            clickMotherRiskStatementRow($event)
          "
        />
        <div class="flex justify-center pt-[20px]">
          <MenuButtonComponent
            class="border-2 mr-4 h-11"
            type="button"
            :btn-style="'btn_bordered'"
            @click="exportPDF"
            :title="textDisplay.buttonExportPDF"
            :isDisabled="form.status !== 2"
          />
          <MenuButtonComponent
            class="border-2 mr-4 h-11"
            type="button"
            :btn-style="'btn_bordered'"
            @click="swapToEdit"
            :title="textDisplay.buttonViewSummaryEdit"
          />
          <MenuButtonComponent
            class="border-2 mr-4 h-11"
            type="button"
            @click="confirmSummary"
            :title="textDisplay.buttonViewSummaryConfirm"
            :isDisabled="
              store.state.user.user_id === form.update_user ||
              form.status === 2 ||
              form.status === 0
            "
          />
        </div>
      </form>
    </div>
  </div>
  <div class="z-20 absolute top-1/2 left-1/2" v-if="isLoading">
    <LoadingComponent />
  </div>
   <HumanResearchModalComponent
    :year_country="tableForm.year_country"
    :author="tableForm.author"
    :publication_magazine="tableForm.publication_magazine"
    :title="tableForm.title"
    :design="tableForm.design"
    :exposure_n_control_n="tableForm.exposure_n_control_n"
    :outcome="tableForm.outcome"
    :result="tableForm.result"
    :recruitment="tableForm.recruitment"
    :reason="tableForm.reason"
    :brtm="tableForm.brtm"
    :memo="tableForm.memo"
    :endnote_link="tableForm.endnote_link"
    @input-year_country-modal="tableForm.year_country = $event"
    @input-author-modal="tableForm.author = $event"
    @input-publication_magazine-modal="tableForm.publication_magazine = $event"
    @input-title-modal="tableForm.title = $event"
    @input-design-modal="tableForm.design = $event"
    @input-exposure_n_control_n-modal="tableForm.exposure_n_control_n = $event"
    @input-outcome-modal="tableForm.outcome = $event"
    @input-result-modal="tableForm.result = $event"
    @input-recruitment-modal="tableForm.recruitment = $event"
    @input-reason-modal="tableForm.reason = $event"
    @input-brtm-modal="tableForm.brtm = $event"
    @input-memo-modal="tableForm.memo = $event"
    @input-endnote_link-modal="tableForm.endnote_link = $event"
    v-if="isHumanResearchModalOpen"
    @closeModal="isHumanResearchModalOpen = false"
    @saveModal="saveRow"
    @cancelModal="cancelModal"
    :isView="true"
  />
  <AnimalTestModalComponent
    :year_country="tableForm.year_country"
    :author="tableForm.author"
    :publication_magazine="tableForm.publication_magazine"
    :title="tableForm.title"
    :animal_species="tableForm.animal_species"
    :outcome="tableForm.outcome"
    :dose="tableForm.dose"
    :result="tableForm.result"
    :brtm="tableForm.brtm"
    :memo="tableForm.memo"
    :endnote_link="tableForm.endnote_link"
    @input-year_country-modal="tableForm.year_country = $event"
    @input-author-modal="tableForm.author = $event"
    @input-publication_magazine-modal="tableForm.publication_magazine = $event"
    @input-title-modal="tableForm.title = $event"
    @input-animal_species-modal="tableForm.animal_species = $event"
    @input-outcome-modal="tableForm.outcome = $event"
    @input-dose-modal="tableForm.dose = $event"
    @input-result-modal="tableForm.result = $event"
    @input-brtm-modal="tableForm.brtm = $event"
    @input-memo-modal="tableForm.memo = $event"
    @input-endnote_link-modal="tableForm.endnote_link = $event"
    v-if="isAnimalTestModalOpen"
    @closeModal="isAnimalTestModalOpen = false"
    @saveModal="saveRow"
    @cancelModal="cancelModal"
    :isView="true"
  />
  <HumanReportedModalComponent
    :year_country="tableForm.year_country"
    :author="tableForm.author"
    :publication_magazine="tableForm.publication_magazine"
    :title="tableForm.title"
    :result="tableForm.result"
    :recruitment="tableForm.recruitment"
    :judgment_reason="tableForm.judgment_reason"
    :brtm="tableForm.brtm"
    :memo="tableForm.memo"
    :endnote_link="tableForm.endnote_link"
    @input-year_country-modal="tableForm.year_country = $event"
    @input-author-modal="tableForm.author = $event"
    @input-publication_magazine-modal="tableForm.publication_magazine = $event"
    @input-title-modal="tableForm.title = $event"
    @input-result-modal="tableForm.result = $event"
    @input-recruitment-modal="tableForm.recruitment = $event"
    @input-judgment_reason-modal="tableForm.judgment_reason = $event"
    @input-brtm-modal="tableForm.brtm = $event"
    @input-memo-modal="tableForm.memo = $event"
    @input-endnote_link-modal="tableForm.endnote_link = $event"
    v-if="isHumanReportedModalOpen"
    @closeModal="isHumanReportedModalOpen = false"
    @saveModal="saveRow"
    @cancelModal="cancelModal"
    :isView="true"
  />
  <InterviewModalComponent
    :postscript_date="tableForm.postscript_date"
    :product_name="tableForm.product_name"
    :version="tableForm.version"
    :pdf_link="tableForm.pdf_link"
    @input-postscript_date-modal="tableForm.postscript_date = $event"
    @input-product_name-modal="tableForm.product_name = $event"
    @input-version-modal="tableForm.version = $event"
    @input-pdf_link-modal="tableForm.pdf_link = $event"
    v-if="isInterviewModalOpen"
    @closeModal="isInterviewModalOpen = false"
    @saveModal="saveRow"
    @cancelModal="cancelModal"
    :isView="true"
  />
  <MotherRiskStatementModalComponent
    :postscript_date="tableForm.postscript_date"
    :year="tableForm.year"
    :destination_link="tableForm.destination_link"
    @input-postscript_date-modal="tableForm.postscript_date = $event"
    @input-year-modal="tableForm.year = $event"
    @input-destination_link-modal="tableForm.destination_link = $event"
    v-if="isMotherRiskStatemantModalOpen"
    @closeModal="isMotherRiskStatemantModalOpen = false"
    @saveModal="saveRow"
    @cancelModal="cancelModal"
    :isView="true"
  />
  <PackageInsertModalComponent
    :postscript_date="tableForm.postscript_date"
    :reference_page="tableForm.reference_page"
    :version="tableForm.version"
    :pdf_link="tableForm.pdf_link"
    @input-postscript_date-modal="tableForm.postscript_date = $event"
    @input-reference_page-modal="tableForm.reference_page = $event"
    @input-version-modal="tableForm.version = $event"
    @input-pdf_link-modal="tableForm.pdf_link = $event"
    v-if="isPackageInsertModalOpen"
    @closeModal="isPackageInsertModalOpen = false"
    @saveModal="saveRow"
    @cancelModal="cancelModal"
    :isView="true"
  />
  <OtherMaterialsModalComponent
    :postscript_date="tableForm.postscript_date"
    :document_content="tableForm.document_content"
    :destination_link="tableForm.destination_link"
    @input-postscript_date-modal="tableForm.postscript_date = $event"
    @input-document_content-modal="tableForm.document_content = $event"
    @input-destination_link-modal="tableForm.destination_link = $event"
    v-if="isOtherMaterialsModalOpen"
    @closeModal="isOtherMaterialsModalOpen = false"
    @saveModal="saveRow"
    @cancelModal="cancelModal"
    :isView="true"
  />
</template>

<script>
import PublicSummaryComponent from './shared/PublicSummaryComponent.vue'
import AllInfoSummaryComponent from './shared/AllInfoSummaryComponent.vue'
import MenuButtonComponent from '../../common/menu/MenuButtonComponent.vue'
import HumanResearchModalComponent from './shared/HumanResearchModalComponent.vue'
import AnimalTestModalComponent from './shared/AnimalTestModalComponent.vue'
import HumanReportedModalComponent from './shared/HumanReportedModalComponent.vue'
import InterviewModalComponent from './shared/InterviewModalComponent.vue'
import MotherRiskStatementModalComponent from './shared/MotherRiskStatementModalComponent.vue'
import PackageInsertModalComponent from './shared/PackageInsertModalComponent.vue'
import OtherMaterialsModalComponent from './shared/OtherMaterialsModalComponent.vue'
import { textDisplay } from '../../../common/TextDisplay'
import LoadingComponent from '../../common/LoadingComponent.vue'
import { ConfirmExportPDF, ConfirmBox } from '../../../common/ConfirmBox'
import { exportPublic, exportAllInfoV2 } from '../../../common/ExportPDF'
import { useStore } from 'vuex'
import { formatDatetime, formatDate } from '../../../common/UsefulFunction'
import { NotificationMessage } from '../../../common/NotificationMessage'
import { ACTION_TYPE } from '../../../common/constants'

/**
 * @module ViewSummaryComponent
 */

export default {
    components: {
        PublicSummaryComponent,
        AllInfoSummaryComponent,
        MenuButtonComponent,
        LoadingComponent,
        HumanResearchModalComponent,
        AnimalTestModalComponent,
        HumanReportedModalComponent,
        InterviewModalComponent,
        MotherRiskStatementModalComponent,
        PackageInsertModalComponent,
        OtherMaterialsModalComponent
    },
    data() {
        return {
            textDisplay,
            formatDatetime,
            isPublic: true,
            isView: true,
            isLoading: false,
            store: useStore(),
            tableForm: {},
            isModalOpened: false,
            isHumanResearchModalOpen: false,
            isHumanReportedModalOpen: false,
            isInterviewModalOpen: false,
            isPackageInsertModalOpen: false,
            isOtherMaterialsModalOpen: false,
            isMotherRiskStatemantModalOpen: false,
            isAnimalTestModalOpen: false,
            form: {
                id: '',
                summary_id: '',
                // Public
                drug_name: '',
                drug_kana: '',
                general: '',
                effect_for_anomaly: '',
                effect_for_baby: '',
                other: '',
                placenta_passage: '',
                animal_test: '',
                attached: '',
                summarize: '',
                // All Info
                pharmacological_name: '',
                major_drug_name: '',
                classification: '',
                status: '',
                update_user: '',
                update_date: '',
                version: '',
                update_username: '',
                approve_username: '',
                approve_date: '',
                humanResearchData: [],
                humanReportedData: [],
                animalResearchResultData: [],
                interviewData: [],
                packageInsertData: [],
                otherMaterialsData: [],
                motherRiskStatementData: []
            }
        }
    },
    computed: {
        formattedUpdateDate: function () {
            return formatDatetime(new Date(this.form.update_date))
        },
        formattedApproveDate: function () {
            return formatDatetime(
                new Date(this.form.approve_date)
            )
        },
        status: function () {
            return this.textDisplay.selectOptionSummaryInDBStatus.find((item) => {
                if (+item.value === +this.form.status) {
                    return item
                }
            })
        }
    },
    async beforeMount() {
        let tmp = {}
        let isFromCache = false
        if (
            this.store.getters.summaries.items &&
      this.store.getters.summaries.items.length > 0
        ) {
            for (let i = 0; i < this.store.getters.summaries.items.length; i++) {
                if (
                    +this.store.getters.summaries.items[i].summary_id ===
          +this.$route.params.index
                ) {
                    tmp = this.store.getters.summaries.items[i]
                    isFromCache = true
                    break
                }
            }
        }
        if (!isFromCache) {
            tmp = await this.store
                .dispatch('getSummary', this.$route.params.index)
                .then((result) => {
                    return result
                })
                .catch((e) => {})
        }
        this.form.id = tmp.id
        this.form.summary_id = tmp.summary_id
        this.form.drug_kana = tmp.drug_kana
        this.form.drug_name = tmp.drug_name
        this.form.general = tmp.general
        this.form.effect_for_anomaly = tmp.effect_for_anomaly
        this.form.effect_for_baby = tmp.effect_for_baby
        this.form.other = tmp.other
        this.form.placenta_passage = tmp.placenta_passage
        this.form.animal_test = tmp.animal_test
        this.form.attached = tmp.attached
        this.form.summarize = tmp.summarize
        this.form.update_user = tmp.update_user
        this.form.update_date = tmp.update_date
        this.form.update_username = tmp.update_username
        this.form.version = tmp.version
        this.form.status = tmp.status
        this.form.approve_username = tmp.approve_username
        this.form.approve_date = tmp.approve_date
        // All Info
        this.form.pharmacological_name = tmp.pharmacological_name
        this.form.major_drug_name = tmp.major_drug_name
        this.form.classification = tmp.classification

        tmp.literatures.forEach((value) => {
            if (value.length > 0) {
                switch (value[0].item) {
                case 41:
                    this.form.humanResearchData = [...value]
                    for (let i = 0; i < this.form.humanResearchData.length; i++) {
                        this.form.humanResearchData[i].update_date = formatDate(
                            this.form.humanResearchData[i].update_date
                        )
                    }
                    break
                case 42:
                    this.form.humanReportedData = [...value]
                    for (let i = 0; i < this.form.humanReportedData.length; i++) {
                        this.form.humanReportedData[i].update_date = formatDate(
                            this.form.humanReportedData[i].update_date
                        )
                    }
                    break
                case 43:
                    this.form.animalResearchResultData = [...value]
                    for (
                        let i = 0;
                        i < this.form.animalResearchResultData.length;
                        i++
                    ) {
                        this.form.animalResearchResultData[i].update_date = formatDate(
                            this.form.animalResearchResultData[i].update_date
                        )
                    }
                    break
                case 44:
                    this.form.interviewData = [...value]
                    for (let i = 0; i < this.form.interviewData.length; i++) {
                        this.form.interviewData[i].update_date = formatDate(
                            this.form.interviewData[i].update_date
                        )
                    }
                    break
                case 45:
                    this.form.packageInsertData = [...value]
                    for (let i = 0; i < this.form.packageInsertData.length; i++) {
                        this.form.packageInsertData[i].update_date = formatDate(
                            this.form.packageInsertData[i].update_date
                        )
                    }
                    break
                case 46:
                    this.form.otherMaterialsData = [...value]
                    for (let i = 0; i < this.form.otherMaterialsData.length; i++) {
                        this.form.otherMaterialsData[i].update_date = formatDate(
                            this.form.otherMaterialsData[i].update_date
                        )
                    }
                    break
                case 50:
                    this.form.motherRiskStatementData = [...value]
                    for (let i = 0; i < this.form.motherRiskStatementData.length; i++) {
                        this.form.motherRiskStatementData[i].update_date = formatDate(
                            this.form.motherRiskStatementData[i].update_date
                        )
                    }
                    break
                }
            }
        })
    },
    methods: {

        /**
         * 編集サマリー画面へ
         */
        swapToEdit() {
            this.$router.push(`/summary/edit-summary/${this.$route.params.index}`)
        },

        /**
         * @async
         * この要約を確認するためにvuexアクションを呼び出す
         */
        async confirmSummary() {
            const isConfirmed = await ConfirmBox(this.textDisplay.msg_approve_alert)
            if (isConfirmed.isConfirmed) {
                this.isLoading = true
                this.store
                    .dispatch('confirmSummary', this.form.summary_id)
                    .then((result) => {
                        this.form.status = 2
                        this.form.update_date =
              result.data.update_date
                        this.form.update_user =
              result.data.update_user
                        this.form.update_username =
              result.data.update_username
                        this.form.summary_id = result.data.summary_id
                        this.form.version = result.data.version
                        this.form.approve_username = result.data.approve_username
                        this.form.approve_date = result.data.approve_date
                        NotificationMessage(this.textDisplay.msg_approve_successfully, 3000, ACTION_TYPE.success)
                        this.$router.push(`/summary/view-summary/${result.data.summary_id}`)
                    })
                    .catch((e) => {
                        NotificationMessage(
                            e.response.data.message,
                            3000,
                            ACTION_TYPE.error
                        )
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            }
        },

        /**
         * @async
         * このサマリー情報をpdfに書き出す
         */
        async exportPDF() {
            const selected = await ConfirmExportPDF()
            if (selected.value === '1') {
                this.isLoading = true
                this.isLoading = await exportPublic(this.form)
            } else if (selected.value === '2') {
                this.isLoading = true
                await exportAllInfoV2(this.form)
                this.isLoading = await exportPublic(this.form)
            }
        },

        /**
         * 表4.1の行をクリック
         * @param {number} e - 行のインデックス
         */
        clickHumanResearchDataRow(e) {
            this.tableForm = {
                ...this.form.humanResearchData[e]
            }
            if (this.tableForm.recruitment === false) {
                this.tableForm.recruitment = this.textDisplay.selectOptionRecruitment[1]
            } else {
                this.tableForm.recruitment = this.textDisplay.selectOptionRecruitment[0]
            }
            this.tableForm.type = 41
            this.tableForm.index = e
            this.isHumanResearchModalOpen = true
        },

        /**
         * 表4.2の行をクリック
         * @param {number} e - 行のインデックス
         */
        clickHumanReportedDataRow(e) {
            this.tableForm = {
                ...this.form.humanReportedData[e]
            }
            if (this.tableForm.recruitment === false) {
                this.tableForm.recruitment = this.textDisplay.selectOptionRecruitment[1]
            } else {
                this.tableForm.recruitment = this.textDisplay.selectOptionRecruitment[0]
            }
            this.tableForm.type = 42
            this.tableForm.index = e
            this.isHumanReportedModalOpen = true
        },

        /**
         * 表4.3の行をクリック
         * @param {number} e - 行のインデックス
         */
        clickAnimalResearchResultDataRow(e) {
            this.tableForm = {
                ...this.form.animalResearchResultData[e]
            }
            this.tableForm.type = 43
            this.tableForm.index = e
            this.isAnimalTestModalOpen = true
        },

        /**
         * 表4.4の行をクリック
         * @param {number} e - 行のインデックス
         */
        clickInterviewDataRow(e) {
            this.tableForm = {
                ...this.form.interviewData[e]
            }
            this.tableForm.type = 44
            this.tableForm.index = e
            this.isInterviewModalOpen = true
        },

        /**
         * 表4.5の行をクリック
         * @param {number} e - 行のインデックス
         */
        clickPackageInsertDataRow(e) {
            this.tableForm = {
                ...this.form.packageInsertData[e]
            }
            this.tableForm.type = 45
            this.tableForm.index = e
            this.isPackageInsertModalOpen = true
        },

        /**
         * 表4.6の行をクリック
         * @param {number} e - 行のインデックス
         */
        clickOtherMaterialsDataRow(e) {
            this.tableForm = {
                ...this.form.otherMaterialsData[e]
            }
            this.tableForm.type = 46
            this.tableForm.index = e
            this.isOtherMaterialsModalOpen = true
        },

        /**
         * 表5.0の行をクリック
         * @param {number} e - 行のインデックス
         */
        clickMotherRiskStatementRow(e) {
            this.tableForm = {
                ...this.form.motherRiskStatementData[e]
            }
            this.tableForm.year = this.textDisplay.selectOptionYear.find(element => element.value === this.tableForm.year)
            this.tableForm.type = 50
            this.tableForm.index = e
            this.isMotherRiskStatemantModalOpen = true
        },

        /**
         * ポップアップを閉じる
         */
        cancelModal() {
            this.tableForm = {}
            this.isMotherRiskStatemantModalOpen = false
            this.isOtherMaterialsModalOpen = false
            this.isPackageInsertModalOpen = false
            this.isInterviewModalOpen = false
            this.isAnimalTestModalOpen = false
            this.isHumanResearchModalOpen = false
            this.isHumanReportedModalOpen = false
        }
    }
}
</script>
