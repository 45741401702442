<template>
    <div class="text-left ">
        <div class="text-left font-bold color-text mb-[20px]">{{ textDisplay.labelTitle }}</div>
        <div class="mb-[20px]">
            <label class="font-bold mb-[10px]">{{ textDisplay.labelPharmacologicalName }}</label>
            <MenuInputComponent
                :isDisabled="isView"
                type="text"
                class="ml-8"
                :maxLength="256"
                :value="pharmacological_name"
                @input="$emit('input-pharmacological-name', $event)"
                :input-style="'input_bordered'"
                max-height="40"
            />
        </div>
        <div class="mb-[20px]">
            <label class="font-bold mb-[10px]">{{ textDisplay.labelMajorDrugName }}</label>
            <MenuInputComponent
                :isDisabled="isView"
                type="text"
                class="ml-8"
                :maxLength="256"
                :value="major_drug_name"
                @input="$emit('input-major-drug-name', $event)"
                :input-style="'input_bordered'"
                max-height="40"
            />
        </div>
        <div class="mb-[20px]">
            <label class="font-bold mb-[10px]">{{ textDisplay.labelClassification }}</label>
            <MenuInputComponent
                :isDisabled="isView"
                type="text"
                class="ml-8"
                :maxLength="256"
                :value="classification"
                @input="$emit('input-classification', $event)"
                :input-style="'input_bordered'"
                max-height="40"
            />
        </div>
        <div class="mb-[20px]">
            <label class="font-bold mb-[10px]">{{ textDisplay.labelReferenceDocuments }}</label>
            <div class="text-left w-full pl-8 mb-[20px]">
                <div class="flex justify-between items-center mb-2.5">
                    <label class="font-normal">{{ textDisplay.labelHumanResearch }}</label>
                    <div v-if="!isView">
                        <button
                            :disabled="isView"
                            type="button"
                            class="mr-2"
                            @click="$emit('add-humanResearchData')"
                        >
                            {{ textDisplay.buttonAddRow }}
                        </button>
                        <button
                            :disabled="isView"
                            type="button"
                            @click="deleteHumanResearch"
                        >
                            {{ textDisplay.buttonDeleteRow }}
                        </button>
                    </div>
                </div>
                <TableComponent
                    :headers="isView ? textDisplay.tableHumanResearchHeaderInView : textDisplay.tableHumanResearchHeader"
                    :table_type="isView ? 3 : 0"
                    class="w-full"
                    :data="humanResearchData"
                    :checkboxSelectedList="humanResearchDataSelectedList"
                    max-height="500"
                    @click-checkbox="humanResearchCheckBox($event)"
                    @click-row="$emit('click-humanResearchData-row', $event)"
                />
            </div>
            <div class="text-left w-full pl-8 mb-[20px]">
                <div class="flex justify-between items-center mb-2.5">
                    <label class="font-normal">{{ textDisplay.labelInHumanReported }}</label>
                    <div v-if="!isView">
                        <button
                            :disabled="isView"
                            type="button"
                            class="mr-2"
                            @click="$emit('add-humanReportedData')"
                        >
                            {{ textDisplay.buttonAddRow }}
                        </button>
                        <button
                            :disabled="isView"
                            type="button"
                            @click="deleteHumanReported"
                        >
                            {{ textDisplay.buttonDeleteRow }}
                        </button>
                    </div>
                </div>
                <TableComponent
                    :headers="isView ? textDisplay.tableHumanReportedHeaderInView : textDisplay.tableHumanReportedHeader"
                    :table_type="isView ? 3 : 0"
                    max-height="500"
                    class="w-full"
                    :data="humanReportedData"
                    :checkboxSelectedList="humanReportedDataSelectedList"
                    @click-row="$emit('click-humanReportedData-row', $event)"
                    @click-checkbox="humanReportedCheckBox($event)"
                />
            </div>
            <div class="text-left w-full pl-8 mb-[20px]">
                <div class="flex justify-between items-center mb-2.5">
                    <label class="font-normal">{{ textDisplay.labelAnimalResearchResult }}</label>
                    <div v-if="!isView">
                        <button
                            :disabled="isView"
                            type="button"
                            class="mr-2"
                            @click="$emit('add-animalResearchResultData')"
                        >
                            {{ textDisplay.buttonAddRow }}
                        </button>
                        <button
                            :disabled="isView"
                            type="button"
                            @click="deleteAnimalResearchResult"
                        >
                            {{ textDisplay.buttonDeleteRow }}
                        </button>
                    </div>
                </div>
                <TableComponent
                    :headers="isView ? textDisplay.tableAnimalTestHeaderInView : textDisplay.tableAnimalTestHeader"
                    :table_type="isView ? 3 : 0"
                    max-height="500"
                    class="w-full"
                    :data="animalResearchResultData"
                    :checkboxSelectedList="animalResearchResultDataSelectedList"
                    @click-row="$emit('click-animalResearchResultData-row', $event)"
                    @click-checkbox="animalResearchResultCheckBox($event)"
                />
            </div>
            <div class="text-left w-full pl-8 mb-[20px]">
                <div class="flex justify-between items-center mb-2.5">
                    <label class="font-normal">{{ textDisplay.labelByInterview }}</label>
                    <div v-if="!isView">
                        <button
                            :disabled="isView"
                            type="button"
                            class="mr-2"
                            @click="$emit('add-interviewData')"
                        >
                            {{ textDisplay.buttonAddRow }}
                        </button>
                        <button
                            :disabled="isView"
                            type="button"
                            @click="deleteInterview"
                        >
                            {{ textDisplay.buttonDeleteRow }}
                        </button>
                    </div>
                </div>
                <TableComponent
                    :headers="isView ? textDisplay.tablInterviewHeaderInView : textDisplay.tablInterviewHeader"
                    :table_type="isView ? 3 : 0"
                    max-height="500"
                    class="w-full"
                    :data="interviewData"
                    :checkboxSelectedList="interviewDataSelectedList"
                    @click-row="$emit('click-interviewData-row', $event)"
                    @click-checkbox="interviewCheckBox($event)"
                />
            </div>
            <div class="text-left w-full pl-8 mb-[20px]">
                <div class="flex justify-between items-center mb-2.5">
                    <label class="font-normal">{{ textDisplay.labelPackageInsert }}</label>
                    <div v-if="!isView">
                        <button
                            :disabled="isView"
                            type="button"
                            class="mr-2"
                            @click="$emit('add-packageInsertData')"
                        >
                            {{ textDisplay.buttonAddRow }}
                        </button>
                        <button
                            :disabled="isView"
                            type="button"
                            @click="deletePackageInsert"
                        >
                            {{ textDisplay.buttonDeleteRow }}
                        </button>
                    </div>
                </div>
                <TableComponent
                    :headers="isView ? textDisplay.tablePackageInsertHeaderInView : textDisplay.tablePackageInsertHeader"
                    :table_type="isView ? 3 : 0"
                    max-height="500"
                    class="w-full"
                    :data="packageInsertData"
                    :checkboxSelectedList="packageInsertDataSelectedList"
                    @click-row="$emit('click-packageInsertData-row', $event)"
                    @click-checkbox="packageInsertCheckBox($event)"
                />
            </div>
            <div class="text-left w-full pl-8 mb-[20px]">
                <div class="flex justify-between items-center mb-2.5">
                    <label class="font-normal">{{ textDisplay.labelOtherMaterials }}</label>
                    <div v-if="!isView">
                        <button
                            :disabled="isView"
                            type="button"
                            class="mr-2"
                            @click="$emit('add-otherMaterialsData')"
                        >
                            {{ textDisplay.buttonAddRow }}
                        </button>
                        <button
                            :disabled="isView"
                            type="button"
                            @click="deleteOtherMaterials"
                        >
                            {{ textDisplay.buttonDeleteRow }}
                        </button>
                    </div>
                </div>
                <TableComponent
                    :headers="isView ? textDisplay.tableOtherMaterialsHeaderInView : textDisplay.tableOtherMaterialsHeader"
                    :table_type="isView ? 3 : 0"
                    max-height="500"
                    class="w-full"
                    :data="otherMaterialsData"
                    :checkboxSelectedList="otherMaterialsDataSelectedList"
                    @click-row="$emit('click-otherMaterialsData-row', $event)"
                    @click-checkbox="otherMaterialsCheckBox($event)"
                />
            </div>
        </div>
        <div class="text-left w-full">
            <div class="flex justify-between items-center w-full mb-2.5">
                <label class="font-bold">{{ textDisplay.labelMotherRiskStatement }}</label>
                <div class="ml-11" v-if="!isView">
                    <button
                        :disabled="isView"
                        type="button"
                        class="mr-2"
                        @click="$emit('add-motherRiskStatementData')"
                    >
                        {{ textDisplay.buttonAddRow }}
                    </button>
                    <button
                        :disabled="isView"
                        type="button"
                        @click="deleteMotherRiskStatement"
                    >
                        {{ textDisplay.buttonDeleteRow }}
                    </button>
                </div>
            </div>
            <div class="pl-8">
                <TableComponent
                    :headers="isView ? textDisplay.tableMotherRiskStatementHeaderInView : textDisplay.tableMotherRiskStatementHeader"
                    :table_type="isView ? 3 : 0"
                    max-height="500"
                    class="w-full"
                    :data="motherRiskStatementData"
                    :checkboxSelectedList="motherRiskStatementDataSelectedList"
                    @click-row="$emit('click-motherRiskStatementData-row', $event)"
                    @click-checkbox="motherRiskStatementCheckBox($event)"
                />
            </div>
        </div>
    </div>
</template>

<script>
import MenuInputComponent from '../../../common/menu/MenuInputComponent.vue'
import TableComponent from '../../../common/TableComponent'
import { textDisplay } from '../../../../common/TextDisplay'

/**
 * @module AllInfoSummaryComponent
 */

export default {
    components: { MenuInputComponent, TableComponent },
    props: {
        isView: { type: Boolean, default: false, require: false },
        pharmacological_name: { type: String, default: '' },
        major_drug_name: { type: String, default: '' },
        classification: { type: String, default: '' },
        humanResearchData: { type: Array },
        humanReportedData: { type: Array },
        animalResearchResultData: { type: Array },
        interviewData: { type: Array },
        packageInsertData: { type: Array },
        otherMaterialsData: { type: Array },
        motherRiskStatementData: { type: Array }
    },
    data() {
        return {
            textDisplay,
            humanResearchDataSelectedList: [],
            humanReportedDataSelectedList: [],
            animalResearchResultDataSelectedList: [],
            interviewDataSelectedList: [],
            packageInsertDataSelectedList: [],
            otherMaterialsDataSelectedList: [],
            motherRiskStatementDataSelectedList: [],
            isAddingRow: false
        }
    },
    methods: {
        /**
         * テーブル 4.1 のチェックボックスをクリックしたときのトリガーイベント
         * @param {Object} e -イベントデータ
         * @param {string | number} e.index - リスト内の行のインデックス
         * @param {boolean} e.isChecked - このチェックボックスがすでにチェックされているかどうか
         */
        humanResearchCheckBox(e) {
            if (e.isChecked) {
                this.humanResearchDataSelectedList.push(parseInt(e.index))
            } else {
                for (let i = 0; i < this.humanResearchDataSelectedList.length; i++) {
                    if (this.humanResearchDataSelectedList[i] === parseInt(e.index)) {
                        this.humanResearchDataSelectedList.splice(i, 1)
                        i--
                    }
                }
            }
        },

        /**
         * テーブル 4.2 のチェックボックスをクリックしたときのトリガーイベント
         * @param {Object} e - イベントデータ
         * @param {string | number} e.index - リスト内の行のインデックス
         * @param {boolean} e.isChecked - このチェックボックスがすでにチェックされているかどうか
         * */
        humanReportedCheckBox(e) {
            if (e.isChecked) {
                this.humanReportedDataSelectedList.push(parseInt(e.index))
            } else {
                for (let i = 0; i < this.humanReportedDataSelectedList.length; i++) {
                    if (this.humanReportedDataSelectedList[i] === parseInt(e.index)) {
                        this.humanReportedDataSelectedList.splice(i, 1)
                        i--
                    }
                }
            }
        },

        /**
         * テーブル 4.3のチェックボックスをクリックすると、イベントが発生する。
         * @param {Object} e - イベントデータ
         * @param {string | number} e.index - リスト内の行のインデックス
         * @param {boolean} e.isChecked - このチェックボックスがすでにチェックされているかどうか
         * */
        animalResearchResultCheckBox(e) {
            if (e.isChecked) {
                this.animalResearchResultDataSelectedList.push(parseInt(e.index))
            } else {
                for (let i = 0; i < this.animalResearchResultDataSelectedList.length; i++) {
                    if (this.animalResearchResultDataSelectedList[i] === parseInt(e.index)) {
                        this.animalResearchResultDataSelectedList.splice(i, 1)
                        i--
                    }
                }
            }
        },

        /**
         * テーブル 4.4のチェックボックスをクリックすると、イベントが発生する。
         * @param {Object} e - イベントデータ
         * @param {string | number} e.index - リスト内の行のインデックス
         * @param {boolean} e.isChecked - このチェックボックスがすでにチェックされているかどうか
         * */
        interviewCheckBox(e) {
            if (e.isChecked) {
                this.interviewDataSelectedList.push(parseInt(e.index))
            } else {
                for (let i = 0; i < this.interviewDataSelectedList.length; i++) {
                    if (this.interviewDataSelectedList[i] === parseInt(e.index)) {
                        this.interviewDataSelectedList.splice(i, 1)
                        i--
                    }
                }
            }
        },

        /**
         * テーブル 4.5のチェックボックスをクリックすると、イベントが発生する。
         * @param {Object} e - イベントデータ
         * @param {string | number} e.index - リスト内の行のインデックス
         * @param {boolean} e.isChecked - このチェックボックスがすでにチェックされているかどうか
         * */
        packageInsertCheckBox(e) {
            if (e.isChecked) {
                this.packageInsertDataSelectedList.push(parseInt(e.index))
            } else {
                for (let i = 0; i < this.packageInsertDataSelectedList.length; i++) {
                    if (this.packageInsertDataSelectedList[i] === parseInt(e.index)) {
                        this.packageInsertDataSelectedList.splice(i, 1)
                        i--
                    }
                }
            }
        },

        /**
         * テーブル 4.6のチェックボックスをクリックすると、イベントが発生する。
         * @param {Object} e - イベントデータ
         * @param {string | number} e.index - リスト内の行のインデックス
         * @param {boolean} e.isChecked - このチェックボックスがすでにチェックされているかどうか
         * */
        otherMaterialsCheckBox(e) {
            if (e.isChecked) {
                this.otherMaterialsDataSelectedList.push(parseInt(e.index))
            } else {
                for (let i = 0; i < this.otherMaterialsDataSelectedList.length; i++) {
                    if (this.otherMaterialsDataSelectedList[i] === parseInt(e.index)) {
                        this.otherMaterialsDataSelectedList.splice(i, 1)
                        i--
                    }
                }
            }
        },

        /**
         * テーブル 5.0のチェックボックスをクリックすると、イベントが発生する。
         * @param {Object} e - イベントデータ
         * @param {string | number} e.index - リスト内の行のインデックス
         * @param {boolean} e.isChecked - このチェックボックスがすでにチェックされているかどうか
         * */
        motherRiskStatementCheckBox(e) {
            if (e.isChecked) {
                this.motherRiskStatementDataSelectedList.push(parseInt(e.index))
            } else {
                for (let i = 0; i < this.motherRiskStatementDataSelectedList.length; i++) {
                    if (this.motherRiskStatementDataSelectedList[i] === parseInt(e.index)) {
                        this.motherRiskStatementDataSelectedList.splice(i, 1)
                        i--
                    }
                }
            }
        },

        /**
         * 表 4.1の行削除
         */
        deleteHumanResearch() {
            if (this.humanResearchDataSelectedList.length !== 0) {
                this.$emit(
                    'delete-humanResearchData',
                    this.humanResearchDataSelectedList.sort((a, b) => {
                        return a - b
                    })
                )
                this.humanResearchDataSelectedList = []
            }
        },

        /**
         * 表 4.2の行削除
         */
        deleteHumanReported() {
            if (this.humanReportedDataSelectedList.length !== 0) {
                this.$emit(
                    'delete-humanReportedData',
                    this.humanReportedDataSelectedList.sort((a, b) => {
                        return a - b
                    })
                )
                this.humanReportedDataSelectedList = []
            }
        },

        /**
         * 表 4.3の行削除
         */
        deleteAnimalResearchResult() {
            if (this.animalResearchResultDataSelectedList.length !== 0) {
                this.$emit(
                    'delete-animalResearchResultData',
                    this.animalResearchResultDataSelectedList.sort((a, b) => {
                        return a - b
                    })
                )
                this.animalResearchResultDataSelectedList = []
            }
        },

        /**
         * 表 4.4の行削除
         */
        deleteInterview() {
            if (this.interviewDataSelectedList.length !== 0) {
                this.$emit('delete-interviewData', this.interviewDataSelectedList.sort((a, b) => {
                    return a - b
                }))
                this.interviewDataSelectedList = []
            }
        },

        /**
         * 表 4.5の行削除
         */
        deletePackageInsert() {
            if (this.packageInsertDataSelectedList.length !== 0) {
                this.$emit(
                    'delete-packageInsertData',
                    this.packageInsertDataSelectedList.sort((a, b) => {
                        return a - b
                    })
                )
                this.packageInsertDataSelectedList = []
            }
        },

        /**
         * 表 4.6の行削除
         */
        deleteOtherMaterials() {
            if (this.otherMaterialsDataSelectedList.length !== 0) {
                this.$emit(
                    'delete-otherMaterialsData',
                    this.otherMaterialsDataSelectedList.sort((a, b) => {
                        return a - b
                    })
                )
                this.otherMaterialsDataSelectedList = []
            }
        },

        /**
         * 表 5.0の行削除
         */
        deleteMotherRiskStatement() {
            if (this.motherRiskStatementDataSelectedList.length !== 0) {
                this.$emit(
                    'delete-motherRiskStatementData',
                    this.motherRiskStatementDataSelectedList.sort((a, b) => {
                        return a - b
                    })
                )
                this.motherRiskStatementDataSelectedList = []
            }
        },

        /**
         * 表 4.1 に行を追加
         */
        addHumanResearchRow() {
            for (let i = 0; i < this.humanResearchDataSelectedList.length; i++) {
                this.humanResearchDataSelectedList[i] += 1
            }
        },

        /**
         * 表 4.2 に行を追加
         */
        addHumanReportedRow() {
            for (let i = 0; i < this.humanReportedDataSelectedList.length; i++) {
                this.humanReportedDataSelectedList[i] += 1
            }
        },

        /**
         * 表 4.3 に行を追加
         */
        addAnimalResearchResultRow() {
            for (let i = 0; i < this.animalResearchResultDataSelectedList.length; i++) {
                this.animalResearchResultDataSelectedList[i] += 1
            }
        },

        /**
         * 表 4.4 に行を追加
         */
        addInterviewRow() {
            for (let i = 0; i < this.interviewDataSelectedList.length; i++) {
                this.interviewDataSelectedList[i] += 1
            }
        },

        /**
         * 表 4.5 に行を追加
         */
        addPackageInsertRow() {
            for (let i = 0; i < this.packageInsertDataSelectedList.length; i++) {
                this.packageInsertDataSelectedList[i] += 1
            }
        },

        /**
         * 表 4.6 に行を追加
         */
        addOtherMaterialsRow() {
            for (let i = 0; i < this.otherMaterialsDataSelectedList.length; i++) {
                this.otherMaterialsDataSelectedList[i] += 1
            }
        },

        /**
         * 表 5.0 に行を追加
         */
        addMotherRiskStatementRow() {
            for (let i = 0; i < this.motherRiskStatementDataSelectedList.length; i++) {
                this.motherRiskStatementDataSelectedList[i] += 1
            }
        }
    }
}
</script>

<style scoped>
    .color-text{
        color: #1F2533;
    }
    label {
        color: #1F2533;
        display: block;
    }
    button {
        border: 1px solid #015DB2;
        border-radius: 10px;
        background-color: #fff;
        color: #015DB2;
        font-size: 16px;
        padding: 5px 10px;
        transition: all .3s ease-in-out;
        font-weight: bold;
    }
    button:hover {
        background-color: #015DB2;
        color: #FBFBFB;
    }
</style>
