<template>
  <div class="flex-1 page-container">
    <div class="w-full bg-white rounded-2xl flex-wrap relative">
      <div class="w-full pt-5 tab-summary px-5 h-[71px]">
        <div class="flex">
          <div
            class="cursor-pointer mr-1 tab-item"
            :class="isPublic ? 'active' : ''"
            @click="isPublic = true"
          >
            <span>{{ textDisplay.labelPublic }}</span>
          </div>
          <div
            class="cursor-pointer tab-item"
            :class="isPublic ? '' : 'active'"
            @click="isPublic = false"
          >
            <span>{{ textDisplay.labelAllInfo }}</span>
          </div>
        </div>
      </div>
      <form
        class="w-full pt-5 pl-5 pr-10 pb-[50px]"
        @submit.prevent="createSummary()"
      >
        <PublicSummaryComponent
          v-if="isPublic"
          class="ml-3 w-5/6"
          :drug_name_error_text="store.state.drug_name_error_text"
          :drug_name_kana_error_text="store.state.drug_name_kana_error_text"
          :general="store.state.formCreateSummary.general"
          :drug_name="store.state.formCreateSummary.drug_name"
          :drug_kana="store.state.formCreateSummary.drug_kana"
          :effect_for_anomaly="store.state.formCreateSummary.effect_for_anomaly"
          :effect_for_baby="store.state.formCreateSummary.effect_for_baby"
          :other="store.state.formCreateSummary.other"
          :animal_test="store.state.formCreateSummary.animal_test"
          :attached="store.state.formCreateSummary.attached"
          :placenta_passage="store.state.formCreateSummary.placenta_passage"
          :summarize="store.state.formCreateSummary.summarize"
          @input-drug-name="drugNameChange"
          @input-drug-kana="drugKanaChange"
          @input-general="
            store.state.formCreateSummary.general = $event.target.value.trim()
          "
          @input-effect-for-anomaly="
            store.state.formCreateSummary.effect_for_anomaly =
              $event.target.value.trim()
          "
          @input-effect-for-baby="
            store.state.formCreateSummary.effect_for_baby = $event.target.value.trim()
          "
          @input-other="
            store.state.formCreateSummary.other = $event.target.value.trim()
          "
          @input-placenta-passage="
            store.state.formCreateSummary.placenta_passage = $event.target.value.trim()
          "
          @input-animal-test="
            store.state.formCreateSummary.animal_test = $event.target.value.trim()
          "
          @input-attached="
            store.state.formCreateSummary.attached = $event.target.value.trim()
          "
          @input-summarize="
            store.state.formCreateSummary.summarize = $event.target.value.trim()
          "
        />
        <AllInfoSummaryComponent
          v-else
          ref="allInfoComponent"
          :pharmacological_name="
            store.state.formCreateSummary.pharmacological_name
          "
          :classification="store.state.formCreateSummary.classification"
          :major_drug_name="store.state.formCreateSummary.major_drug_name"
          :animalResearchResultData="
            store.state.formCreateSummary.animalResearchResultData
          "
          :humanReportedData="store.state.formCreateSummary.humanReportedData"
          :humanResearchData="store.state.formCreateSummary.humanResearchData"
          :interviewData="store.state.formCreateSummary.interviewData"
          :packageInsertData="store.state.formCreateSummary.packageInsertData"
          :otherMaterialsData="store.state.formCreateSummary.otherMaterialsData"
          :motherRiskStatementData="
            store.state.formCreateSummary.motherRiskStatementData
          "
          @input-pharmacological-name="
            store.state.formCreateSummary.pharmacological_name =
              $event.target.value.trim()
          "
          @input-major-drug-name="
            store.state.formCreateSummary.major_drug_name = $event.target.value.trim()
          "
          @input-classification="
            store.state.formCreateSummary.classification = $event.target.value.trim()
          "
          @add-humanResearchData="addHumanResearchDataRow"
          @delete-humanResearchData="deleteHumanResearchData($event)"
          @add-humanReportedData="addHumanReportedDataRow"
          @delete-humanReportedData="deleteHumanReportedData($event)"
          @add-animalResearchResultData="addAnimalResearchResultDataRow"
          @delete-animalResearchResultData="
            deleteAnimalResearchResultData($event)
          "
          @add-interviewData="addInterviewDataRow"
          @delete-interviewData="deleteInterviewData($event)"
          @add-packageInsertData="addPackageInsertDataRow"
          @delete-packageInsertData="deletePackageInsertData($event)"
          @add-otherMaterialsData="addOtherMaterialsDataRow"
          @delete-otherMaterialsData="deleteOtherMaterialsData($event)"
          @add-motherRiskStatementData="addMotherRiskStatementRow"
          @delete-motherRiskStatementData="
            deleteMotherRiskStatementData($event)
          "
          @click-humanResearchData-row="clickHumanResearchDataRow($event)"
          @click-humanReportedData-row="clickHumanReportedDataRow($event)"
          @click-animalResearchResultData-row="
            clickAnimalResearchResultDataRow($event)
          "
          @click-interviewData-row="clickInterviewDataRow($event)"
          @click-packageInsertData-row="clickPackageInsertDataRow($event)"
          @click-otherMaterialsData-row="clickOtherMaterialsDataRow($event)"
          @click-motherRiskStatementData-row="
            clickMotherRiskStatementRow($event)
          "
        />
        <div class="flex justify-center mt-[40px]">
          <MenuButtonComponent
            class="border-2 mr-4 h-11"
            type="button"
            @click="saveToDraft"
            :title="textDisplay.buttonHold"
            :btn-style="'btn_bordered'"
          />
          <MenuButtonComponent
            class="border-2 mr-4 h-11"
            type="submit"
            :title="textDisplay.buttonSave"
          />
        </div>
      </form>
    </div>
  </div>
  <div class="z-20 absolute top-1/3 left-1/2" v-if="store.state.isLoading">
    <LoadingComponent />
  </div>
  <HumanResearchModalComponent
    :year_country="tableForm.year_country"
    :author="tableForm.author"
    :publication_magazine="tableForm.publication_magazine"
    :title="tableForm.title"
    :design="tableForm.design"
    :exposure_n_control_n="tableForm.exposure_n_control_n"
    :outcome="tableForm.outcome"
    :result="tableForm.result"
    :recruitment="tableForm.recruitment"
    :reason="tableForm.reason"
    :brtm="tableForm.brtm"
    :memo="tableForm.memo"
    :endnote_link="tableForm.endnote_link"
    @input-year_country-modal="tableForm.year_country = $event"
    @input-author-modal="tableForm.author = $event"
    @input-publication_magazine-modal="tableForm.publication_magazine = $event"
    @input-title-modal="tableForm.title = $event"
    @input-design-modal="tableForm.design = $event"
    @input-exposure_n_control_n-modal="tableForm.exposure_n_control_n = $event"
    @input-outcome-modal="tableForm.outcome = $event"
    @input-result-modal="tableForm.result = $event"
    @input-recruitment-modal="tableForm.recruitment = $event"
    @input-reason-modal="tableForm.reason = $event"
    @input-brtm-modal="tableForm.brtm = $event"
    @input-memo-modal="tableForm.memo = $event"
    @input-endnote_link-modal="tableForm.endnote_link = $event"
    v-if="isHumanResearchModalOpen"
    @closeModal="isHumanResearchModalOpen = false; this.isAddingRow = false"
    @saveModal="saveRow"
    @cancelModal="cancelModal"
  />
  <AnimalTestModalComponent
    :year_country="tableForm.year_country"
    :author="tableForm.author"
    :publication_magazine="tableForm.publication_magazine"
    :title="tableForm.title"
    :animal_species="tableForm.animal_species"
    :outcome="tableForm.outcome"
    :dose="tableForm.dose"
    :result="tableForm.result"
    :brtm="tableForm.brtm"
    :memo="tableForm.memo"
    :endnote_link="tableForm.endnote_link"
    @input-year_country-modal="tableForm.year_country = $event"
    @input-author-modal="tableForm.author = $event"
    @input-publication_magazine-modal="tableForm.publication_magazine = $event"
    @input-title-modal="tableForm.title = $event"
    @input-animal_species-modal="tableForm.animal_species = $event"
    @input-outcome-modal="tableForm.outcome = $event"
    @input-dose-modal="tableForm.dose = $event"
    @input-result-modal="tableForm.result = $event"
    @input-brtm-modal="tableForm.brtm = $event"
    @input-memo-modal="tableForm.memo = $event"
    @input-endnote_link-modal="tableForm.endnote_link = $event"
    v-if="isAnimalTestModalOpen"
    @closeModal="isAnimalTestModalOpen = false; this.isAddingRow = false"
    @saveModal="saveRow"
    @cancelModal="cancelModal"
  />
  <HumanReportedModalComponent
    :year_country="tableForm.year_country"
    :author="tableForm.author"
    :publication_magazine="tableForm.publication_magazine"
    :title="tableForm.title"
    :result="tableForm.result"
    :recruitment="tableForm.recruitment"
    :judgment_reason="tableForm.judgment_reason"
    :brtm="tableForm.brtm"
    :memo="tableForm.memo"
    :endnote_link="tableForm.endnote_link"
    @input-year_country-modal="tableForm.year_country = $event"
    @input-author-modal="tableForm.author = $event"
    @input-publication_magazine-modal="tableForm.publication_magazine = $event"
    @input-title-modal="tableForm.title = $event"
    @input-result-modal="tableForm.result = $event"
    @input-recruitment-modal="tableForm.recruitment = $event"
    @input-judgment_reason-modal="tableForm.judgment_reason = $event"
    @input-brtm-modal="tableForm.brtm = $event"
    @input-memo-modal="tableForm.memo = $event"
    @input-endnote_link-modal="tableForm.endnote_link = $event"
    v-if="isHumanReportedModalOpen"
    @closeModal="isHumanReportedModalOpen = false; this.isAddingRow = false"
    @saveModal="saveRow"
    @cancelModal="cancelModal"
  />
  <InterviewModalComponent
    :postscript_date="tableForm.postscript_date"
    :product_name="tableForm.product_name"
    :version="tableForm.version"
    :pdf_link="tableForm.pdf_link"
    @input-postscript_date-modal="tableForm.postscript_date = $event"
    @input-product_name-modal="tableForm.product_name = $event"
    @input-version-modal="tableForm.version = $event"
    @input-pdf_link-modal="tableForm.pdf_link = $event"
    v-if="isInterviewModalOpen"
    @closeModal="isInterviewModalOpen = false; this.isAddingRow = false"
    @saveModal="saveRow"
    @cancelModal="cancelModal"
  />
  <MotherRiskStatementModalComponent
    :postscript_date="tableForm.postscript_date"
    :year="tableForm.year"
    :destination_link="tableForm.destination_link"
    @input-postscript_date-modal="tableForm.postscript_date = $event"
    @input-year-modal="tableForm.year = $event"
    @input-destination_link-modal="tableForm.destination_link = $event"
    v-if="isMotherRiskStatemantModalOpen"
    @closeModal="isMotherRiskStatemantModalOpen = false; this.isAddingRow = false"
    @saveModal="saveRow"
    @cancelModal="cancelModal"
  />
  <PackageInsertModalComponent
    :postscript_date="tableForm.postscript_date"
    :reference_page="tableForm.reference_page"
    :version="tableForm.version"
    :pdf_link="tableForm.pdf_link"
    @input-postscript_date-modal="tableForm.postscript_date = $event"
    @input-reference_page-modal="tableForm.reference_page = $event"
    @input-version-modal="tableForm.version = $event"
    @input-pdf_link-modal="tableForm.pdf_link = $event"
    v-if="isPackageInsertModalOpen"
    @closeModal="isPackageInsertModalOpen = false; this.isAddingRow = false"
    @saveModal="saveRow"
    @cancelModal="cancelModal"
  />
  <OtherMaterialsModalComponent
    :postscript_date="tableForm.postscript_date"
    :document_content="tableForm.document_content"
    :destination_link="tableForm.destination_link"
    @input-postscript_date-modal="tableForm.postscript_date = $event"
    @input-document_content-modal="tableForm.document_content = $event"
    @input-destination_link-modal="tableForm.destination_link = $event"
    v-if="isOtherMaterialsModalOpen"
    @closeModal="isOtherMaterialsModalOpen = false; this.isAddingRow = false"
    @saveModal="saveRow"
    @cancelModal="cancelModal"
  />
</template>

<script>
import PublicSummaryComponent from './shared/PublicSummaryComponent.vue'
import AllInfoSummaryComponent from './shared/AllInfoSummaryComponent.vue'
import HumanResearchModalComponent from './shared/HumanResearchModalComponent.vue'
import AnimalTestModalComponent from './shared/AnimalTestModalComponent.vue'
import HumanReportedModalComponent from './shared/HumanReportedModalComponent.vue'
import InterviewModalComponent from './shared/InterviewModalComponent.vue'
import MotherRiskStatementModalComponent from './shared/MotherRiskStatementModalComponent.vue'
import PackageInsertModalComponent from './shared/PackageInsertModalComponent.vue'
import OtherMaterialsModalComponent from './shared/OtherMaterialsModalComponent.vue'
import MenuButtonComponent from '../../common/menu/MenuButtonComponent.vue'
import { textDisplay } from '../../../common/TextDisplay'
import LoadingComponent from '../../common/LoadingComponent.vue'
import { NotificationMessage } from '../../../common/NotificationMessage'
import { ACTION_TYPE } from '../../../common/constants'
import { useStore } from 'vuex'

/**
 * @module CreateSummaryComponent
 */

export default {
    components: {
        PublicSummaryComponent,
        AllInfoSummaryComponent,
        MenuButtonComponent,
        LoadingComponent,
        HumanResearchModalComponent,
        AnimalTestModalComponent,
        HumanReportedModalComponent,
        InterviewModalComponent,
        MotherRiskStatementModalComponent,
        PackageInsertModalComponent,
        OtherMaterialsModalComponent
    },
    data() {
        return {
            textDisplay,
            isPublic: true,
            store: useStore(),
            isSaved: false,
            tableForm: {
                type: '',
                index: ''
            },
            isAddingRow: false,
            isHumanResearchModalOpen: false,
            isHumanReportedModalOpen: false,
            isInterviewModalOpen: false,
            isPackageInsertModalOpen: false,
            isOtherMaterialsModalOpen: false,
            isMotherRiskStatemantModalOpen: false,
            isAnimalTestModalOpen: false
        }
    },
    watch: {
        'store.state.formCreateSummary': {
            handler(v) {
                if (!this.isSaved) {
                    this.store.state.isSummaryBeingCreate = true
                }
            },
            deep: true
        }
    },
    beforeMount() {
        if (!this.store.state.isRenewToken) {
            this.store.state.isSummaryBeingCreate = false
        } else {
            this.store.state.isRenewToken = false
        }
    },
    methods: {

        /**
         * 新しいサマリーを作成するためにvuexアクションを呼び出す
         */
        createSummary() {
            let isValid = false
            if (this.store.state.formCreateSummary.drug_name === '') {
                NotificationMessage(this.textDisplay.msg_026, 3000, ACTION_TYPE.error)
                this.store.state.drug_name_error_text = this.textDisplay.msg_026
                isValid = true
            }
            if (this.store.state.formCreateSummary.drug_kana === '') {
                NotificationMessage(this.textDisplay.msg_030, 3000, ACTION_TYPE.error)
                this.store.state.drug_name_kana_error_text = this.textDisplay.msg_030
                isValid = true
            }
            if (isValid) {
                return
            }
            this.store.state.isLoading = true
            const payload = {
                drug_name: this.store.state.formCreateSummary.drug_name,
                drug_kana: this.store.state.formCreateSummary.drug_kana,
                general: this.store.state.formCreateSummary.general,
                effect_for_anomaly:
          this.store.state.formCreateSummary.effect_for_anomaly,
                effect_for_baby: this.store.state.formCreateSummary.effect_for_baby,
                other: this.store.state.formCreateSummary.other,
                placenta_passage: this.store.state.formCreateSummary.placenta_passage,
                animal_test: this.store.state.formCreateSummary.animal_test,
                attached: this.store.state.formCreateSummary.attached,
                summarize: this.store.state.formCreateSummary.summarize,
                pharmacological_name:
          this.store.state.formCreateSummary.pharmacological_name,
                major_drug_name: this.store.state.formCreateSummary.major_drug_name,
                classification: this.store.state.formCreateSummary.classification
            }
            payload.literatures = [
                ...this.store.state.formCreateSummary.humanResearchData.map((value) => {
                    delete value.updated_at
                    return { ...value, item: '41' }
                }),
                ...this.store.state.formCreateSummary.humanReportedData.map((value) => {
                    delete value.updated_at
                    return { ...value, item: '42' }
                }),
                ...this.store.state.formCreateSummary.animalResearchResultData.map(
                    (value) => {
                        delete value.updated_at
                        return { ...value, item: '43' }
                    }
                ),
                ...this.store.state.formCreateSummary.interviewData.map((value) => {
                    delete value.updated_at
                    return { ...value, item: '44' }
                }),
                ...this.store.state.formCreateSummary.packageInsertData.map((value) => {
                    delete value.updated_at
                    return { ...value, item: '45' }
                }),
                ...this.store.state.formCreateSummary.otherMaterialsData.map(
                    (value) => {
                        delete value.updated_at
                        return { ...value, item: '46' }
                    }
                ),
                ...this.store.state.formCreateSummary.motherRiskStatementData.map(
                    (value) => {
                        delete value.updated_at
                        return { ...value, item: '50' }
                    }
                )
            ]
            this.store
                .dispatch('createSummary', payload)
                .then((result) => {
                    NotificationMessage(
                        this.textDisplay.msg_016,
                        3000,
                        ACTION_TYPE.success
                    )
                    this.isSaved = true
                    this.store.state.formCreateSummary = {
                        // Public
                        drug_name: '',
                        drug_kana: '',
                        general: '',
                        effect_for_anomaly: '',
                        effect_for_baby: '',
                        other: '',
                        placenta_passage: '',
                        animal_test: '',
                        attached: '',
                        summarize: '',
                        // All Info
                        pharmacological_name: '',
                        major_drug_name: '',
                        classification: '',
                        humanResearchData: [],
                        humanReportedData: [],
                        animalResearchResultData: [],
                        interviewData: [],
                        packageInsertData: [],
                        otherMaterialsData: [],
                        motherRiskStatementData: []
                    }
                    this.store.state.isSummaryBeingCreate = false
                    this.$router.push(`/summary/view-summary/${result.data.summary_id}`)
                })
                .catch((e) => {
                    if (e.response.data.error_code === 'MSG_027') {
                        this.store.state.drug_name_error_text = this.textDisplay.msg_027
                        NotificationMessage(this.textDisplay.msg_027, 3000, ACTION_TYPE.error)
                    }
                    if (e.response.data.error_code === 'MSG_031') {
                        this.store.state.drug_name_kana_error_text =
              this.textDisplay.msg_031
                        NotificationMessage(this.textDisplay.msg_031, 3000, ACTION_TYPE.error)
                    }
                })
                .finally(() => {
                    this.store.state.isLoading = false
                })
        },

        /**
         * 新しいサマリー案を作成するためのvuexアクションを呼び出す
         */
        saveToDraft() {
            let isValid = false
            if (this.store.state.formCreateSummary.drug_name === '') {
                NotificationMessage(this.textDisplay.msg_026, 3000, ACTION_TYPE.error)
                this.store.state.drug_name_error_text = this.textDisplay.msg_026
                isValid = true
            }
            if (this.store.state.formCreateSummary.drug_kana === '') {
                NotificationMessage(this.textDisplay.msg_030, 3000, ACTION_TYPE.error)
                this.store.state.drug_name_kana_error_text = this.textDisplay.msg_030
                isValid = true
                return
            }
            if (isValid) {
                return
            }
            this.store.state.isLoading = true
            const payload = {
                drug_name: this.store.state.formCreateSummary.drug_name,
                drug_kana: this.store.state.formCreateSummary.drug_kana,
                general: this.store.state.formCreateSummary.general,
                effect_for_anomaly:
          this.store.state.formCreateSummary.effect_for_anomaly,
                effect_for_baby: this.store.state.formCreateSummary.effect_for_baby,
                other: this.store.state.formCreateSummary.other,
                placenta_passage: this.store.state.formCreateSummary.placenta_passage,
                animal_test: this.store.state.formCreateSummary.animal_test,
                attached: this.store.state.formCreateSummary.attached,
                summarize: this.store.state.formCreateSummary.summarize,
                pharmacological_name:
          this.store.state.formCreateSummary.pharmacological_name,
                major_drug_name: this.store.state.formCreateSummary.major_drug_name,
                classification: this.store.state.formCreateSummary.classification,
                temp: true
            }
            payload.literatures = [
                ...this.store.state.formCreateSummary.humanResearchData.map((value) => {
                    delete value.updated_at
                    return { ...value, item: '41' }
                }),
                ...this.store.state.formCreateSummary.humanReportedData.map((value) => {
                    delete value.updated_at
                    return { ...value, item: '42' }
                }),
                ...this.store.state.formCreateSummary.animalResearchResultData.map(
                    (value) => {
                        delete value.updated_at
                        return { ...value, item: '43' }
                    }
                ),
                ...this.store.state.formCreateSummary.interviewData.map((value) => {
                    delete value.updated_at
                    return { ...value, item: '44' }
                }),
                ...this.store.state.formCreateSummary.packageInsertData.map((value) => {
                    delete value.updated_at
                    return { ...value, item: '45' }
                }),
                ...this.store.state.formCreateSummary.otherMaterialsData.map(
                    (value) => {
                        delete value.updated_at
                        return { ...value, item: '46' }
                    }
                ),
                ...this.store.state.formCreateSummary.motherRiskStatementData.map(
                    (value) => {
                        delete value.updated_at
                        return { ...value, item: '50' }
                    }
                )
            ]
            this.store
                .dispatch('createSummary', payload)
                .then((result) => {
                    NotificationMessage(
                        this.textDisplay.msg_save_to_draft_successfully,
                        3000,
                        ACTION_TYPE.success
                    )
                    this.isSaved = true
                    this.store.state.formCreateSummary = {
                        // Public
                        drug_name: '',
                        drug_kana: '',
                        general: '',
                        effect_for_anomaly: '',
                        effect_for_baby: '',
                        other: '',
                        placenta_passage: '',
                        animal_test: '',
                        attached: '',
                        summarize: '',
                        // All Info
                        pharmacological_name: '',
                        major_drug_name: '',
                        classification: '',
                        humanResearchData: [],
                        humanReportedData: [],
                        animalResearchResultData: [],
                        interviewData: [],
                        packageInsertData: [],
                        otherMaterialsData: [],
                        motherRiskStatementData: []
                    }
                    this.store.state.isSummaryBeingCreate = false
                    this.$router.push(`/summary/view-summary/${result.data.id}`)
                })
                .catch((e) => {
                    if (e.response.data.error_code === 'MSG_027') {
                        this.store.state.drug_name_error_text = this.textDisplay.msg_027
                        NotificationMessage(this.textDisplay.msg_027, 3000, ACTION_TYPE.error)
                    }
                    if (e.response.data.error_code === 'MSG_031') {
                        this.store.state.drug_name_kana_error_text =
              this.textDisplay.msg_031
                        NotificationMessage(this.textDisplay.msg_031, 3000, ACTION_TYPE.error)
                    }
                })
                .finally(() => {
                    this.store.state.isLoading = false
                })
        },

        /**
         * テーブル4.1の選択した行を削除する
         * @param {Array<number>} e -行番号一覧
         */
        deleteHumanResearchData(e) {
            for (let i = e.length - 1; i >= 0; i--) {
                this.store.state.formCreateSummary.humanResearchData.splice(e[i], 1)
            }
        },

        /**
         * 表 4.2の選択した行を削除する
         * @param {Array<number>} e -行番号一覧
         */
        deleteHumanReportedData(e) {
            for (let i = e.length - 1; i >= 0; i--) {
                this.store.state.formCreateSummary.humanReportedData.splice(e[i], 1)
            }
        },

        /**
         * 表 4.3の選択した行を削除する
         * @param {Array<number>} e -行番号一覧
         */
        deleteAnimalResearchResultData(e) {
            for (let i = e.length - 1; i >= 0; i--) {
                this.store.state.formCreateSummary.animalResearchResultData.splice(
                    e[i],
                    1
                )
            }
        },

        /**
         * 表 4.4の選択した行を削除する
         * @param {Array<number>} e -行番号一覧
         */
        deleteInterviewData(e) {
            for (let i = e.length - 1; i >= 0; i--) {
                this.store.state.formCreateSummary.interviewData.splice(e[i], 1)
            }
        },

        /**
         * 表 4.5の選択した行を削除する
         * @param {Array<number>} e -行番号一覧
         */
        deletePackageInsertData(e) {
            for (let i = e.length - 1; i >= 0; i--) {
                this.store.state.formCreateSummary.packageInsertData.splice(e[i], 1)
            }
        },

        /**
         * 表 4.6の選択した行を削除する
         * @param {Array<number>} e -行番号一覧
         */
        deleteOtherMaterialsData(e) {
            for (let i = e.length - 1; i >= 0; i--) {
                this.store.state.formCreateSummary.otherMaterialsData.splice(e[i], 1)
            }
        },

        /**
         * 表 5.0の選択した行を削除する
         * @param {Array<number>} e -行番号一覧
         */
        deleteMotherRiskStatementData(e) {
            for (let i = e.length - 1; i >= 0; i--) {
                this.store.state.formCreateSummary.motherRiskStatementData.splice(
                    e[i],
                    1
                )
            }
        },

        /**
         * 薬品名の変更をキャッチする
         * @param {Object} e -変化するイベント（vuejsの概念に基づく）
         * @param {string} e.target.value - 薬剤名入力にテキストが入力された
         */
        drugNameChange(e) {
            this.store.state.formCreateSummary.drug_name = e.target.value.trim()
            if (this.store.state.formCreateSummary.drug_name === '') {
                this.store.state.drug_name_error_text = this.textDisplay.msg_026
            } else {
                this.store.state.drug_name_error_text = ''
            }
        },

        /**
         * 薬品名の変更をキャッチする kana
         * @param {Object} e -変化するイベント（vuejsの概念に基づく）
         * @param {string} e.target.value - 薬剤名のカナ入力でテキストが入力された
         * */
        drugKanaChange(e) {
            this.store.state.formCreateSummary.drug_kana = e.target.value.trim()
            if (this.store.state.formCreateSummary.drug_kana === '') {
                this.store.state.drug_name_kana_error_text = this.textDisplay.msg_030
            } else {
                this.store.state.drug_name_kana_error_text = ''
            }
        },

        /**
         * テーブル 4.1の上部に新しい行を追加するためのポップアップを開く
         */
        addHumanResearchDataRow() {
            this.isAddingRow = true
            this.clickHumanResearchDataRow(
                0
            )
        },

        /**
         * テーブル 4.2の上部に新しい行を追加するためのポップアップを開く
         */
        addHumanReportedDataRow() {
            this.isAddingRow = true
            this.clickHumanReportedDataRow(0)
        },

        /**
         * テーブル 4.3の上部に新しい行を追加するためのポップアップを開く
         */
        addAnimalResearchResultDataRow() {
            this.isAddingRow = true
            this.clickAnimalResearchResultDataRow(0)
        },

        /**
         * テーブル 4.4の上部に新しい行を追加するためのポップアップを開く
         */
        addInterviewDataRow() {
            this.isAddingRow = true
            this.clickInterviewDataRow(0)
        },

        /**
         * テーブル 4.5の上部に新しい行を追加するためのポップアップを開く
         */
        addPackageInsertDataRow() {
            this.isAddingRow = true
            this.clickPackageInsertDataRow(0)
        },

        /**
         * テーブル 4.6の上部に新しい行を追加するためのポップアップを開く
         */
        addOtherMaterialsDataRow() {
            this.isAddingRow = true
            this.clickOtherMaterialsDataRow(0)
        },

        /**
         * テーブル 5.0の上部に新しい行を追加するためのポップアップを開く
         */
        addMotherRiskStatementRow() {
            this.isAddingRow = true
            this.clickMotherRiskStatementRow(0)
        },

        /**
         * 表4.1の行をクリック
         * @param {number} e - 行のインデックス"
         * */
        clickHumanResearchDataRow(e) {
            if (this.isAddingRow) {
                this.tableForm = {
                    year_country: '',
                    author: '',
                    publication_magazine: '',
                    title: '',
                    design: '',
                    exposure_n_control_n: '',
                    outcome: '',
                    result: '',
                    recruitment: true,
                    reason: '',
                    brtm: '',
                    memo: '',
                    endnote_link: ''
                }
            } else {
                this.tableForm = {
                    ...this.store.state.formCreateSummary.humanResearchData[e]
                }
            }
            if (this.tableForm.recruitment === false) {
                this.tableForm.recruitment = this.textDisplay.selectOptionRecruitment[1]
            } else {
                this.tableForm.recruitment = this.textDisplay.selectOptionRecruitment[0]
            }
            this.tableForm.type = 41
            this.tableForm.index = e
            this.isHumanResearchModalOpen = true
        },

        /**
         * 表4.2の行をクリック
         * @param {number} e - 行のインデックス"
         * */
        clickHumanReportedDataRow(e) {
            if (this.isAddingRow) {
                this.tableForm = {
                    year_country: '',
                    author: '',
                    publication_magazine: '',
                    title: '',
                    result: '',
                    recruitment: true,
                    judgment_reason: '',
                    brtm: '',
                    memo: '',
                    endnote_link: ''
                }
            } else {
                this.tableForm = {
                    ...this.store.state.formCreateSummary.humanReportedData[e]
                }
            }
            if (this.tableForm.recruitment === false) {
                this.tableForm.recruitment = this.textDisplay.selectOptionRecruitment[1]
            } else {
                this.tableForm.recruitment = this.textDisplay.selectOptionRecruitment[0]
            }
            this.tableForm.type = 42
            this.tableForm.index = e
            this.isHumanReportedModalOpen = true
        },

        /**
         * 表4.3の行をクリック
         * @param {number} e - 行のインデックス"
         * */
        clickAnimalResearchResultDataRow(e) {
            if (this.isAddingRow) {
                this.tableForm = {
                    year_country: '',
                    author: '',
                    publication_magazine: '',
                    title: '',
                    animal_species: '',
                    outcome: '',
                    dose: '',
                    result: '',
                    brtm: '',
                    memo: '',
                    endnote_link: ''
                }
            } else {
                this.tableForm = {
                    ...this.store.state.formCreateSummary.animalResearchResultData[e]
                }
            }
            this.tableForm.type = 43
            this.tableForm.index = e
            this.isAnimalTestModalOpen = true
        },

        /**
         * 表4.4の行をクリック
         * @param {number} e - 行のインデックス"
         * */
        clickInterviewDataRow(e) {
            if (this.isAddingRow) {
                this.tableForm = {
                    postscript_date: '',
                    product_name: '',
                    version: '',
                    pdf_link: ''
                }
            } else {
                this.tableForm = {
                    ...this.store.state.formCreateSummary.interviewData[e]
                }
            }
            this.tableForm.type = 44
            this.tableForm.index = e
            this.isInterviewModalOpen = true
        },

        /**
         * 表4.5の行をクリック
         * @param {number} e - 行のインデックス"
         * */
        clickPackageInsertDataRow(e) {
            if (this.isAddingRow) {
                this.tableForm = {
                    postscript_date: '',
                    reference_page: '',
                    version: '',
                    pdf_link: ''
                }
            } else {
                this.tableForm = {
                    ...this.store.state.formCreateSummary.packageInsertData[e]
                }
            }
            this.tableForm.type = 45
            this.tableForm.index = e
            this.isPackageInsertModalOpen = true
        },

        /**
         * 表4.6の行をクリック
         * @param {number} e - 行のインデックス"
         * */
        clickOtherMaterialsDataRow(e) {
            if (this.isAddingRow) {
                this.tableForm = {
                    postscript_date: '',
                    document_content: '',
                    destination_link: ''
                }
            } else {
                this.tableForm = {
                    ...this.store.state.formCreateSummary.otherMaterialsData[e]
                }
            }
            this.tableForm.type = 46
            this.tableForm.index = e
            this.isOtherMaterialsModalOpen = true
        },

        /**
         * 表5.0の行をクリック
         * @param {number} e - 行のインデックス"
         * */
        clickMotherRiskStatementRow(e) {
            if (this.isAddingRow) {
                this.tableForm = {
                    references: '',
                    reference_page: '',
                    endnote_link: '',
                    updated_at: ''
                }
            } else {
                this.tableForm = {
                    ...this.store.state.formCreateSummary.motherRiskStatementData[e]
                }
            }
            this.tableForm.year = this.textDisplay.selectOptionYear.find(element => element.value === this.tableForm.year)
            this.tableForm.type = 50
            this.tableForm.index = e
            this.isMotherRiskStatemantModalOpen = true
        },

        /**
         * テーブルデータに新しい行を追加して保存する
         */
        saveRow() {
            if (this.isAddingRow) {
                switch (this.tableForm.type) {
                case 41:
                    this.tableForm.recruitment = this.tableForm.recruitment.value
                    this.store.state.formCreateSummary.humanResearchData.splice(0, 0, { ...this.tableForm })
                    this.tableForm = {}
                    this.isHumanResearchModalOpen = false
                    this.isAddingRow = false
                    this.$refs.allInfoComponent.addHumanResearchRow()
                    break
                case 42:
                    this.tableForm.recruitment = this.tableForm.recruitment.value
                    this.store.state.formCreateSummary.humanReportedData.splice(0, 0, { ...this.tableForm })
                    this.tableForm = {}
                    this.isHumanReportedModalOpen = false
                    this.isAddingRow = false
                    this.$refs.allInfoComponent.addHumanReportedRow()
                    break
                case 43:
                    this.store.state.formCreateSummary.animalResearchResultData.splice(0, 0, { ...this.tableForm })
                    this.tableForm = {}
                    this.isAnimalTestModalOpen = false
                    this.isAddingRow = false
                    this.$refs.allInfoComponent.addAnimalResearchResultRow()
                    break
                case 44:
                    this.store.state.formCreateSummary.interviewData.splice(0, 0, { ...this.tableForm })
                    this.tableForm = {}
                    this.isInterviewModalOpen = false
                    this.isAddingRow = false
                    this.$refs.allInfoComponent.addInterviewRow()
                    break
                case 45:
                    this.store.state.formCreateSummary.packageInsertData.splice(0, 0, { ...this.tableForm })
                    this.tableForm = {}
                    this.isPackageInsertModalOpen = false
                    this.isAddingRow = false
                    this.$refs.allInfoComponent.addPackageInsertRow()
                    break
                case 46:
                    this.store.state.formCreateSummary.otherMaterialsData.splice(0, 0, { ...this.tableForm })
                    this.tableForm = {}
                    this.isOtherMaterialsModalOpen = false
                    this.isAddingRow = false
                    this.$refs.allInfoComponent.addOtherMaterialsRow()
                    break
                case 50:
                    if (this.tableForm.year) {
                        this.tableForm.year = this.tableForm.year.value
                    }
                    this.store.state.formCreateSummary.motherRiskStatementData.splice(0, 0, { ...this.tableForm })
                    this.tableForm = {}
                    this.isMotherRiskStatemantModalOpen = false
                    this.isAddingRow = false
                    this.$refs.allInfoComponent.addMotherRiskStatementRow()
                    break
                }
            } else {
                switch (this.tableForm.type) {
                case 41:
                    this.tableForm.recruitment = this.tableForm.recruitment.value
                    this.store.state.formCreateSummary.humanResearchData[
                        this.tableForm.index
                    ] = { ...this.tableForm }
                    this.tableForm = {}
                    this.isHumanResearchModalOpen = false
                    break
                case 42:
                    this.tableForm.recruitment = this.tableForm.recruitment.value
                    this.store.state.formCreateSummary.humanReportedData[
                        this.tableForm.index
                    ] = { ...this.tableForm }
                    this.tableForm = {}
                    this.isHumanReportedModalOpen = false
                    break
                case 43:
                    this.store.state.formCreateSummary.animalResearchResultData[
                        this.tableForm.index
                    ] = { ...this.tableForm }
                    this.tableForm = {}
                    this.isAnimalTestModalOpen = false
                    break
                case 44:
                    this.store.state.formCreateSummary.interviewData[
                        this.tableForm.index
                    ] = { ...this.tableForm }
                    this.tableForm = {}
                    this.isInterviewModalOpen = false
                    break
                case 45:
                    this.store.state.formCreateSummary.packageInsertData[
                        this.tableForm.index
                    ] = { ...this.tableForm }
                    this.tableForm = {}
                    this.isPackageInsertModalOpen = false
                    break
                case 46:
                    this.store.state.formCreateSummary.otherMaterialsData[
                        this.tableForm.index
                    ] = { ...this.tableForm }
                    this.tableForm = {}
                    this.isOtherMaterialsModalOpen = false
                    break
                case 50:
                    if (this.tableForm.year) {
                        this.tableForm.year = this.tableForm.year.value
                    }
                    this.store.state.formCreateSummary.motherRiskStatementData[
                        this.tableForm.index
                    ] = { ...this.tableForm }
                    this.tableForm = {}
                    this.isMotherRiskStatemantModalOpen = false
                    break
                }
            }
        },

        /**
         * テーブルデータへの行の追加をキャンセルする
         */
        cancelModal() {
            this.tableForm = {}
            this.isMotherRiskStatemantModalOpen = false
            this.isOtherMaterialsModalOpen = false
            this.isPackageInsertModalOpen = false
            this.isInterviewModalOpen = false
            this.isAnimalTestModalOpen = false
            this.isHumanResearchModalOpen = false
            this.isHumanReportedModalOpen = false
            this.isAddingRow = false
        }
    }
}
</script>

<style>
.header-create-summary {
  color: #1f2533;
  font-size: 16px;
  font-weight: 500;
  border-bottom: 1px solid #e8f0ff;
}
.tab-summary {
  border-bottom: 1px solid #015db2;
}
.tab-item {
  border: 1px solid #015db2;
  border-bottom: none;
  box-sizing: border-box;
  border-radius: 10px 10px 0px 0px;
  min-width: 145px;
  background-color: #fff;
}
.tab-item span {
  display: inline-block;
  color: #015db2;
  font-size: 16px;
  font-weight: bold;
  padding: 12px 18px;
}
.tab-item.active {
  background-color: #015db2;
}
.tab-item.active span {
  border-bottom: 1px solid #015db2;
  color: #fbfbfb;
}
</style>
