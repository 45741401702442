import { textDisplay } from '../common/TextDisplay'
export default {
    counter: 0,
    name: 'vue',
    dataTableTest: [],
    user: {
        access_token: '',
        username: '',
        expired_at: '',
        authority: '',
        user_id: ''
    },
    userList: {},
    summaries: { items: [] },
    currentSummaryPage: 1,
    currentSummarySelectedIndex: -1,
    formSearchSummary: {
        drug_name: '',
        pharmacological_name: '',
        major_drug_name: '',
        classification: '',
        sort_by: textDisplay.selectOptionSearch1[0],
        order_by: textDisplay.selectOptionSearch2[0],
        status: textDisplay.selectOptionSearchStatus[0]
    },
    formCreateSummary: {
        // Public
        drug_name: '',
        drug_kana: '',
        general: '',
        effect_for_anomaly: '',
        effect_for_baby: '',
        other: '',
        placenta_passage: '',
        animal_test: '',
        attached: '',
        summarize: '',
        // All Info
        pharmacological_name: '',
        major_drug_name: '',
        classification: '',
        humanResearchData: [],
        humanReportedData: [],
        animalResearchResultData: [],
        interviewData: [],
        packageInsertData: [],
        otherMaterialsData: [],
        motherRiskStatementData: []
    },
    formUpdateSummary: {
        id: '',
        summary_id: '',
        // Public
        drug_name: '',
        drug_kana: '',
        general: '',
        effect_for_anomaly: '',
        effect_for_baby: '',
        other: '',
        placenta_passage: '',
        animal_test: '',
        attached: '',
        summarize: '',
        update_user: '',
        update_date: '',
        update_username: '',
        version: '',
        status: '',
        // All Info
        pharmacological_name: '',
        major_drug_name: '',
        classification: '',
        humanResearchData: [],
        humanReportedData: [],
        animalResearchResultData: [],
        interviewData: [],
        packageInsertData: [],
        otherMaterialsData: [],
        motherRiskStatementData: []
    },
    drug_name_error_text: '',
    drug_name_kana_error_text: '',
    isSummaryBeingEdit: false,
    isSummaryBeingCreate: false,
    isUserBeingEdit: false,
    isUserBeingCreate: false,
    isRenewToken: false
}
