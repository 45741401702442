<template>
    <div class="bg-white flex flex-col items-center rounded-xl logout-container">
        <svg>
            <use xlink:href="../../assets/svg_symbol.svg#icon-logout-large" xmlns:xlink="http://www.w3.org/1999/xlink"></use>
        </svg>
        <div class="font-normal logout-confirm text-lg mt-7 mb-10">{{textDisplay.logoutConfirm}}</div>
        <div class="inline-block btn-logout cursor-pointer font-bold" @click="logout()">{{textDisplay.logoutBtn}}</div>
    </div>
</template>

<script>
import { textDisplay } from '../../common/TextDisplay'
import { useStore } from 'vuex'
/**
 * @module LogoutComponent
 */
export default {
    name: 'LogoutComponent',
    data() {
        return {
            textDisplay: textDisplay,
            store: useStore()
        }
    },
    methods: {
        /**
         * @description ログアウト
         */
        logout() {
            this.store.dispatch('logout').then((res) => {
                this.$router.push('/auth/login')
            })
        }
    }
}
</script>

<style scoped>
    .logout-container {
        width: 480px;
        padding: 54px;
        margin: 0 auto;
    }

    .logout-container svg {
        width: 81px;
        height: 81px;
    }

    .logout-confirm {
        color: #1F2533;
    }

    .btn-logout {
        color: #FBFBFB;
        background: #015DB2;
        border-radius: 10px;
        padding: 12px 40px;
    }

</style>
