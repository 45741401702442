<template>
  <div class="w-full min-h-full absolute top-0 left-0 bottom-0 overflow-scroll">
    <form
      class="w-full min-h-full py-16 px-28 flex flex-col justify-between"
      @submit.prevent="editUser"
    >
      <div class="flex flex-col items-center">
        <div class="w-[312px] flex flex-col justify-start mb-12">
          <label class="text-left">{{ textDisplay.inputUsernameTitle }}<sup>*</sup></label>
          <MenuInputComponent
            title=""
            type="text"
            :input-style="'input_bordered'"
            class="w-full"
            :value="form.username"
            v-model="form.username"
            @input="usernameChange()"
          />
          <br v-if="warningUsernameNull === ''" />
          <div v-else class="error-msg mt-1 text-left">
            {{ warningUsernameNull }}
          </div>
        </div>
        <div class="w-[312px] flex flex-col justify-start mb-12">
          <label class="text-left">{{ textDisplay.inputUserIdTitle }}<sup>*</sup>{{textDisplay.inputUserIdNote}}</label>
          <MenuInputComponent
            title=""
            type="text"
            class="w-full"
            :value="form.user_id"
            v-model="form.user_id"
            :isDisabled="true"
            :maxLength="6"
            @input="userIdChange()"
          />
          <br v-if="warningUserIdNull === ''" />
          <div v-else class="error-msg mt-1 text-left">
            {{ warningUserIdNull }}
          </div>
        </div>
        <div class="w-[312px] mb-12 flex flex-col justify-start">
          <label class="text-left">{{ textDisplay.inputRoleTitle }}<sup>*</sup></label>
          <MenuDropdownComponent
            :data="textDisplay.selectoptionAutority"
            v-model="form.authority"
            :selected="form.authority"
            :text-position="'left'"
            @change="authorityChange()"
            bgColor="#fff"
            :isBorder="true"
          />
          <br v-if="warningAuthorityNull === ''" />
          <div v-else class="error-msg mt-1 text-left">
            {{ warningAuthorityNull }}
          </div>
        </div>
        <div class="w-[258px]">
          <MenuButtonComponent
          :title="textDisplay.btnResetPassword"
          class="h-11 w-full mb-5"
          type="button"
          @click="resetPassword()"
        />
        </div>
      </div>
      <div class="flex justify-center items-center">
        <MenuButtonComponent
          :title="textDisplay.buttonBackCreateUser"
          :btn-style="'btn_bordered'"
          type="button"
          class="h-11 mr-5"
          @click="back()"
        />
        <MenuButtonComponent
          :title="textDisplay.buttonCreateUser"
          class="h-11"
          type="submit"
        />
      </div>
    </form>
  </div>
  <div class="z-20 absolute left-1/2 top-1/3" v-if="isLoading">
      <LoadingComponent />
    </div>
</template>

<script>
import MenuDropdownComponent from '../../common/menu/MenuDropdownComponent'
import MenuButtonComponent from '../../common/menu/MenuButtonComponent.vue'
import MenuInputComponent from '../../common/menu/MenuInputComponent'
import LoadingComponent from '../../common/LoadingComponent.vue'
import { textDisplay } from '../../../common/TextDisplay'
import { useStore } from 'vuex'
import { NotificationMessage } from '../../../common/NotificationMessage'
import { ACTION_TYPE } from '../../../common/constants'
import { ConfirmBox, PasswordBox } from '../../../common/ConfirmBox'

/**
 * @module EditUserComponent
 */

export default {
    components: {
        MenuInputComponent,
        MenuDropdownComponent,
        MenuButtonComponent,
        LoadingComponent
    },
    data() {
        return {
            textDisplay: textDisplay,
            store: useStore(),
            form: {
                username: '',
                password: '',
                authority: textDisplay.selectoptionAutority[0],
                user_id: ''
            },
            isLoading: false,
            warningUsernameNull: '',
            warningUserIdNull: '',
            warningPasswordNull: '',
            warningAuthorityNull: ''
        }
    },
    beforeUnmount() {
        this.form = {
            username: '',
            password: '',
            authority: textDisplay.selectoptionAutority[0],
            user_id: ''
        }
    },
    beforeMount() {
        this.form = this.store.state.userList.items.find(item => {
            if (item.user_id === this.$route.params.userId) {
                return item
            }
        })
        this.form.authority = this.textDisplay.selectoptionAutority.find(item => {
            if (item.value === this.form.authority) return item
        })
    },
    methods: {

        /**
         * @async
         * 現在変更しているユーザーの新しいパスワードを生成する
         */
        async resetPassword() {
            const value = await PasswordBox({
                title: `${this.$route.params.userId}のパスワードをリセットしてよろしいですか？`,
                cancelBtnText: textDisplay.btnCancelResetPassword,
                confirmBtnText: textDisplay.btnSubmitResetPassword,
                showCancelBtn: true,
                allowOutsideClick: true
            })

            if (value && value.isConfirmed) {
                this.form.password = this.generatePassword(5, 3, 2)
                const newPassword = await PasswordBox({
                    title: `${this.$route.params.userId}のパスワードは${this.form.password}です。`,
                    cancelBtnText: null,
                    confirmBtnText: textDisplay.btnCopyPassword,
                    showCancelBtn: false,
                    allowOutsideClick: false
                })
                if (newPassword && newPassword.isConfirmed) {
                    await navigator.clipboard.writeText(`${this.$route.params.userId}のパスワードは${this.form.password}です。`)
                    NotificationMessage(
                        textDisplay.copyPasswordSuccess,
                        10000,
                        ACTION_TYPE.success
                    )
                }
            }
        },

        /**
         * ユーザー情報を変更するためにvuexアクションを呼び出す
         */
        editUser() {
            this.isLoading = true
            let isValidInput = true
            if (this.form.username === '') {
                isValidInput = false
                this.warningUsernameNull = this.textDisplay.warningUsernameNull
            }
            if (this.form.user_id === '') {
                isValidInput = false
                this.warningUserIdNull = this.textDisplay.warningUserIdNull
            }
            if (this.form.password === '') {
                isValidInput = false
                this.warningPasswordNull = this.textDisplay.warningPasswordNull
            }
            if (!this.form.authority) {
                isValidInput = false
                this.warningAuthorityNull = this.textDisplay.warningAuthorityNull
            }
            if (isValidInput) {
                this.store
                    .dispatch('editUser', {
                        username: this.form.username.trim(),
                        password: this.form.password,
                        authority: `${this.form.authority.value}`,
                        user_id: this.form.user_id
                    })
                    .then((result) => {
                        NotificationMessage(
                            this.textDisplay.msg_016,
                            10000,
                            ACTION_TYPE.success
                        )
                        if (this.form.user_id === this.store.state.user.user_id) {
                            this.store.state.user.username = this.form.username
                            this.store.state.user.authority = this.form.authority.value
                            const localStorageUser = JSON.parse(localStorage.getItem('user'))
                            localStorageUser.username = this.form.username
                            localStorageUser.authority = this.form.authority.value
                            localStorage.setItem('user', JSON.stringify(localStorageUser))
                            if (this.form.authority.value === 0) {
                                this.$router.push('/')
                                return
                            }
                        }
                        this.$router.push('/users/list')
                    })
                    .catch((e) => {
                        NotificationMessage(e.response.data.message, 3000, ACTION_TYPE.error)
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            } else {
                this.isLoading = false
            }
        },

        /**
         * ユーザー名の変更をキャッチする
         */
        usernameChange() {
            if (this.form.username !== '') {
                this.warningUsernameNull = ''
            } else {
                this.warningUsernameNull = this.textDisplay.warningUsernameNull
            }
        },

        /**
         * user_idの変更をキャッチする
         */
        userIdChange() {
            if (this.form.user_id !== '') {
                this.warningUserIdNull = ''
            } else {
                this.warningUserIdNull = this.textDisplay.warningUserIdNull
            }
        },

        /**
         * passwordの変更をキャッチする
         */
        passwordChange() {
            if (this.form.password !== '') {
                this.warningPasswordNull = ''
            } else {
                this.warningPasswordNull = this.textDisplay.warningPasswordNull
            }
        },

        /**
         * authorityの変更をキャッチする
         */
        authorityChange() {
            if (this.form.authority !== '') {
                this.warningAuthorityNull = ''
            } else {
                this.warningAuthorityNull = this.textDisplay.warningAuthorityNull
            }
        },

        /**
         * @async
         * 前のパスに戻る (通常はユーザー一覧画面です。)
         */
        async back() {
            const isConfirmed = await ConfirmBox(this.textDisplay.msg_012)
            if (isConfirmed.isConfirmed) {
                this.$router.go(-1)
            }
        },

        /**
         * パスワードの自動生成
         * @param {number} letters - 数字を含まない文字数 (a-z、A-Z)
         * @param {number} numbers - 数字(0-9)の文字数
         * @param {number} either - 英数字（0-9、a-z、A-Z）の数
         * @return {string} パスワード -パスワードが生成される
         * */
        generatePassword(letters, numbers, either) {
            const chars = [
                'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', // letters
                '0123456789', // numbers
                'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789' // either
            ]

            return [letters, numbers, either]
                .map(function (len, i) {
                    return Array(len)
                        .fill(chars[i])
                        .map(function (x) {
                            return x[Math.floor(Math.random() * x.length)]
                        })
                        .join('')
                })
                .concat()
                .join('')
                .split('')
                .sort(function () {
                    return 0.5 - Math.random()
                })
                .join('')
        }
    }
}
</script>

<style scoped>
sup,
.error-msg {
  color: #ff4e43;
}
sup {
  font-size: 16px;
}
label {
  color: #1f2533;
  font-weight: 500;
  margin-bottom: 5px;
}
</style>
