
const STATUS_CODES = {
    ok: 200,
    created: 201,
    no_content: 204,
    not_found: 404,
    un_authorization: 401,
    permission_denied: 403,
    server_error: 500,
    bad_request: 400,
    method_not_allowed: 405,
    request_entity_too_large: 413,
    bad_gateway: 502,
    service_unavailable: 503,
    gateway_timeout: 504
}

const ACTION_TYPE = {
    success: 'success',
    warning: 'warning',
    error: 'error',
    info: 'info',
    question: 'question'
}

export {
    STATUS_CODES,
    ACTION_TYPE
}
