<template>
    <div class="relative flex flex-row items-center input-container">
        <input
            v-bind:type="type === 'password' && show ? 'text' : type"
            v-bind:placeholder="title"
            class="p-[7px] text-left flex-1"
            :class="[border, inputStyle === 'input_bordered' ? 'input-bordered' : 'custom_menu_input']"
            @input="$emit('update:modelValue', $event.target.value)"
            @keyup.ctrl="$emit('input-key-up', $event)"
            @keypress="$emit('input-key-press', $event)"
            :required="isRequired"
            :maxlength="maxLength"
            :value="value"
            :style="{maxHeight: maxHeight + 'px'}"
            :disabled="isDisabled"
        />
        <v-icon classNames="mx-3 z-20 my-1 cursor-pointer"
                v-if="type === 'password' && !show"
                @click="toggle()"
                icon="icon-eye-closed"></v-icon>
        <v-icon classNames="mx-3 z-20 my-1 cursor-pointer"
                v-if="type === 'password' && show"
                @click="toggle()"
                icon="icon-eye-opened"></v-icon>
    </div>
</template>

<script>

import SVGIconComponent from '../SVGIconComponent'

/**
 * @module MenuInputComponent
 */
export default {
    props: {
        title: { type: String, require: false },
        type: { type: String, require: true },
        value: { type: String, require: false, default: '' },
        color: { type: String, default: '', require: false },
        isRequired: { type: Boolean, default: false, require: false },
        maxLength: { type: Number, require: false },
        isDisabled: { type: Boolean, require: false, default: false },
        inputStyle: { type: String, require: false },
        maxHeight: { type: Number, require: false }
    },
    data() {
        return {
            show: false,
            border: this.$props.color ? `border-${this.$props.color}-500` : ''
        }
    },
    methods: {
        /**
         * ドロップダウンの項目を選択する
         */
        toggle() {
            this.show = !this.show
        }
    },
    components: {
        vIcon: SVGIconComponent
    }
}
</script>

<style scoped>
    .custom_menu_input{
        border: none;
        outline: none;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        height: 40px;
        background-color:  inherit;
    }
    .input-container:focus-within{
        border: 1px solid #0D82E3;
        box-shadow: 0px 4px 7px 0px #DAE7FF;
    }
    .custom_menu_input::placeholder {
        color: #CDCDCD;
    }
    input[disabled]{
        background-color: #E8F0FF;
        border: none;
    }
    .input-container {
        background-color: #E8F0FF;
        border-radius: 10px;
        overflow: hidden;
    }

    .input-container svg{
        width: 25px;
        height: 25px;
    }
    input {
        outline: none;
    }
    .custom_auth_input {
        border: none;
        outline: none;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        height: 45px;
        background-color: inherit;
    }

    .custom_auth_input::placeholder {
        color: #CDCDCD;
    }
    .input-bordered {
        border: 1px solid #CBDDFF;
        background-color: #fff;
        border-radius: 10px;
    }
    .input-bordered::placeholder {
        color: #CDCDCD;
    }
</style>
