<template>
    <button
        v-bind:type="type"
        class=" text-center
            border-solid
            p-3
            align-middle
            custom_auth_button
            font-bold
        "
        @click="$emit('click-auth-button')"
    >
        <slot/>
        {{ title }}
    </button>
</template>

<script>
export default {
    props: {
        title: { type: String, required: true },
        type: { type: String, required: true }
    }
}
</script>

<style>
.custom_auth_button {
    font-style: normal;
    font-weight: normal;
    box-sizing: border-box;
    border-radius: 10px;
    letter-spacing: -0.02em;
    background-color:#015DB2;
    color: #FBFBFB;
    min-width: 145px;
}
</style>
