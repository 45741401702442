<template>
    <div class="w-full h-screen flex items-center bg-gray-400 login-container">
        <router-view/>

    </div>
</template>

<script>
export default {}
</script>

<style scoped>
    .login-container {
        background-image: url("../assets/bg_login.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .login-inner {
        border-radius: 50px;
        width: 655px;
    }
</style>
