import axios from 'axios'
import { STATUS_CODES } from '../common/constants'

/**
 * @module Store.Actions
 */
export default {
    getDataTableTest: async ({ state, commit }) => {
        await axios.get('https://api.openbrewerydb.org/breweries')
            .then((res) => {
                if (res.status === STATUS_CODES.ok) {
                    commit('updateDataTable', res.data)
                }
            })
    },
    /**
     * @method login
     * @description ログイン
     * @param {Object} payload
     * @param {string} payload.user_id
     * @param {string} payload.password
     * @return {boolean} ログインに成功した場合と失敗した場合
    */
    login: async ({ state, commit }, payload) => {
        return await axios
            .post(`${process.env.VUE_APP_BE_BASE_URL}/login`, payload)
            .then((res) => {
                const expiredAt = new Date()
                expiredAt.setSeconds(expiredAt.getSeconds() + res.data.expires_in)
                commit('setUser', {
                    username: res.data.username,
                    access_token: res.data.token,
                    expired_at: expiredAt,
                    authority: res.data.authority,
                    user_id: res.data.user_id
                })
                localStorage.setItem('user', JSON.stringify({
                    username: res.data.username,
                    access_token: res.data.token,
                    expired_at: expiredAt,
                    authority: res.data.authority,
                    user_id: res.data.user_id
                }))
                return true
            })
            .catch((e) => {
                return false
            })
    },
    /**
     * @method getUserFromLocalStorage
     * @description ユーザ情報(User_id, username, access_token, expired_at, authority)を取得します。
     *
    */
    getUserFromLocalStorage: ({ state, commit }) => {
        const user = JSON.parse(localStorage.getItem('user'))
        commit('setUser', user)
    },
    /**
     * @method sessionExpired
     * @description トークンの有効期限が切れている場合、access_tokenを含む現在のユーザー情報をLocalStorageから削除する。
     */
    sessionExpired: ({ state, commit }) => {
        localStorage.removeItem('user')
        commit('setUser', {
            access_token: '',
            username: '',
            expired_at: '',
            user_id: '',
            authority: ''
        })
    },
    /**
     * @method logout
     * @description 状態をクリアし、LocalStorageからaccess_tokenを含む現在のユーザ情報を削除します。
     */
    logout: ({ state, commit }) => {
        localStorage.removeItem('user')
        commit('setUser', {
            access_token: '',
            username: '',
            expired_at: '',
            user_id: '',
            authority: ''
        })
        commit('clearListUser')
        commit('clearGlobalState')
    },

    /**
     * @method getListUser
     * @description アプリでユーザー一覧を表示するためのAPIを呼び出す
     * @param {Object} payload
     * @param {number} payload.page - ページ番号
     * @param {number} payload.per_page - ページ内に表示されるユーザー数
     * @return {boolean} ユーザリストの取得に成功または失敗した場合
     * */
    getListUser: async ({ state, commit }, payload) => {
        return await axios.get(`${process.env.VUE_APP_BE_BASE_URL}/users`, {
            params: payload
        })
            .then(result => {
                commit('setListUser', result.data.users)
                return true
            })
            .catch(e => {
                return false
            })
    },

    /**
     * @method createUser
     * @description 新規ユーザーを作成するためのAPIを呼び出す。
     * @param {Object} payload
     * @param {string} payload.username
     * @param {number} payload.User_id
     * @param {string} payload.password
     * @param {string} payload.authority
     * @return {boolean | promise} 新しいユーザの作成が成功したかどうか
     */
    createUser: async ({ state, commit }, payload) => {
        return await axios.post(`${process.env.VUE_APP_BE_BASE_URL}/users`, payload)
            .then(result => {
                commit('pushUser', result.data)
                return true
            })
            .catch(e => {
                return Promise.reject(e)
            })
    },

    /**
     * @method deleteUser
     * @description ユーザーを削除するためのAPIを呼び出す
     * @param {Object} payload
     * @param {string | number} payload.id - User_id
     * @return {boolean | promise} ユーザの削除が成功したかどうか
     * */
    deleteUser: async ({ state, commit }, payload) => {
        return await axios.delete(`${process.env.VUE_APP_BE_BASE_URL}/users`, {
            data: {
                user_ids: [payload]
            }
        })
            .then(result => {
                commit('deleteUser', payload)
                return true
            })
            .catch(e => {
                return Promise.reject(e)
            })
    },

    /**
     * @method editUser
     * @description ユーザーを編集するためのAPIを呼び出す
     * @param {Object} payload
     * @param {string} payload.username
     * @param {string} payload.password
     * @param {string} payload.authority
     * @return {boolean | promise} ユーザの編集が成功したかどうか
     * */
    editUser: async ({ state, commit }, payload) => {
        return await axios.patch(`${process.env.VUE_APP_BE_BASE_URL}/users/${payload.user_id}`,
            {
                authority: payload.authority,
                username: payload.username,
                password: payload.password
            }
        )
            .then(result => {
                commit('updateUser', { user_id: payload.user_id, authority: payload.authority })
                return true
            })
            .catch(e => {
                return Promise.reject(e)
            })
    },

    /**
     * @method createSummary
     * @description apiを呼び出して新しいサマリーを作成する
     * @param {Object} payload
     * @param {string} payload.drug_name -医薬品名
     * @param {string} payload.drug_name - 医薬品名カナ
     * @param {string} payload.general - 一般情報
     * @param {string} payload.effect_for_anomaly - 先天異常への影響
     * @param {string} payload.effect_for_baby - 先天異常以外の胎児への影響
     * @param {string} payload.other - その他
     * @param {string} payload.placenta_passage - 胎盤通過性
     * @param {string} payload.animal_test - 動物試験
     * @param {string} payload.attached - 添付文書記載内容
     * @param {string} payload.summarize - まとめ
     * @param {string} payload.pharmacological_name - 薬理名
     * @param {string} payload.major_drug_name - 主要商品名
     * @param {string} payload.classification - 分類
     * @param {Array} payload.humanResearchData - 人間での疫学研究
     * @param {Array} payload.humanReportedData - 人間での症例報告
     * @param {Array} payload.animalResearchResultData - 動物実験結果
     * @param {Array} payload.interviewData - インタビューフォーム
     * @param {Array} payload.packageInsertData - 添付文書
     * @param {Array} payload.otherMaterialsData - その他資料
     * @param {Array} payload.motherRiskStatementData - マザーリスクステートメント
     * @return {Object | promise} 新しいサマリーの作成に成功した場合 => apiのレスポンスを返し、そうでない場合はエラーレスポンスを含むプロミスを返す。
     * */
    createSummary: async ({ commit }, payload) => {
        return await axios.post(`${process.env.VUE_APP_BE_BASE_URL}/summaries`, payload)
            .then(result => {
                return result
            })
            .catch(e => {
                return Promise.reject(e)
            })
    },

    /**
     * @method listSummaries
     * @description 検索キーワードでサマリーを一覧表示するAPIを呼び出す
     * @param payload
     * @param {string} payload.drug_name - 医薬品名
     * @param {string} payload.pharmacological_name - 薬理名
     * @param {string} payload.major_drug_name - 主要商品名
     * @param {string} payload.classification - 分類
     * @param {string} payload.sort_by - 並び順
     * @param {string} payload.order_by
     * @param {number} payload.status - ステータス
     * @param {number} payload.page - ページ番号
     * @param {number} payload.per_page - ページ内に表示されるユーザー数
     * @return {boolean | promise}サマリー一覧が正常に終了したかどうか
     * */
    listSummaries: async ({ commit }, payload) => {
        return await axios.get(`${process.env.VUE_APP_BE_BASE_URL}/summaries`, {
            params: payload
        }).then(result => {
            commit('setSummaries', result.data.summaries)
            return true
        }).catch(e => {
            return Promise.reject(e)
        })
    },

    /**
     * @method getSummary
     * @description サマリーIDで単一のサマリーを取得するAPIを呼び出す
     * @param payload
     * @param {string | number} payload.summary_id
     * @return {response_data | promise} apiのレスポンスを返す取得に成功した場合、または失敗した場合にエラープロミスを返します。
     * */
    getSummary: async ({ state }, payload) => {
        return await axios.get(`${process.env.VUE_APP_BE_BASE_URL}/summaries/${payload}`)
            .then(result => {
                return result.data
            }).catch(e => {
                return Promise.reject(e)
            })
    },

    /**
     * @method editSummary
     * @description サマリー_idでサマリーを編集するためのapiを呼び出す。
     * @param payload
     * @param {number} payload.id - summary_id
     * @param {string} payload.drug_name -医薬品名
     * @param {string} payload.drug_name - 医薬品名カナ
     * @param {string} payload.general - 一般情報
     * @param {string} payload.effect_for_anomaly - 先天異常への影響
     * @param {string} payload.effect_for_baby - 先天異常以外の胎児への影響
     * @param {string} payload.other - その他
     * @param {string} payload.placenta_passage - 胎盤通過性
     * @param {string} payload.animal_test - 動物試験
     * @param {string} payload.attached - 添付文書記載内容
     * @param {string} payload.summarize - まとめ
     * @param {string} payload.pharmacological_name - 薬理名
     * @param {string} payload.major_drug_name - 主要商品名
     * @param {string} payload.classification - 分類
     * @param {Array} payload.humanResearchData - 人間での疫学研究
     * @param {Array} payload.humanReportedData - 人間での症例報告
     * @param {Array} payload.animalResearchResultData - 動物実験結果
     * @param {Array} payload.interviewData - インタビューフォーム
     * @param {Array} payload.packageInsertData - 添付文書
     * @param {Array} payload.otherMaterialsData - その他資料
     * @param {Array} payload.motherRiskStatementData - マザーリスクステートメント
     * @return {Object | promise} if edit summary successfully => apiのレスポンスを返し、それ以外の場合はエラーレスポンスを含むプロミスを返す。
     * */
    editSummary: async ({ state, commit }, payload) => {
        return await axios.patch(`${process.env.VUE_APP_BE_BASE_URL}/summaries/${payload.id}`, payload.data)
            .then(result => {
                commit('editSummary')
                return result
            }).catch(e => {
                return Promise.reject(e)
            })
    },

    /**
     * @method confirmSummary
     * @description サマリーを確認するためにapiを呼び出す
     * @param payload
     * @param {number} payload.summary_id
     * @return {object | promise} apiの呼び出しに失敗した場合、エラーメッセージを含むプロミスを返します。
     * */
    confirmSummary: async ({ state, commit }, payload) => {
        return await axios.post(`${process.env.VUE_APP_BE_BASE_URL}/summaries/${payload}/approve`)
            .then(result => {
                return result
            }).catch(e => {
                return Promise.reject(e)
            })
    }
}
