<template>
    <div class="text-left my-[20px]">
        <label class="text-left font-bold">{{ textDisplay.labelDrugName }}<sup>*</sup></label>
        <MenuInputComponent
            :isDisabled="$route.name === 'ViewSummary' || $route.name === 'EditSummary' ? true : isView"
            class="ml-8"
            :maxLength="256"
            type="text"
            @input="$emit('input-drug-name', $event)"
            :value="drug_name"
            :input-style="'input_bordered'"
            max-height="40"
        />
        <p v-if="drug_name_error_text" class="text-red-500 text-left ml-8">
            {{ drug_name_error_text }}
        </p>
    </div>
    <div class="text-left my-[20px]">
        <label class="text-left font-bold">{{ textDisplay.labelKana }}<sup>*</sup></label>
        <MenuInputComponent
            :isDisabled="$route.name === 'ViewSummary' || $route.name === 'EditSummary' ? true : isView"
            class="ml-8"
            type="text"
            :maxLength="256"
            @input="$emit('input-drug-kana', $event)"
            :value="drug_kana"
            :input-style="'input_bordered'"
            max-height="40"
        />
        <p v-if="drug_name_kana_error_text" class="text-red-500 text-left ml-8">
            {{ drug_name_kana_error_text }}
        </p>
    </div>
    <div class="text-left mb-[20px]">
        <label class="font-bold">{{ textDisplay.labelGeneralInfo }}</label>
        <div class="pl-8">
            <MenuTextAreaComponent
                :isDisabled="isView"
                @input="$emit('input-general', $event)"
                :cacheValue="general"
            />
        </div>
    </div>
    <div class="text-left mb-[20px]">
        <label class="font-bold">{{ textDisplay.labelHuman }}</label>
        <div class="pl-8 my-2 w-full">
            <div class="text-left">
                <label class="font-normal">{{ textDisplay.labelEffectForAnomaly }}</label>
                <div class="pl-8">
                    <MenuTextAreaComponent
                        :isDisabled="isView"
                        class="h-36"
                        @input="$emit('input-effect-for-anomaly', $event)"
                        :cacheValue="effect_for_anomaly"
                    />
                </div>
            </div>
            <div class="text-left mt-[20px]">
                <label class="font-normal">{{ textDisplay.labelEffectForBaby }}</label>
                <div class="pl-8">
                    <MenuTextAreaComponent
                        :isDisabled="isView"
                        class="h-36"
                        @input="$emit('input-effect-for-baby', $event)"
                        :cacheValue="effect_for_baby"
                    />
                </div>
            </div>
        </div>
    </div>
    <div class="text-left mb-[20px]">
        <label class="font-bold">{{ textDisplay.labelOther }}</label>
        <div class="pl-8">
            <MenuTextAreaComponent :isDisabled="isView" @input="$emit('input-other', $event)"
                               :cacheValue="other"/>
        </div>
    </div>
    <div class="text-left mb-[20px]">
        <label class="font-bold">{{ textDisplay.labelPlacentaPassage }}</label>
        <div class="pl-8">
            <MenuTextAreaComponent
                :isDisabled="isView"
                @input="$emit('input-placenta-passage', $event)"
                :cacheValue="placenta_passage"
            />
        </div>
    </div>
    <div class="text-left mb-[20px]">
        <label class="font-bold">{{ textDisplay.labelAnimalTest }}</label>
        <div class="pl-8">
            <MenuTextAreaComponent
                :isDisabled="isView"
                @input="$emit('input-animal-test', $event)"
                :cacheValue="animal_test"
            />
        </div>
    </div>
    <div class="text-left mb-[20px]">
        <label class="font-bold">{{ textDisplay.labelAttachment }}</label>
        <div class="pl-8">
            <MenuTextAreaComponent
                :isDisabled="isView"
                @input="$emit('input-attached', $event)"
                :cacheValue="attached"
            />
        </div>
    </div>
    <div class="text-left mb-[20px]">
        <label class="font-bold">{{ textDisplay.labelSummarize }}</label>
        <div class="pl-8">
            <MenuTextAreaComponent
                :isDisabled="isView"
                @input="$emit('input-summarize', $event)"
                :cacheValue="summarize"
            />
        </div>
    </div>
</template>

<script>
import MenuInputComponent from '../../../common/menu/MenuInputComponent.vue'
import MenuTextAreaComponent from '../../../common/menu/MenuTextAreaComponent.vue'
import { textDisplay } from '../../../../common/TextDisplay'

export default {
    components: { MenuInputComponent, MenuTextAreaComponent },
    props: {
        isView: { type: Boolean, default: false, require: false },
        drug_name: { type: String, default: '' },
        drug_kana: { type: String, default: '' },
        general: { type: String, default: '' },
        effect_for_anomaly: { type: String, default: '' },
        effect_for_baby: { type: String, default: '' },
        other: { type: String, default: '' },
        placenta_passage: { type: String, default: '' },
        animal_test: { type: String, default: '' },
        attached: { type: String, default: '' },
        summarize: { type: String, default: '' },
        drug_name_error_text: { type: String, default: '', require: '' },
        drug_name_kana_error_text: { type: String, default: '', require: '' }
    },
    data() {
        return {
            textDisplay
        }
    }
}
</script>

<style scoped>
    sup,
    .error-msg {
    color: #ff4e43;
    }
    sup {
    font-size: 16px;
    }
    label {
        color: #1F2533;
        font-size: 16px;
        margin-bottom: 10px;
        display: block;
    }
</style>
