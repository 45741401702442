<template>
  <div class="flex-1 page-container">
    <div class="w-full bg-white rounded-2xl flex-wrap relative">
      <div
        class="
          flex
          w-full
          header-create-summary
          border-bottom
          pt-5
          pl-5
          pr-10
          pb-5
          flex-wrap
        "
      >
        <div class="w-full flex">
          <div class="w-1/2 text-left">
            {{ textDisplay.labelStatus }}: {{ status.title }}
          </div>
          <div class="flex w-1/2">
            <div class="flex w-1/2">
              {{ textDisplay.labelUpdatedBy }}:
              {{
                store.state.formUpdateSummary.update_username
                  ? store.state.formUpdateSummary.update_username
                  : ""
              }}
            </div>
            <div class="flex w-1/2">
              {{ textDisplay.labelUpdatedAt }}:
              {{
                store.state.formUpdateSummary.update_date
                  ? formattedUpdateDate
                  : ""
              }}
            </div>
          </div>
        </div>
        <div class="w-full flex">
          <div class="w-1/2 text-left"></div>
          <div class="flex w-1/2">
            <div class="flex w-1/2">
              {{ textDisplay.labelApprovedBy }}:
              {{
                store.state.formUpdateSummary.approve_username
                ? store.state.formUpdateSummary.approve_username
                : ""
              }}
            </div>
            <div class="flex w-1/2">
              {{ textDisplay.labelApprovedAt }}:
              {{
                store.state.formUpdateSummary.approve_date
                ? formattedApproveDate
                : ""
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="w-full pt-5 tab-summary px-5 h-[71px]">
        <div class="flex">
          <div
            class="cursor-pointer mr-1 tab-item"
            :class="isPublic ? 'active' : ''"
            @click="isPublic = true"
          >
            <span>{{ textDisplay.labelPublic }}</span>
          </div>
          <div
            class="cursor-pointer tab-item"
            :class="!isPublic ? 'active' : ''"
            @click="isPublic = false"
          >
            <span>{{ textDisplay.labelAllInfo }}</span>
          </div>
        </div>
      </div>
      <form
        class="w-full pt-5 pl-5 pr-10 pb-[50px]"
        @submit.prevent="editSummary()"
      >
        <PublicSummaryComponent
          v-if="isPublic"
          class="ml-3"
          :isView="isView"
          :general="store.state.formUpdateSummary.general"
          :drug_name="store.state.formUpdateSummary.drug_name"
          :drug_kana="store.state.formUpdateSummary.drug_kana"
          :effect_for_anomaly="store.state.formUpdateSummary.effect_for_anomaly"
          :effect_for_baby="store.state.formUpdateSummary.effect_for_baby"
          :other="store.state.formUpdateSummary.other"
          :animal_test="store.state.formUpdateSummary.animal_test"
          :attached="store.state.formUpdateSummary.attached"
          :placenta_passage="store.state.formUpdateSummary.placenta_passage"
          :summarize="store.state.formUpdateSummary.summarize"
          @input-general="
            store.state.formUpdateSummary.general = $event.target.value.trim()
          "
          @input-effect-for-anomaly="
            store.state.formUpdateSummary.effect_for_anomaly =
              $event.target.value.trim()
          "
          @input-effect-for-baby="
            store.state.formUpdateSummary.effect_for_baby = $event.target.value.trim()
          "
          @input-other="
            store.state.formUpdateSummary.other = $event.target.value.trim()
          "
          @input-placenta-passage="
            store.state.formUpdateSummary.placenta_passage = $event.target.value.trim()
          "
          @input-animal-test="
            store.state.formUpdateSummary.animal_test = $event.target.value.trim()
          "
          @input-attached="
            store.state.formUpdateSummary.attached = $event.target.value.trim()
          "
          @input-summarize="
            store.state.formUpdateSummary.summarize = $event.target.value.trim()
          "
        />
        <AllInfoSummaryComponent
          v-else
          ref="allInfoComponent"
          class="ml-3 mb-[20px]"
          :isView="isView"
          :pharmacological_name="
            store.state.formUpdateSummary.pharmacological_name
          "
          :classification="store.state.formUpdateSummary.classification"
          :major_drug_name="store.state.formUpdateSummary.major_drug_name"
          :animalResearchResultData="
            store.state.formUpdateSummary.animalResearchResultData
          "
          :humanReportedData="store.state.formUpdateSummary.humanReportedData"
          :humanResearchData="store.state.formUpdateSummary.humanResearchData"
          :interviewData="store.state.formUpdateSummary.interviewData"
          :packageInsertData="store.state.formUpdateSummary.packageInsertData"
          :otherMaterialsData="store.state.formUpdateSummary.otherMaterialsData"
          :motherRiskStatementData="
            store.state.formUpdateSummary.motherRiskStatementData
          "
          @input-pharmacological-name="
            store.state.formUpdateSummary.pharmacological_name =
              $event.target.value.trim()
          "
          @input-major-drug-name="
            store.state.formUpdateSummary.major_drug_name = $event.target.value.trim()
          "
          @input-classification="
            store.state.formUpdateSummary.classification = $event.target.value.trim()
          "
          @add-humanResearchData="addHumanResearchDataRow"
          @delete-humanResearchData="deleteHumanResearchData($event)"
          @add-humanReportedData="addHumanReportedDataRow"
          @delete-humanReportedData="deleteHumanReportedData($event)"
          @add-animalResearchResultData="addAnimalResearchResultDataRow"
          @delete-animalResearchResultData="
            deleteAnimalResearchResultData($event)
          "
          @add-interviewData="addInterviewDataRow"
          @delete-interviewData="deleteInterviewData($event)"
          @add-packageInsertData="addPackageInsertDataRow"
          @delete-packageInsertData="deletePackageInsertData($event)"
          @add-otherMaterialsData="addOtherMaterialsDataRow"
          @delete-otherMaterialsData="deleteOtherMaterialsData($event)"
          @add-motherRiskStatementData="addMotherRiskStatementRow"
          @delete-motherRiskStatementData="
            deleteMotherRiskStatementData($event)
          "
          @click-humanResearchData-row="clickHumanResearchDataRow($event)"
          @click-humanReportedData-row="clickHumanReportedDataRow($event)"
          @click-animalResearchResultData-row="
            clickAnimalResearchResultDataRow($event)
          "
          @click-interviewData-row="clickInterviewDataRow($event)"
          @click-packageInsertData-row="clickPackageInsertDataRow($event)"
          @click-otherMaterialsData-row="clickOtherMaterialsDataRow($event)"
          @click-motherRiskStatementData-row="
            clickMotherRiskStatementRow($event)
          "
        />
        <div class="flex justify-center pt-[20px]">
          <MenuButtonComponent
            class="border-2 mr-4 h-11"
            type="button"
            @click="saveToDraft"
            :btn-style="'btn_bordered'"
            :title="textDisplay.buttonEditSaveToDraft"
          />
          <MenuButtonComponent
            class="border-2 mr-4 h-11"
            type="submit"
            :title="textDisplay.buttonViewSummarySave"
          />
        </div>
      </form>
    </div>
  </div>
  <div class="z-20 absolute top-1/3 left-1/2" v-if="store.state.isLoading">
    <LoadingComponent />
  </div>
  <HumanResearchModalComponent
    :year_country="tableForm.year_country"
    :author="tableForm.author"
    :publication_magazine="tableForm.publication_magazine"
    :title="tableForm.title"
    :design="tableForm.design"
    :exposure_n_control_n="tableForm.exposure_n_control_n"
    :outcome="tableForm.outcome"
    :result="tableForm.result"
    :recruitment="tableForm.recruitment"
    :reason="tableForm.reason"
    :brtm="tableForm.brtm"
    :memo="tableForm.memo"
    :endnote_link="tableForm.endnote_link"
    @input-year_country-modal="tableForm.year_country = $event"
    @input-author-modal="tableForm.author = $event"
    @input-publication_magazine-modal="tableForm.publication_magazine = $event"
    @input-title-modal="tableForm.title = $event"
    @input-design-modal="tableForm.design = $event"
    @input-exposure_n_control_n-modal="tableForm.exposure_n_control_n = $event"
    @input-outcome-modal="tableForm.outcome = $event"
    @input-result-modal="tableForm.result = $event"
    @input-recruitment-modal="tableForm.recruitment = $event"
    @input-reason-modal="tableForm.reason = $event"
    @input-brtm-modal="tableForm.brtm = $event"
    @input-memo-modal="tableForm.memo = $event"
    @input-endnote_link-modal="tableForm.endnote_link = $event"
    v-if="isHumanResearchModalOpen"
    @closeModal="isHumanResearchModalOpen = false; this.isAddingRow = false"
    @saveModal="saveRow"
    @cancelModal="cancelModal"
  />
  <AnimalTestModalComponent
    :year_country="tableForm.year_country"
    :author="tableForm.author"
    :publication_magazine="tableForm.publication_magazine"
    :title="tableForm.title"
    :animal_species="tableForm.animal_species"
    :outcome="tableForm.outcome"
    :dose="tableForm.dose"
    :result="tableForm.result"
    :brtm="tableForm.brtm"
    :memo="tableForm.memo"
    :endnote_link="tableForm.endnote_link"
    @input-year_country-modal="tableForm.year_country = $event"
    @input-author-modal="tableForm.author = $event"
    @input-publication_magazine-modal="tableForm.publication_magazine = $event"
    @input-title-modal="tableForm.title = $event"
    @input-animal_species-modal="tableForm.animal_species = $event"
    @input-outcome-modal="tableForm.outcome = $event"
    @input-dose-modal="tableForm.dose = $event"
    @input-result-modal="tableForm.result = $event"
    @input-brtm-modal="tableForm.brtm = $event"
    @input-memo-modal="tableForm.memo = $event"
    @input-endnote_link-modal="tableForm.endnote_link = $event"
    v-if="isAnimalTestModalOpen"
    @closeModal="isAnimalTestModalOpen = false; this.isAddingRow = false"
    @saveModal="saveRow"
    @cancelModal="cancelModal"
  />
  <HumanReportedModalComponent
    :year_country="tableForm.year_country"
    :author="tableForm.author"
    :publication_magazine="tableForm.publication_magazine"
    :title="tableForm.title"
    :result="tableForm.result"
    :recruitment="tableForm.recruitment"
    :judgment_reason="tableForm.judgment_reason"
    :brtm="tableForm.brtm"
    :memo="tableForm.memo"
    :endnote_link="tableForm.endnote_link"
    @input-year_country-modal="tableForm.year_country = $event"
    @input-author-modal="tableForm.author = $event"
    @input-publication_magazine-modal="tableForm.publication_magazine = $event"
    @input-title-modal="tableForm.title = $event"
    @input-result-modal="tableForm.result = $event"
    @input-recruitment-modal="tableForm.recruitment = $event"
    @input-judgment_reason-modal="tableForm.judgment_reason = $event"
    @input-brtm-modal="tableForm.brtm = $event"
    @input-memo-modal="tableForm.memo = $event"
    @input-endnote_link-modal="tableForm.endnote_link = $event"
    v-if="isHumanReportedModalOpen"
    @closeModal="isHumanReportedModalOpen = false; this.isAddingRow = false"
    @saveModal="saveRow"
    @cancelModal="cancelModal"
  />
  <InterviewModalComponent
    :postscript_date="tableForm.postscript_date"
    :product_name="tableForm.product_name"
    :version="tableForm.version"
    :pdf_link="tableForm.pdf_link"
    @input-postscript_date-modal="tableForm.postscript_date = $event"
    @input-product_name-modal="tableForm.product_name = $event"
    @input-version-modal="tableForm.version = $event"
    @input-pdf_link-modal="tableForm.pdf_link = $event"
    v-if="isInterviewModalOpen"
    @closeModal="isInterviewModalOpen = false; this.isAddingRow = false"
    @saveModal="saveRow"
    @cancelModal="cancelModal"
  />
  <MotherRiskStatementModalComponent
    :postscript_date="tableForm.postscript_date"
    :year="tableForm.year"
    :destination_link="tableForm.destination_link"
    @input-postscript_date-modal="tableForm.postscript_date = $event"
    @input-year-modal="tableForm.year = $event"
    @input-destination_link-modal="tableForm.destination_link = $event"
    v-if="isMotherRiskStatemantModalOpen"
    @closeModal="isMotherRiskStatemantModalOpen = false; this.isAddingRow = false"
    @saveModal="saveRow"
    @cancelModal="cancelModal"
  />
  <PackageInsertModalComponent
    :postscript_date="tableForm.postscript_date"
    :reference_page="tableForm.reference_page"
    :version="tableForm.version"
    :pdf_link="tableForm.pdf_link"
    @input-postscript_date-modal="tableForm.postscript_date = $event"
    @input-reference_page-modal="tableForm.reference_page = $event"
    @input-version-modal="tableForm.version = $event"
    @input-pdf_link-modal="tableForm.pdf_link = $event"
    v-if="isPackageInsertModalOpen"
    @closeModal="isPackageInsertModalOpen = false; this.isAddingRow = false"
    @saveModal="saveRow"
    @cancelModal="cancelModal"
  />
  <OtherMaterialsModalComponent
    :postscript_date="tableForm.postscript_date"
    :document_content="tableForm.document_content"
    :destination_link="tableForm.destination_link"
    @input-postscript_date-modal="tableForm.postscript_date = $event"
    @input-document_content-modal="tableForm.document_content = $event"
    @input-destination_link-modal="tableForm.destination_link = $event"
    v-if="isOtherMaterialsModalOpen"
    @closeModal="isOtherMaterialsModalOpen = false; this.isAddingRow = false"
    @saveModal="saveRow"
    @cancelModal="cancelModal"
  />
</template>

<script>
import PublicSummaryComponent from './shared/PublicSummaryComponent.vue'
import AllInfoSummaryComponent from './shared/AllInfoSummaryComponent.vue'
import MenuButtonComponent from '../../common/menu/MenuButtonComponent.vue'
import HumanResearchModalComponent from './shared/HumanResearchModalComponent.vue'
import AnimalTestModalComponent from './shared/AnimalTestModalComponent.vue'
import HumanReportedModalComponent from './shared/HumanReportedModalComponent.vue'
import InterviewModalComponent from './shared/InterviewModalComponent.vue'
import MotherRiskStatementModalComponent from './shared/MotherRiskStatementModalComponent.vue'
import PackageInsertModalComponent from './shared/PackageInsertModalComponent.vue'
import OtherMaterialsModalComponent from './shared/OtherMaterialsModalComponent.vue'
import { textDisplay } from '../../../common/TextDisplay'
import LoadingComponent from '../../common/LoadingComponent.vue'
import { NotificationMessage } from '../../../common/NotificationMessage'
import { ACTION_TYPE } from '../../../common/constants'
import { ConfirmBox } from '../../../common/ConfirmBox'
import { useStore } from 'vuex'
import { formatDatetime, formatDate } from '../../../common/UsefulFunction'

/**
 * @module EditSummaryComponent
 */
export default {
    components: {
        PublicSummaryComponent,
        AllInfoSummaryComponent,
        MenuButtonComponent,
        LoadingComponent,
        HumanResearchModalComponent,
        AnimalTestModalComponent,
        HumanReportedModalComponent,
        InterviewModalComponent,
        MotherRiskStatementModalComponent,
        PackageInsertModalComponent,
        OtherMaterialsModalComponent
    },
    data() {
        return {
            textDisplay,
            formatDatetime,
            isPublic: true,
            isView: false,
            store: useStore(),
            tableForm: {
                type: '',
                index: '',
                references: '',
                reference_page: '',
                endnote_link: '',
                update_date: ''
            },
            isAddingRow: false,
            isModalOpened: false,
            isHumanResearchModalOpen: false,
            isHumanReportedModalOpen: false,
            isInterviewModalOpen: false,
            isPackageInsertModalOpen: false,
            isOtherMaterialsModalOpen: false,
            isMotherRiskStatemantModalOpen: false,
            isAnimalTestModalOpen: false
        }
    },
    watch: {
        'store.state.formUpdateSummary': {
            handler(v) {
                this.store.state.isSummaryBeingEdit = true
            },
            deep: true
        }
    },
    computed: {
        formattedUpdateDate: function () {
            return formatDatetime(
                new Date(this.store.state.formUpdateSummary.update_date)
            )
        },
        formattedApproveDate: function () {
            return formatDatetime(
                new Date(this.store.state.formUpdateSummary.approve_date)
            )
        },
        status: function () {
            return this.textDisplay.selectOptionSummaryInDBStatus.find((item) => {
                if (+item.value === +this.store.state.formUpdateSummary.status) {
                    return item
                }
            })
        }
    },
    async beforeMount() {
        if (+this.store.state.formUpdateSummary.summary_id !== +this.$route.params.index) {
            let tmp = {}
            let isFromCache = false
            if (
                this.store.getters.summaries.items &&
        this.store.getters.summaries.items.length > 0
            ) {
                for (let i = 0; i < this.store.getters.summaries.items.length; i++) {
                    if (
                        +this.store.getters.summaries.items[i].summary_id ===
            +this.$route.params.index
                    ) {
                        tmp = this.store.getters.summaries.items[i]
                        isFromCache = true
                        break
                    }
                }
            }
            if (!isFromCache) {
                tmp = await this.store
                    .dispatch('getSummary', this.$route.params.index)
                    .then((result) => {
                        return result
                    })
                    .catch((e) => {
                        NotificationMessage(
                            e.response.data.message,
                            3000,
                            ACTION_TYPE.error
                        )
                    })
            }

            // 「4.文献情報」「5.マザーリスクステートメント」の初期化
            this.store.state.formUpdateSummary.humanResearchData = []
            this.store.state.formUpdateSummary.humanReportedData = []
            this.store.state.formUpdateSummary.animalResearchResultData = []
            this.store.state.formUpdateSummary.interviewData = []
            this.store.state.formUpdateSummary.packageInsertData = []
            this.store.state.formUpdateSummary.otherMaterialsData = []
            this.store.state.formUpdateSummary.motherRiskStatementData = []

            this.store.state.formUpdateSummary.id = tmp.id
            this.store.state.formUpdateSummary.summary_id = tmp.summary_id
            this.store.state.formUpdateSummary.drug_kana = tmp.drug_kana
            this.store.state.formUpdateSummary.drug_name = tmp.drug_name
            this.store.state.formUpdateSummary.general = tmp.general
            this.store.state.formUpdateSummary.effect_for_anomaly =
        tmp.effect_for_anomaly
            this.store.state.formUpdateSummary.effect_for_baby = tmp.effect_for_baby
            this.store.state.formUpdateSummary.other = tmp.other
            this.store.state.formUpdateSummary.placenta_passage =
        tmp.placenta_passage
            this.store.state.formUpdateSummary.animal_test = tmp.animal_test
            this.store.state.formUpdateSummary.attached = tmp.attached
            this.store.state.formUpdateSummary.summarize = tmp.summarize
            this.store.state.formUpdateSummary.status = tmp.status
            this.store.state.formUpdateSummary.update_user = tmp.update_user
            this.store.state.formUpdateSummary.update_username = tmp.update_username
            this.store.state.formUpdateSummary.update_date = tmp.update_date
            this.store.state.formUpdateSummary.version = tmp.version
            this.store.state.formUpdateSummary.approve_username = tmp.approve_username
            this.store.state.formUpdateSummary.approve_date = tmp.approve_date
            // All Info
            this.store.state.formUpdateSummary.pharmacological_name = tmp.pharmacological_name
            this.store.state.formUpdateSummary.major_drug_name = tmp.major_drug_name
            this.store.state.formUpdateSummary.classification = tmp.classification
            tmp.literatures.forEach((value) => {
                if (value.length > 0) {
                    switch (value[0].item) {
                    case 41:
                        this.store.state.formUpdateSummary.humanResearchData = [...value]
                        for (
                            let i = 0;
                            i < this.store.state.formUpdateSummary.humanResearchData.length;
                            i++
                        ) {
                            this.store.state.formUpdateSummary.humanResearchData[
                                i
                            ].update_date = formatDate(
                                this.store.state.formUpdateSummary.humanResearchData[i]
                                    .update_date
                            )
                        }
                        break
                    case 42:
                        this.store.state.formUpdateSummary.humanReportedData = [...value]
                        for (
                            let i = 0;
                            i < this.store.state.formUpdateSummary.humanReportedData.length;
                            i++
                        ) {
                            this.store.state.formUpdateSummary.humanReportedData[
                                i
                            ].update_date = formatDate(
                                this.store.state.formUpdateSummary.humanReportedData[i]
                                    .update_date
                            )
                        }
                        break
                    case 43:
                        this.store.state.formUpdateSummary.animalResearchResultData = [
                            ...value
                        ]
                        for (
                            let i = 0;
                            i <
                this.store.state.formUpdateSummary.animalResearchResultData
                    .length;
                            i++
                        ) {
                            this.store.state.formUpdateSummary.animalResearchResultData[
                                i
                            ].update_date = formatDate(
                                this.store.state.formUpdateSummary.animalResearchResultData[i]
                                    .update_date
                            )
                        }
                        break
                    case 44:
                        this.store.state.formUpdateSummary.interviewData = [...value]
                        for (
                            let i = 0;
                            i < this.store.state.formUpdateSummary.interviewData.length;
                            i++
                        ) {
                            this.store.state.formUpdateSummary.interviewData[
                                i
                            ].update_date = formatDate(
                                this.store.state.formUpdateSummary.interviewData[i]
                                    .update_date
                            )
                        }
                        break
                    case 45:
                        this.store.state.formUpdateSummary.packageInsertData = [...value]
                        for (
                            let i = 0;
                            i < this.store.state.formUpdateSummary.packageInsertData.length;
                            i++
                        ) {
                            this.store.state.formUpdateSummary.packageInsertData[
                                i
                            ].update_date = formatDate(
                                this.store.state.formUpdateSummary.packageInsertData[i]
                                    .update_date
                            )
                        }
                        break
                    case 46:
                        this.store.state.formUpdateSummary.otherMaterialsData = [...value]
                        for (
                            let i = 0;
                            i < this.store.state.formUpdateSummary.otherMaterialsData.length;
                            i++
                        ) {
                            this.store.state.formUpdateSummary.otherMaterialsData[
                                i
                            ].update_date = formatDate(
                                this.store.state.formUpdateSummary.otherMaterialsData[i]
                                    .update_date
                            )
                        }
                        break
                    case 50:
                        this.store.state.formUpdateSummary.motherRiskStatementData = [
                            ...value
                        ]
                        for (
                            let i = 0;
                            i <
                this.store.state.formUpdateSummary.motherRiskStatementData
                    .length;
                            i++
                        ) {
                            this.store.state.formUpdateSummary.motherRiskStatementData[
                                i
                            ].update_date = formatDate(
                                this.store.state.formUpdateSummary.motherRiskStatementData[i]
                                    .update_date
                            )
                        }
                        break
                    }
                }
            })
        }
        if (!this.store.state.isRenewToken) {
            this.store.state.isSummaryBeingEdit = false
        } else {
            this.store.state.isSummaryBeingEdit = true
            this.store.state.isRenewToken = false
        }
    },
    methods: {

        /**
         * 表4.1の選択した行を削除する
         * @param {Array<number>} e -行番号一覧
         * */
        deleteHumanResearchData(e) {
            for (let i = e.length - 1; i >= 0; i--) {
                this.store.state.formUpdateSummary.humanResearchData.splice(e[i], 1)
            }
        },

        /**
         * 表4.2の選択した行を削除する
         * @param {Array<number>} e -行番号一覧
         * */
        deleteHumanReportedData(e) {
            for (let i = e.length - 1; i >= 0; i--) {
                this.store.state.formUpdateSummary.humanReportedData.splice(e[i], 1)
            }
        },

        /**
         * 表4.3の選択した行を削除する
         * @param {Array<number>} e -行番号一覧
         * */
        deleteAnimalResearchResultData(e) {
            for (let i = e.length - 1; i >= 0; i--) {
                this.store.state.formUpdateSummary.animalResearchResultData.splice(
                    e[i],
                    1
                )
            }
        },

        /**
         * 表4.4の選択した行を削除する
         * @param {Array<number>} e -行番号一覧
         * */
        deleteInterviewData(e) {
            for (let i = e.length - 1; i >= 0; i--) {
                this.store.state.formUpdateSummary.interviewData.splice(e[i], 1)
            }
        },

        /**
         * 表4.5の選択した行を削除する
         * @param {Array<number>} e -行番号一覧
         * */
        deletePackageInsertData(e) {
            for (let i = e.length - 1; i >= 0; i--) {
                this.store.state.formUpdateSummary.packageInsertData.splice(e[i], 1)
            }
        },

        /**
         * 表4.6の選択した行を削除する
         * @param {Array<number>} e -行番号一覧
         * */
        deleteOtherMaterialsData(e) {
            for (let i = e.length - 1; i >= 0; i--) {
                this.store.state.formUpdateSummary.otherMaterialsData.splice(e[i], 1)
            }
        },

        /**
         * 表5.0の選択した行を削除する
         * @param {Array<number>} e -行番号一覧
         * */
        deleteMotherRiskStatementData(e) {
            for (let i = e.length - 1; i >= 0; i--) {
                this.store.state.formUpdateSummary.motherRiskStatementData.splice(
                    e[i],
                    1
                )
            }
        },

        /**
         * サマリーを編集するためにvuexアクションを呼び出す
         */
        async editSummary() {
            const isComfirmed = await ConfirmBox(this.textDisplay.msg_033)
            if (isComfirmed.isConfirmed) {
                this.store.state.isLoading = true
                const payload = {
                    id: this.store.state.formUpdateSummary.summary_id,
                    data: {
                        drug_name: this.store.state.formUpdateSummary.drug_name,
                        drug_kana: this.store.state.formUpdateSummary.drug_kana,
                        general: this.store.state.formUpdateSummary.general,
                        effect_for_anomaly:
              this.store.state.formUpdateSummary.effect_for_anomaly,
                        effect_for_baby: this.store.state.formUpdateSummary.effect_for_baby,
                        other: this.store.state.formUpdateSummary.other,
                        placenta_passage:
              this.store.state.formUpdateSummary.placenta_passage,
                        animal_test: this.store.state.formUpdateSummary.animal_test,
                        attached: this.store.state.formUpdateSummary.attached,
                        summarize: this.store.state.formUpdateSummary.summarize,
                        pharmacological_name:
              this.store.state.formUpdateSummary.pharmacological_name,
                        major_drug_name: this.store.state.formUpdateSummary.major_drug_name,
                        classification: this.store.state.formUpdateSummary.classification,
                        update_date: this.store.state.formUpdateSummary.update_date,
                        version: this.store.state.formUpdateSummary.version
                    }
                }
                payload.data.literatures = [
                    ...this.store.state.formUpdateSummary.humanResearchData.map(
                        (value) => {
                            delete value.update_date
                            return { ...value, item: '41' }
                        }
                    ),
                    ...this.store.state.formUpdateSummary.humanReportedData.map(
                        (value) => {
                            delete value.update_date
                            return { ...value, item: '42' }
                        }
                    ),
                    ...this.store.state.formUpdateSummary.animalResearchResultData.map(
                        (value) => {
                            delete value.update_date
                            return { ...value, item: '43' }
                        }
                    ),
                    ...this.store.state.formUpdateSummary.interviewData.map((value) => {
                        delete value.update_date
                        return { ...value, item: '44' }
                    }),
                    ...this.store.state.formUpdateSummary.packageInsertData.map(
                        (value) => {
                            delete value.update_date
                            return { ...value, item: '45' }
                        }
                    ),
                    ...this.store.state.formUpdateSummary.otherMaterialsData.map(
                        (value) => {
                            delete value.update_date
                            return { ...value, item: '46' }
                        }
                    ),
                    ...this.store.state.formUpdateSummary.motherRiskStatementData.map(
                        (value) => {
                            delete value.update_date
                            return { ...value, item: '50' }
                        }
                    )
                ]
                let isSuccessful = false
                this.store
                    .dispatch('editSummary', payload)
                    .then((result) => {
                        this.store.state.formUpdateSummary.update_date =
              result.data.update_date
                        this.store.state.formUpdateSummary.update_user =
              result.data.update_user
                        this.store.state.formUpdateSummary.update_username =
              result.data.update_username
                        this.store.state.formUpdateSummary.summary_id = result.data.summary_id
                        this.store.state.formUpdateSummary.version = result.data.version
                        this.store.state.formUpdateSummary.status = result.data.status
                        isSuccessful = true
                        result.data.literatures.forEach((value) => {
                            if (value.length > 0) {
                                switch (value[0].item) {
                                case 41:
                                    this.store.state.formUpdateSummary.humanResearchData = [...value]
                                    for (
                                        let i = 0;
                                        i < this.store.state.formUpdateSummary.humanResearchData.length;
                                        i++
                                    ) {
                                        this.store.state.formUpdateSummary.humanResearchData[
                                            i
                                        ].update_date = formatDate(
                                            this.store.state.formUpdateSummary.humanResearchData[i]
                                                .update_date
                                        )
                                    }
                                    break
                                case 42:
                                    this.store.state.formUpdateSummary.humanReportedData = [...value]
                                    for (
                                        let i = 0;
                                        i < this.store.state.formUpdateSummary.humanReportedData.length;
                                        i++
                                    ) {
                                        this.store.state.formUpdateSummary.humanReportedData[
                                            i
                                        ].update_date = formatDate(
                                            this.store.state.formUpdateSummary.humanReportedData[i]
                                                .update_date
                                        )
                                    }
                                    break
                                case 43:
                                    this.store.state.formUpdateSummary.animalResearchResultData = [
                                        ...value
                                    ]
                                    for (
                                        let i = 0;
                                        i <
                            this.store.state.formUpdateSummary.animalResearchResultData
                                .length;
                                        i++
                                    ) {
                                        this.store.state.formUpdateSummary.animalResearchResultData[
                                            i
                                        ].update_date = formatDate(
                                            this.store.state.formUpdateSummary.animalResearchResultData[i]
                                                .update_date
                                        )
                                    }
                                    break
                                case 44:
                                    this.store.state.formUpdateSummary.interviewData = [...value]
                                    for (
                                        let i = 0;
                                        i < this.store.state.formUpdateSummary.interviewData.length;
                                        i++
                                    ) {
                                        this.store.state.formUpdateSummary.interviewData[
                                            i
                                        ].update_date = formatDate(
                                            this.store.state.formUpdateSummary.interviewData[i]
                                                .update_date
                                        )
                                    }
                                    break
                                case 45:
                                    this.store.state.formUpdateSummary.packageInsertData = [...value]
                                    for (
                                        let i = 0;
                                        i < this.store.state.formUpdateSummary.packageInsertData.length;
                                        i++
                                    ) {
                                        this.store.state.formUpdateSummary.packageInsertData[
                                            i
                                        ].update_date = formatDate(
                                            this.store.state.formUpdateSummary.packageInsertData[i]
                                                .update_date
                                        )
                                    }
                                    break
                                case 46:
                                    this.store.state.formUpdateSummary.otherMaterialsData = [...value]
                                    for (
                                        let i = 0;
                                        i < this.store.state.formUpdateSummary.otherMaterialsData.length;
                                        i++
                                    ) {
                                        this.store.state.formUpdateSummary.otherMaterialsData[
                                            i
                                        ].update_date = formatDate(
                                            this.store.state.formUpdateSummary.otherMaterialsData[i]
                                                .update_date
                                        )
                                    }
                                    break
                                case 50:
                                    this.store.state.formUpdateSummary.motherRiskStatementData = [
                                        ...value
                                    ]
                                    for (
                                        let i = 0;
                                        i <
                            this.store.state.formUpdateSummary.motherRiskStatementData
                                .length;
                                        i++
                                    ) {
                                        this.store.state.formUpdateSummary.motherRiskStatementData[
                                            i
                                        ].update_date = formatDate(
                                            this.store.state.formUpdateSummary.motherRiskStatementData[i]
                                                .update_date
                                        )
                                    }
                                    break
                                }
                            }
                        })
                        NotificationMessage(
                            this.textDisplay.msg_016,
                            3000,
                            ACTION_TYPE.success
                        )
                    })
                    .catch((e) => {
                        NotificationMessage(
                            e.response.data.message,
                            3000,
                            ACTION_TYPE.error
                        )
                    })
                    .finally(() => {
                        this.store.state.isLoading = false
                        if (isSuccessful) {
                            this.store.state.isSummaryBeingEdit = false
                            this.$router.push(`/summary/view-summary/${this.store.state.formUpdateSummary.summary_id}`)
                        }
                    })
            }
        },

        /**
         * サマリーを下書きとして保存するためのvuexアクションを呼び出す
         */
        async saveToDraft() {
            const isComfirmed = await ConfirmBox(
                this.textDisplay.msg_025
            )
            if (isComfirmed.isConfirmed) {
                this.store.state.isLoading = true
                const payload = {
                    id: this.store.state.formUpdateSummary.summary_id,
                    data: {
                        drug_name: this.store.state.formUpdateSummary.drug_name,
                        drug_kana: this.store.state.formUpdateSummary.drug_kana,
                        general: this.store.state.formUpdateSummary.general,
                        effect_for_anomaly:
              this.store.state.formUpdateSummary.effect_for_anomaly,
                        effect_for_baby: this.store.state.formUpdateSummary.effect_for_baby,
                        other: this.store.state.formUpdateSummary.other,
                        placenta_passage:
              this.store.state.formUpdateSummary.placenta_passage,
                        animal_test: this.store.state.formUpdateSummary.animal_test,
                        attached: this.store.state.formUpdateSummary.attached,
                        summarize: this.store.state.formUpdateSummary.summarize,
                        pharmacological_name:
              this.store.state.formUpdateSummary.pharmacological_name,
                        major_drug_name: this.store.state.formUpdateSummary.major_drug_name,
                        classification: this.store.state.formUpdateSummary.classification,
                        update_date: this.store.state.formUpdateSummary.update_date,
                        temp: true
                    }
                }
                payload.data.literatures = [
                    ...this.store.state.formUpdateSummary.humanResearchData.map(
                        (value) => {
                            delete value.update_date
                            return { ...value, item: '41' }
                        }
                    ),
                    ...this.store.state.formUpdateSummary.humanReportedData.map(
                        (value) => {
                            delete value.update_date
                            return { ...value, item: '42' }
                        }
                    ),
                    ...this.store.state.formUpdateSummary.animalResearchResultData.map(
                        (value) => {
                            delete value.update_date
                            return { ...value, item: '43' }
                        }
                    ),
                    ...this.store.state.formUpdateSummary.interviewData.map((value) => {
                        delete value.update_date
                        return { ...value, item: '44' }
                    }),
                    ...this.store.state.formUpdateSummary.packageInsertData.map(
                        (value) => {
                            delete value.update_date
                            return { ...value, item: '45' }
                        }
                    ),
                    ...this.store.state.formUpdateSummary.otherMaterialsData.map(
                        (value) => {
                            delete value.update_date
                            return { ...value, item: '46' }
                        }
                    ),
                    ...this.store.state.formUpdateSummary.motherRiskStatementData.map(
                        (value) => {
                            delete value.update_date
                            return { ...value, item: '50' }
                        }
                    )
                ]
                let isSuccessful = false
                this.store
                    .dispatch('editSummary', payload)
                    .then((result) => {
                        this.store.state.formUpdateSummary.update_date =
              result.data.update_date
                        this.store.state.formUpdateSummary.update_user =
              result.data.update_user
                        this.store.state.formUpdateSummary.update_username =
              result.data.update_username
                        this.store.state.formUpdateSummary.summary_id = result.data.summary_id
                        this.store.state.formUpdateSummary.version = result.data.version
                        this.store.state.formUpdateSummary.status = result.data.status
                        isSuccessful = true
                        result.data.literatures.forEach((value) => {
                            if (value.length > 0) {
                                switch (value[0].item) {
                                case 41:
                                    this.store.state.formUpdateSummary.humanResearchData = [...value]
                                    for (
                                        let i = 0;
                                        i < this.store.state.formUpdateSummary.humanResearchData.length;
                                        i++
                                    ) {
                                        this.store.state.formUpdateSummary.humanResearchData[
                                            i
                                        ].update_date = formatDate(
                                            this.store.state.formUpdateSummary.humanResearchData[i]
                                                .update_date
                                        )
                                    }
                                    break
                                case 42:
                                    this.store.state.formUpdateSummary.humanReportedData = [...value]
                                    for (
                                        let i = 0;
                                        i < this.store.state.formUpdateSummary.humanReportedData.length;
                                        i++
                                    ) {
                                        this.store.state.formUpdateSummary.humanReportedData[
                                            i
                                        ].update_date = formatDate(
                                            this.store.state.formUpdateSummary.humanReportedData[i]
                                                .update_date
                                        )
                                    }
                                    break
                                case 43:
                                    this.store.state.formUpdateSummary.animalResearchResultData = [
                                        ...value
                                    ]
                                    for (
                                        let i = 0;
                                        i <
                            this.store.state.formUpdateSummary.animalResearchResultData
                                .length;
                                        i++
                                    ) {
                                        this.store.state.formUpdateSummary.animalResearchResultData[
                                            i
                                        ].update_date = formatDate(
                                            this.store.state.formUpdateSummary.animalResearchResultData[i]
                                                .update_date
                                        )
                                    }
                                    break
                                case 44:
                                    this.store.state.formUpdateSummary.interviewData = [...value]
                                    for (
                                        let i = 0;
                                        i < this.store.state.formUpdateSummary.interviewData.length;
                                        i++
                                    ) {
                                        this.store.state.formUpdateSummary.interviewData[
                                            i
                                        ].update_date = formatDate(
                                            this.store.state.formUpdateSummary.interviewData[i]
                                                .update_date
                                        )
                                    }
                                    break
                                case 45:
                                    this.store.state.formUpdateSummary.packageInsertData = [...value]
                                    for (
                                        let i = 0;
                                        i < this.store.state.formUpdateSummary.packageInsertData.length;
                                        i++
                                    ) {
                                        this.store.state.formUpdateSummary.packageInsertData[
                                            i
                                        ].update_date = formatDate(
                                            this.store.state.formUpdateSummary.packageInsertData[i]
                                                .update_date
                                        )
                                    }
                                    break
                                case 46:
                                    this.store.state.formUpdateSummary.otherMaterialsData = [...value]
                                    for (
                                        let i = 0;
                                        i < this.store.state.formUpdateSummary.otherMaterialsData.length;
                                        i++
                                    ) {
                                        this.store.state.formUpdateSummary.otherMaterialsData[
                                            i
                                        ].update_date = formatDate(
                                            this.store.state.formUpdateSummary.otherMaterialsData[i]
                                                .update_date
                                        )
                                    }
                                    break
                                case 50:
                                    this.store.state.formUpdateSummary.motherRiskStatementData = [
                                        ...value
                                    ]
                                    for (
                                        let i = 0;
                                        i <
                            this.store.state.formUpdateSummary.motherRiskStatementData
                                .length;
                                        i++
                                    ) {
                                        this.store.state.formUpdateSummary.motherRiskStatementData[
                                            i
                                        ].update_date = formatDate(
                                            this.store.state.formUpdateSummary.motherRiskStatementData[i]
                                                .update_date
                                        )
                                    }
                                    break
                                }
                            }
                        })
                        NotificationMessage(
                            this.textDisplay.msg_save_to_draft_successfully,
                            3000,
                            ACTION_TYPE.success
                        )
                    })
                    .catch((e) => {
                        NotificationMessage(
                            e.response.data.message,
                            3000,
                            ACTION_TYPE.error
                        )
                    })
                    .finally(() => {
                        this.store.state.isLoading = false
                        if (isSuccessful) {
                            this.store.state.isSummaryBeingEdit = false
                            this.$router.push(`/summary/view-summary/${this.store.state.formUpdateSummary.summary_id}`)
                        }
                    })
            }
        },

        /**
         * テーブル4.1の上部に新しい行を追加するためのポップアップを開く
         */
        addHumanResearchDataRow() {
            this.isAddingRow = true
            this.clickHumanResearchDataRow(
                0
            )
        },

        /**
         * テーブル4.2の上部に新しい行を追加するためのポップアップを開く
         */
        addHumanReportedDataRow() {
            this.isAddingRow = true
            this.clickHumanReportedDataRow(0)
        },

        /**
         * テーブル4.3の上部に新しい行を追加するためのポップアップを開く
         */
        addAnimalResearchResultDataRow() {
            this.isAddingRow = true
            this.clickAnimalResearchResultDataRow(0)
        },

        /**
         * テーブル4.4の上部に新しい行を追加するためのポップアップを開く
         */
        addInterviewDataRow() {
            this.isAddingRow = true
            this.clickInterviewDataRow(0)
        },

        /**
         * テーブル4.5の上部に新しい行を追加するためのポップアップを開く
         */
        addPackageInsertDataRow() {
            this.isAddingRow = true
            this.clickPackageInsertDataRow(0)
        },

        /**
         * テーブル4.6の上部に新しい行を追加するためのポップアップを開く
         */
        addOtherMaterialsDataRow() {
            this.isAddingRow = true
            this.clickOtherMaterialsDataRow(0)
        },

        /**
         * テーブル5.0の上部に新しい行を追加するためのポップアップを開く
         */
        addMotherRiskStatementRow() {
            this.isAddingRow = true
            this.clickMotherRiskStatementRow(0)
        },

        /**
         * 表4.1の行をクリック
         * @param {number} e - 行のインデックス
         * */
        clickHumanResearchDataRow(e) {
            if (this.isAddingRow) {
                this.tableForm = {
                    year_country: '',
                    author: '',
                    publication_magazine: '',
                    title: '',
                    design: '',
                    exposure_n_control_n: '',
                    outcome: '',
                    result: '',
                    recruitment: true,
                    reason: '',
                    brtm: '',
                    memo: '',
                    endnote_link: ''
                }
            } else {
                this.tableForm = {
                    ...this.store.state.formUpdateSummary.humanResearchData[e]
                }
            }
            if (this.tableForm.recruitment === false) {
                this.tableForm.recruitment = this.textDisplay.selectOptionRecruitment[1]
            } else {
                this.tableForm.recruitment = this.textDisplay.selectOptionRecruitment[0]
            }
            this.tableForm.type = 41
            this.tableForm.index = e
            this.isHumanResearchModalOpen = true
        },

        /**
         * 表4.2の行をクリック
         * @param {number} e - 行のインデックス
         * */
        clickHumanReportedDataRow(e) {
            if (this.isAddingRow) {
                this.tableForm = {
                    year_country: '',
                    author: '',
                    publication_magazine: '',
                    title: '',
                    result: '',
                    recruitment: true,
                    judgment_reason: '',
                    brtm: '',
                    memo: '',
                    endnote_link: ''
                }
            } else {
                this.tableForm = {
                    ...this.store.state.formUpdateSummary.humanReportedData[e]
                }
            }
            if (this.tableForm.recruitment === false) {
                this.tableForm.recruitment = this.textDisplay.selectOptionRecruitment[1]
            } else {
                this.tableForm.recruitment = this.textDisplay.selectOptionRecruitment[0]
            }
            this.tableForm.type = 42
            this.tableForm.index = e
            this.isHumanReportedModalOpen = true
        },

        /**
         * 表4.3の行をクリック
         * @param {number} e - 行のインデックス
         * */
        clickAnimalResearchResultDataRow(e) {
            if (this.isAddingRow) {
                this.tableForm = {
                    year_country: '',
                    author: '',
                    publication_magazine: '',
                    title: '',
                    animal_species: '',
                    outcome: '',
                    dose: '',
                    result: '',
                    brtm: '',
                    memo: '',
                    endnote_link: ''
                }
            } else {
                this.tableForm = {
                    ...this.store.state.formUpdateSummary.animalResearchResultData[e]
                }
            }
            this.tableForm.type = 43
            this.tableForm.index = e
            this.isAnimalTestModalOpen = true
        },

        /**
         * 表4.4の行をクリック
         * @param {number} e - 行のインデックス
         * */
        clickInterviewDataRow(e) {
            if (this.isAddingRow) {
                this.tableForm = {
                    postscript_date: '',
                    product_name: '',
                    version: '',
                    pdf_link: ''
                }
            } else {
                this.tableForm = {
                    ...this.store.state.formUpdateSummary.interviewData[e]
                }
            }
            this.tableForm.type = 44
            this.tableForm.index = e
            this.isInterviewModalOpen = true
        },

        /**
         * 表4.5の行をクリック
         * @param {number} e - 行のインデックス
         * */
        clickPackageInsertDataRow(e) {
            if (this.isAddingRow) {
                this.tableForm = {
                    postscript_date: '',
                    reference_page: '',
                    version: '',
                    pdf_link: ''
                }
            } else {
                this.tableForm = {
                    ...this.store.state.formUpdateSummary.packageInsertData[e]
                }
            }
            this.tableForm.type = 45
            this.tableForm.index = e
            this.isPackageInsertModalOpen = true
        },

        /**
         * 表4.6の行をクリック
         * @param {number} e - 行のインデックス
         * */
        clickOtherMaterialsDataRow(e) {
            if (this.isAddingRow) {
                this.tableForm = {
                    postscript_date: '',
                    document_content: '',
                    destination_link: ''
                }
            } else {
                this.tableForm = {
                    ...this.store.state.formUpdateSummary.otherMaterialsData[e]
                }
            }
            this.tableForm.type = 46
            this.tableForm.index = e
            this.isOtherMaterialsModalOpen = true
        },

        /**
         * 表5.0の行をクリック
         * @param {number} e - 行のインデックス
         * */
        clickMotherRiskStatementRow(e) {
            if (this.isAddingRow) {
                this.tableForm = {
                    references: '',
                    reference_page: '',
                    endnote_link: '',
                    updated_at: ''
                }
            } else {
                this.tableForm = {
                    ...this.store.state.formUpdateSummary.motherRiskStatementData[e]
                }
            }
            this.tableForm.year = this.textDisplay.selectOptionYear.find(element => element.value === this.tableForm.year)
            this.tableForm.type = 50
            this.tableForm.index = e
            this.isMotherRiskStatemantModalOpen = true
        },

        /**
         * テーブルデータに新しい行を追加して保存する
         */
        saveRow() {
            if (this.isAddingRow) {
                switch (this.tableForm.type) {
                case 41:
                    this.tableForm.recruitment = this.tableForm.recruitment.value
                    this.store.state.formUpdateSummary.humanResearchData.splice(0, 0, { ...this.tableForm })
                    this.tableForm = {}
                    this.isHumanResearchModalOpen = false
                    this.isAddingRow = false
                    this.$refs.allInfoComponent.addHumanResearchRow()
                    break
                case 42:
                    this.tableForm.recruitment = this.tableForm.recruitment.value
                    this.store.state.formUpdateSummary.humanReportedData.splice(0, 0, { ...this.tableForm })
                    this.tableForm = {}
                    this.isHumanReportedModalOpen = false
                    this.isAddingRow = false
                    this.$refs.allInfoComponent.addHumanReportedRow()
                    break
                case 43:
                    this.store.state.formUpdateSummary.animalResearchResultData.splice(0, 0, { ...this.tableForm })
                    this.tableForm = {}
                    this.isAnimalTestModalOpen = false
                    this.isAddingRow = false
                    this.$refs.allInfoComponent.addAnimalResearchResultRow()
                    break
                case 44:
                    this.store.state.formUpdateSummary.interviewData.splice(0, 0, { ...this.tableForm })
                    this.tableForm = {}
                    this.isInterviewModalOpen = false
                    this.isAddingRow = false
                    this.$refs.allInfoComponent.addInterviewRow()
                    break
                case 45:
                    this.store.state.formUpdateSummary.packageInsertData.splice(0, 0, { ...this.tableForm })
                    this.tableForm = {}
                    this.isPackageInsertModalOpen = false
                    this.isAddingRow = false
                    this.$refs.allInfoComponent.addPackageInsertRow()
                    break
                case 46:
                    this.store.state.formUpdateSummary.otherMaterialsData.splice(0, 0, { ...this.tableForm })
                    this.tableForm = {}
                    this.isOtherMaterialsModalOpen = false
                    this.isAddingRow = false
                    this.$refs.allInfoComponent.addOtherMaterialsRow()
                    break
                case 50:
                    if (this.tableForm.year) {
                        this.tableForm.year = this.tableForm.year.value
                    }
                    this.store.state.formUpdateSummary.motherRiskStatementData.splice(0, 0, { ...this.tableForm })
                    this.tableForm = {}
                    this.isMotherRiskStatemantModalOpen = false
                    this.isAddingRow = false
                    this.$refs.allInfoComponent.addMotherRiskStatementRow()
                    break
                }
            } else {
                switch (this.tableForm.type) {
                case 41:
                    this.tableForm.recruitment = this.tableForm.recruitment.value
                    this.store.state.formUpdateSummary.humanResearchData[
                        this.tableForm.index
                    ] = { ...this.tableForm }
                    this.isHumanResearchModalOpen = false
                    break
                case 42:
                    this.tableForm.recruitment = this.tableForm.recruitment.value
                    this.store.state.formUpdateSummary.humanReportedData[
                        this.tableForm.index
                    ] = { ...this.tableForm }
                    this.isHumanReportedModalOpen = false
                    break
                case 43:
                    this.store.state.formUpdateSummary.animalResearchResultData[
                        this.tableForm.index
                    ] = { ...this.tableForm }
                    this.isAnimalTestModalOpen = false
                    break
                case 44:
                    this.store.state.formUpdateSummary.interviewData[
                        this.tableForm.index
                    ] = { ...this.tableForm }
                    this.isInterviewModalOpen = false
                    break
                case 45:
                    this.store.state.formUpdateSummary.packageInsertData[
                        this.tableForm.index
                    ] = { ...this.tableForm }
                    this.isPackageInsertModalOpen = false
                    break
                case 46:
                    this.store.state.formUpdateSummary.otherMaterialsData[
                        this.tableForm.index
                    ] = { ...this.tableForm }
                    this.isOtherMaterialsModalOpen = false
                    break
                case 50:
                    if (this.tableForm.year) {
                        this.tableForm.year = this.tableForm.year.value
                    }
                    this.store.state.formUpdateSummary.motherRiskStatementData[
                        this.tableForm.index
                    ] = { ...this.tableForm }
                    this.isMotherRiskStatemantModalOpen = false
                    break
                }
            }
        },

        /**
         * テーブルデータへの行の追加をキャンセルする
         */
        cancelModal() {
            this.tableForm = {}
            this.isMotherRiskStatemantModalOpen = false
            this.isOtherMaterialsModalOpen = false
            this.isPackageInsertModalOpen = false
            this.isInterviewModalOpen = false
            this.isAnimalTestModalOpen = false
            this.isHumanResearchModalOpen = false
            this.isHumanReportedModalOpen = false
            this.isAddingRow = false
        }
    }
}
</script>
