import { createRouter, createWebHistory } from 'vue-router'
import { NotificationMessage } from '../common/NotificationMessage'
import { ACTION_TYPE } from '../common/constants'
import { ConfirmBox } from '../common/ConfirmBox'

// View
import Auth from '../views/Auth.vue'

// Component
import LoginComponent from '../components/auth/LoginComponent.vue'
import LogoutComponent from '../components/auth/LogoutComponent'
import ListUserComponent from '../components/pages/user_management/ListUserComponent.vue'
import CreateUserComponent from '../components/pages/user_management/CreateUserComponent.vue'
import EditUserComponent from '../components/pages/user_management/EditUserComponent.vue'
import UserManagementComponent from '../components/pages/user_management/UserManagementComponent.vue'
import CreateSummaryComponent from '../components/pages/summary/CreateSummaryComponent.vue'
import SearchSummaryComponent from '../components/pages/summary/SearchSummaryComponent.vue'
import ViewSummaryComponent from '../components/pages/summary/ViewSummaryComponent.vue'
import EditSummaryComponent from '../components/pages/summary/EditSummaryComponent.vue'
import MainMenuConponent from '../components/pages/main_menu/MainMenuConponent'
import store from '@/store/index'
import { textDisplay } from '../common/TextDisplay'

const routes = [
    {
        path: '/',
        name: 'Page',
        // which is lazy-loaded when the route is visited.
        component: () => import('../views/Page.vue'),
        meta: { requiredAuth: true },
        children: [
            {
                path: 'users',
                name: 'UserManagement',
                meta: { requiredAuth: true, requiredRole: 1 },
                component: UserManagementComponent,
                children: [
                    {
                        path: 'list',
                        name: 'ListUser',
                        component: ListUserComponent
                    },
                    {
                        path: 'create',
                        name: 'CreateUser',
                        component: CreateUserComponent
                    },
                    {
                        path: 'edit/:userId',
                        name: 'EditUser',
                        component: EditUserComponent
                    }
                ]
            },
            {
                path: 'summary/create-summary',
                name: 'CreateSummary',
                meta: { requiredAuth: true, requiredRole: 0, preventNavigate: true },
                component: CreateSummaryComponent
            },
            {
                path: 'summary/search-summary',
                name: 'SearchSummary',
                meta: { requiredAuth: true, requiredRole: 0 },
                component: SearchSummaryComponent
            },
            {
                path: 'summary/view-summary/:index',
                name: 'ViewSummary',
                meta: { requiredAuth: true },
                component: ViewSummaryComponent
            },
            {
                path: 'summary/edit-summary/:index',
                name: 'EditSummary',
                meta: { requiredAuth: true, requiredRole: 0, preventNavigate: true },
                component: EditSummaryComponent
            },
            {
                path: '/',
                name: 'MainMenu',
                meta: { requiredAuth: true },
                component: MainMenuConponent
            }
        ]
    },
    {
        path: '/auth',
        name: 'Auth',
        component: Auth,
        children: [
            {
                path: 'login',
                name: 'Login',
                component: LoginComponent,
                meta: { isLoggedIn: true }
            },
            {
                path: 'logout',
                name: 'Logout',
                meta: { requiredAuth: true, preventNavigate: true },
                component: LogoutComponent
            }
        ]
    }
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})
router.beforeEach(async (to, from, next) => {
    if (to.meta.requiredAuth) {
        if (store.state.user.access_token === '') {
            next('/auth/login')
            return
        } else {
            const expiredAt = new Date(store.state.user.expired_at)
            if (new Date().getTime() > expiredAt.getTime()) {
                NotificationMessage(textDisplay.msg_token_expired, 3000, ACTION_TYPE.warning)
                next('/auth/login')
                return
            }
        }
    }
    if (to.meta.requiredRole) {
        if (to.meta.requiredRole > store.state.user.authority) {
            NotificationMessage('PERMISSION DENY!', 3000, ACTION_TYPE.error)
            return
        }
    }
    // Show confirm box before redirect from edit summary or create summary screen
    // Choose yes => save it to draft before redirecting
    // Choose no => redirect and delete all data input
    if (from.meta.preventNavigate) {
        let isCancel = false
        // Check if this is edit summary screen or create summary screen
        switch (from.name) {
        case 'EditSummary':
            // If this is edit summary and the data was changed
            if (store.state.isSummaryBeingEdit) {
                // Show confirm box
                await ConfirmBox(textDisplay.msg_004a).then((result) => {
                    // If choose yes
                    if (result.isConfirmed) {
                        // Define and fill data to payload (request body)
                        const payload = {
                            id: store.state.formUpdateSummary.summary_id,
                            data: {
                                drug_name: store.state.formUpdateSummary.drug_name,
                                drug_kana: store.state.formUpdateSummary.drug_kana,
                                general: store.state.formUpdateSummary.general,
                                effect_for_anomaly:
                      store.state.formUpdateSummary.effect_for_anomaly,
                                effect_for_baby: store.state.formUpdateSummary.effect_for_baby,
                                other: store.state.formUpdateSummary.other,
                                placenta_passage:
                      store.state.formUpdateSummary.placenta_passage,
                                animal_test: store.state.formUpdateSummary.animal_test,
                                attached: store.state.formUpdateSummary.attached,
                                summarize: store.state.formUpdateSummary.summarize,
                                pharmacological_name:
                      store.state.formUpdateSummary.pharmacological_name,
                                major_drug_name: store.state.formUpdateSummary.major_drug_name,
                                classification: store.state.formUpdateSummary.classification,
                                update_date: store.state.formUpdateSummary.update_date,
                                temp: true
                            }
                        }
                        payload.data.literatures = [
                            ...store.state.formUpdateSummary.humanResearchData.map(
                                (value) => {
                                    delete value.update_date
                                    return { ...value, item: '41' }
                                }
                            ),
                            ...store.state.formUpdateSummary.humanReportedData.map(
                                (value) => {
                                    delete value.update_date
                                    return { ...value, item: '42' }
                                }
                            ),
                            ...store.state.formUpdateSummary.animalResearchResultData.map(
                                (value) => {
                                    delete value.update_date
                                    return { ...value, item: '43' }
                                }
                            ),
                            ...store.state.formUpdateSummary.interviewData.map((value) => {
                                delete value.update_date
                                return { ...value, item: '44' }
                            }),
                            ...store.state.formUpdateSummary.packageInsertData.map(
                                (value) => {
                                    delete value.update_date
                                    return { ...value, item: '45' }
                                }
                            ),
                            ...store.state.formUpdateSummary.otherMaterialsData.map(
                                (value) => {
                                    delete value.update_date
                                    return { ...value, item: '46' }
                                }
                            ),
                            ...store.state.formUpdateSummary.motherRiskStatementData.map(
                                (value) => {
                                    delete value.update_date
                                    return { ...value, item: '50' }
                                }
                            )
                        ]
                        store.state.isLoading = true
                        // Call action editSummary with the above payload
                        return store
                            .dispatch('editSummary', payload)
                            .then((result) => {
                                NotificationMessage(
                                    textDisplay.msg_save_to_draft_successfully,
                                    3000,
                                    ACTION_TYPE.success
                                )
                                // If save successfully => return true to the next handler step
                                return true
                            })
                            .catch((e) => {
                                NotificationMessage(
                                    e.response.data.message,
                                    3000,
                                    ACTION_TYPE.error
                                )
                                // If something wrongs => return false to the next handler step
                                return false
                            })
                            .finally(() => {
                                store.state.isLoading = false
                            })
                    } else {
                        return true
                    }
                })
                    .then(result => {
                        // If the previous handler return true
                        if (result) {
                            // Clear data from global state (store)
                            store.state.isSummaryBeingEdit = false
                            store.state.formUpdateSummary = {
                                id: '',
                                summary_id: '',
                                // Public
                                drug_name: '',
                                drug_kana: '',
                                general: '',
                                effect_for_anomaly: '',
                                effect_for_baby: '',
                                other: '',
                                placenta_passage: '',
                                animal_test: '',
                                attached: '',
                                summarize: '',
                                update_user: '',
                                update_date: '',
                                update_username: '',
                                version: '',
                                status: '',
                                // All Info
                                pharmacological_name: '',
                                major_drug_name: '',
                                classification: '',
                                humanResearchData: [],
                                humanReportedData: [],
                                animalResearchResultData: [],
                                interviewData: [],
                                packageInsertData: [],
                                otherMaterialsData: [],
                                motherRiskStatementData: []
                            }
                        } else {
                            // If the previous handler return false => set isCancel is true to prevent navigation
                            isCancel = true
                        }
                    })
            }
            break
        case 'CreateSummary':
            if (store.state.isSummaryBeingCreate) {
                await ConfirmBox(textDisplay.msg_004b).then(result => {
                    if (result.isConfirmed) {
                        let isInvalid = false
                        if (store.state.formCreateSummary.drug_name === '') {
                            NotificationMessage(textDisplay.msg_026, 3000, ACTION_TYPE.error)
                            store.state.drug_name_error_text = textDisplay.msg_026
                            isInvalid = true
                        }
                        if (store.state.formCreateSummary.drug_kana === '') {
                            NotificationMessage(textDisplay.msg_030, 3000, ACTION_TYPE.error)
                            store.state.drug_name_kana_error_text = textDisplay.msg_030
                            isInvalid = true
                        }
                        if (isInvalid) {
                            return false
                        }
                        const payload = {
                            drug_name: store.state.formCreateSummary.drug_name,
                            drug_kana: store.state.formCreateSummary.drug_kana,
                            general: store.state.formCreateSummary.general,
                            effect_for_anomaly:
                      store.state.formCreateSummary.effect_for_anomaly,
                            effect_for_baby: store.state.formCreateSummary.effect_for_baby,
                            other: store.state.formCreateSummary.other,
                            placenta_passage: store.state.formCreateSummary.placenta_passage,
                            animal_test: store.state.formCreateSummary.animal_test,
                            attached: store.state.formCreateSummary.attached,
                            summarize: store.state.formCreateSummary.summarize,
                            pharmacological_name:
                      store.state.formCreateSummary.pharmacological_name,
                            major_drug_name: store.state.formCreateSummary.major_drug_name,
                            classification: store.state.formCreateSummary.classification,
                            temp: true
                        }
                        payload.literatures = [
                            ...store.state.formCreateSummary.humanResearchData.map((value) => {
                                delete value.updated_at
                                return { ...value, item: '41' }
                            }),
                            ...store.state.formCreateSummary.humanReportedData.map((value) => {
                                delete value.updated_at
                                return { ...value, item: '42' }
                            }),
                            ...store.state.formCreateSummary.animalResearchResultData.map(
                                (value) => {
                                    delete value.updated_at
                                    return { ...value, item: '43' }
                                }
                            ),
                            ...store.state.formCreateSummary.interviewData.map((value) => {
                                delete value.updated_at
                                return { ...value, item: '44' }
                            }),
                            ...store.state.formCreateSummary.packageInsertData.map((value) => {
                                delete value.updated_at
                                return { ...value, item: '45' }
                            }),
                            ...store.state.formCreateSummary.otherMaterialsData.map(
                                (value) => {
                                    delete value.updated_at
                                    return { ...value, item: '46' }
                                }
                            ),
                            ...store.state.formCreateSummary.motherRiskStatementData.map(
                                (value) => {
                                    delete value.updated_at
                                    return { ...value, item: '50' }
                                }
                            )
                        ]
                        store.state.isLoading = true
                        return store
                            .dispatch('createSummary', payload)
                            .then((result) => {
                                NotificationMessage(
                                    textDisplay.msg_save_to_draft_successfully,
                                    3000,
                                    ACTION_TYPE.success
                                )
                                return true
                            })
                            .catch((e) => {
                                NotificationMessage(e.response.data.message, 3000, ACTION_TYPE.error)
                                if (e.response.data.error_code === 'MSG_027') {
                                    store.state.drug_name_error_text = textDisplay.msg_027
                                }
                                if (e.response.data.error_code === 'MSG_031') {
                                    store.state.drug_name_kana_error_text = textDisplay.msg_031
                                }
                                return false
                            })
                            .finally(() => {
                                store.state.isLoading = false
                            })
                    } else {
                        store.state.drug_name_error_text = ''
                        store.state.drug_name_kana_error_text = ''
                        return true
                    }
                }).then(result => {
                    if (result) {
                        store.state.isSummaryBeingCreate = false
                        store.state.formCreateSummary = {
                            // Public
                            drug_name: '',
                            drug_kana: '',
                            general: '',
                            effect_for_anomaly: '',
                            effect_for_baby: '',
                            other: '',
                            placenta_passage: '',
                            animal_test: '',
                            attached: '',
                            summarize: '',
                            // All Info
                            pharmacological_name: '',
                            major_drug_name: '',
                            classification: '',
                            humanResearchData: [],
                            humanReportedData: [],
                            animalResearchResultData: [],
                            interviewData: [],
                            packageInsertData: [],
                            otherMaterialsData: [],
                            motherRiskStatementData: []
                        }
                    } else {
                        isCancel = true
                    }
                })
            }
            break
        }
        // If isCancel is true which means somethings wrong => prevent navigation
        if (isCancel) {
            return
        }
    }
    if (to.meta.isLoggedIn) {
        if (store.state.user.user_id && new Date().getTime() < new Date(store.state.user.expired_at)) {
            next('/')
        }
    }
    next()
})

export default router
