import Swal from 'sweetalert2'

/**
 * @module Notification
 */

/**
 * @method NotificationMessage
 * @description 通知
 * @param {string} title - 通知ボックスのタイトル
 * @param {number} duration - 通知ボックスが表示される時間
 * @param {string} type - 通知の種類 (成功|警告|エラー|情報|質問)
 */
export const NotificationMessage = (
    title,
    duration,
    type,
    position = 'top-end') => {
    const Toast = Swal.mixin({
        toast: true,
        position: position,
        showConfirmButton: false,
        timer: duration,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    })

    Toast.fire({
        icon: type,
        title: title
    })
}
