<template>
  <ModalComponent @close="$emit('close-modal')">
    <template v-slot:body
      >
      <div class="mb-5 w-full ml-[68px]">
        <label class=" font-bold w-full">{{textDisplay.labelOtherMaterials}}</label>
      </div>
      <div  class="mb-5">
        <label class="  w-full">{{textDisplay.modalOtherMaterials.labelPostscriptDate}}<sup>*</sup></label>
            <MenuInputComponent
            class="w-[685px] ml-[30px]"
            type="date"
            @input="$emit('input-postscript_date-modal', $event.target.value.trim())"
            :input-style="'input_bordered'"
            :value="postscript_date"
            :isDisabled="isView"
            :maxLength="256"
            />
            <p v-if="isShowPostscriptDateNullMessage" class="text-red-500 text-left ml-8">
            {{ textDisplay.msg_popup_postscript_date_is_null }}
        </p>
      </div>
      <div class="mb-5">
        <label class="  w-full">{{textDisplay.modalOtherMaterials.labelDocument}}</label>
        <MenuInputComponent
          class="w-[685px] ml-[30px]"
          type="text"
          @input="$emit('input-document_content-modal', $event.target.value.trim())"
          :input-style="'input_bordered'"
          :value="document_content"
          :isDisabled="isView"
          :maxLength="256"
        />
      </div>
      <div  class="mb-5">
        <label class="  w-full">{{textDisplay.modalOtherMaterials.labelDestinationLink}}</label>
            <MenuInputComponent
            class="w-[685px] ml-[30px]"
            type="text"
            @input="$emit('input-destination_link-modal', $event.target.value.trim())"
            :input-style="'input_bordered'"
            :value="destination_link"
            :isDisabled="isView"
            :maxLength="256"
            />
            <p class="text-red-500 text-left ml-8" v-if="isInvalidDestinationLink">
              {{ textDisplay.modalOtherMaterials.labelDestinationLink + textDisplay.msg_invalid_url }}
            </p>
      </div>
      <div class="flex justify-center">
        <MenuButtonComponent class=" mr-5" btn-style="btn_bordered" :title="textDisplay.buttonModalCancel" @click="cancel"/>
        <MenuButtonComponent :title="textDisplay.buttonModalSave" v-if="!isView" @click="save" :isDisabled="isInvalidDestinationLink"/>
      </div>
    </template>
  </ModalComponent>
</template>

<script>
import ModalComponent from '../../../common/ModalComponent.vue'
import MenuInputComponent from '../../../common/menu/MenuInputComponent.vue'
import MenuButtonComponent from '../../../common/menu/MenuButtonComponent.vue'
import { textDisplay } from '../../../../common/TextDisplay'
import { isValidHttpUrl } from '../../../../common/UsefulFunction'
import { NotificationMessage } from '../../../../common/NotificationMessage'
import { ACTION_TYPE } from '../../../../common/constants'

/**
 * @module OtherMaterialsModalComponent
 */
export default {
    components: { ModalComponent, MenuButtonComponent, MenuInputComponent },
    props: {
        postscript_date: { type: String, require: false },
        document_content: { type: String, require: false },
        destination_link: { type: String, require: false },
        isView: { type: Boolean, default: false, require: false }
    },
    data() {
        return {
            textDisplay,
            isInvalidDestinationLink: false,
            isPostscriptDateNull: this.$props.postscript_date === '',
            isShowPostscriptDateNullMessage: false
        }
    },
    watch: {
        destination_link: function (val) {
            if (!isValidHttpUrl(val)) {
                this.isInvalidDestinationLink = true
            } else {
                this.isInvalidDestinationLink = false
            }
        },
        postscript_date: function (val) {
            if (val === '') {
                this.isPostscriptDateNull = true
                this.isShowPostscriptDateNullMessage = true
            } else {
                this.isPostscriptDateNull = false
                this.isShowPostscriptDateNullMessage = false
            }
        }
    },
    methods: {
        /**
         * 保存ボタンがクリックされたときのトリガーイベント
         */
        save() {
            if (this.isPostscriptDateNull) {
                this.isShowPostscriptDateNullMessage = true
                NotificationMessage(this.textDisplay.msg_popup_postscript_date_is_null, 3000, ACTION_TYPE.error)
            } else {
                this.$emit('save-modal')
            }
        },

        /**
         * キャンセルボタンがクリックされたときのトリガーイベント
         */
        cancel() {
            this.$emit('cancel-modal')
        }
    }
}
</script>

<style scoped>
    sup,
    .error-msg {
    color: #ff4e43;
    }
    sup {
    font-size: 16px;
    }
    label {
        color: #1F2533;
        font-size: 16px;
        display: block;
    }
</style>
