<template>
    <div class="dropdown-container relative">
        <div class="dropdown-btn flex justify-between items-center cursor-pointer"
             @click="toggleMenu()"
            :style="{backgroundColor: bgColor || '#E8F0FF' }"
            :class="isBorder ? 'dropdown-border' : ''"
            >
            <span v-if="selected" :class="{'text-left': textPosition === 'left'}">{{selected.title}}</span>
            <span class="placeholder" v-else :class="{'text-left': textPosition === 'left'}">{{placeholder}}</span>
            <v-icon classNames="ml-2" icon="icon-down" width="10" height="10" v-if="!isDisabled"></v-icon>
        </div>
        <div class="dropdown-menu w-full"
             v-if="open"
             :style="{width: widthMenu + 'px'}"
             :class="{'menu-right': widthMenu > 0, 'font-monospace': textType === 1 }">
            <div class="dropdown-menu-inner w-full overflow-scroll">
                <div class="menu-item cursor-pointer"
                     :class="{'text-left': textPosition === 'left'}"
                     v-for="(item, index) in data"
                     :key="index"
                     @click="selectOption(item)">{{ item.title }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import SVGIconComponent from '../SVGIconComponent'

/**
 * @module MenuDropdownComponent
 */
export default {
    components: {
        vIcon: SVGIconComponent
    },
    props: {
        data: { type: Array, require: false },
        selected: { type: Object, require: false },
        placeholder: { type: String, require: false, default: '' },
        isDisabled: { type: Boolean, require: false, default: false },
        bgColor: { type: String, require: true, default: '#E8F0FF' },
        widthMenu: { type: Number, require: false },
        textPosition: { type: String, require: false },
        isBorder: { type: Boolean, require: false, default: false },
        textType: { type: Number, require: false }
    },
    mounted() {
        window.addEventListener('click', this.closeMenu)
    },
    beforeUnmount() {
        window.removeEventListener('click', this.closeMenu)
    },
    data() {
        return {
            open: false
        }
    },
    methods: {
        /**
         * ドロップダウンメニューを閉じる
         */
        closeMenu(e) {
            if (!this.$el.contains(e.target)) {
                this.open = false
            }
        },

        /**
         * ドロップダウンメニューの表示／非表示
         */
        toggleMenu() {
            if (!this.$props.isDisabled) {
                this.open = !this.open
            }
        },

        /**
         * ドロップダウンの項目を選択する
         */
        selectOption(option) {
            this.$emit('update:modelValue', option)
            this.open = false
        }
    }
}
</script>

<style scoped>
    .font-monospace {
        font-family: monospace;
    }
    .dropdown-border {
        border: 1px solid #CBDDFF
    }

    .dropdown-btn {
        padding: 10px 15px;
        border-radius: 10px;
        height: 40px;
    }

    .dropdown-btn span {
        display: block;
        flex: 1;
    }

    .placeholder {
        color: #CDCDCD;
    }
    .dropdown-menu {
        position: absolute;
        left: 0;
        right: 0;
        z-index: 2;
    }
    .dropdown-menu-inner{
        max-height: 200px;
        background-color: #fff;
        box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
        margin-top: 2px;
    }
    .menu-item {
        padding: 7px 15px;
    }

    .menu-item:hover{
        background-color:  #E8F0FF;
    }
    .menu-right{
        left: auto!important;
        right: 10px!important;
    }
    .menu-right .menu-item{
        text-align: left;
    }
</style>
