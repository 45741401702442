export const textDisplay = {
    error404: 'Not found',
    error500: 'Internal server error',
    error: 'Error',
    error401: 'Unauthorized',
    msg_026: '医薬品名は必須項目です。入力してください。',
    msg_027: '入力された医薬品名は既に存在します。',
    msg_030: '医薬品名カナは必須項目です。入力してください。',
    msg_031: '入力された医薬品名カナは既に存在します。',
    msg_004a: '編集中の内容を一時保存してもよろしいですか？',
    msg_004b: '新規作成中サマリの内容を一時保存してもよろしいですか？',
    msg_006: 'アカウントを削除してもよろしいですか？',
    msg_012: '戻ってよろしいでしょうか？',
    msg_025: 'このサマリを一時保存してよろしいですか。',
    msg_016: '保存が完了致しました。',
    msg_logged_in: 'すでにログインしています。',
    msg_invalid_url: 'のフォーマットは正しくありません。もう一度やり直してください。',
    msg_save_to_draft: '一時保存してよろしいでしょうか？',
    msg_token_expired: 'あなたのセッションの有効期限が切れました。',
    msg_save_to_draft_successfully: '一時保存を完了致しました。',
    msg_approve_alert: '確認してよろしいでしょうか？',
    msg_approve_successfully: '確認しました。',
    msg_delete_user_successfully: 'ユーザーの削除が完了しました。',
    msg_020: '検索条件に一致するサマリがありません。',
    msg_033: 'このサマリを保存してよろしいですか。',
    msg_popup_title_is_null: 'タイトルは必須項目です。入力して下さい。',
    msg_popup_postscript_date_is_null: '追記年月日は必須項目です。入力して下さい。',

    // Auth
    nullUserId: '「ユーザーID」を入力してください。',
    nullPassword: '「パスワード」を入力してください。',
    errorLogin: 'ユーザーID、パスワードが一致しません。',
    inputUserId: 'ユーザーID*',
    inputPassword: 'パスワード*',
    buttonLogin: '認証',
    loginHeader: 'ユーザー認証',
    userIdInputTitleLogin: 'ユーザーID',
    passwordInputTitleLogin: 'パスワード',
    logoutTitle: 'ログアウト',
    logoutConfirm: 'ログアウトしました。',
    logoutBtn: 'ユーザー認証画面に戻る。',
    buttonCopyPassword: 'コピー',

    // Pages
    isLoading: false,
    tableLastColumnTitle: '操作',
    tableEditButton: '編集',
    tableDeleteButton: '削除',
    tableUserListHeader: [
        { title: 'ユーザー名称', field: 'username', type: 1 },
        { title: 'ユーザーID', field: 'user_id', type: 1 },
        { title: '権限', field: 'authority', type: 2 }
    ],
    sidebarList: [
        {
            title: 'メインメニュー',
            to: '/',
            icon: 'icon-home',
            active_icon: 'icon-home-active',
            name: ['MainMenu']
        },
        {
            title: 'サマリ一覧表示',
            to: '/summary/search-summary',
            icon: 'icon-search',
            active_icon: 'icon-search-active',
            name: ['SearchSummary', 'EditSummary', 'ViewSummary']
        },
        { title: 'サマリ新規作成', to: '/summary/create-summary', active_icon: 'icon-summary-active', icon: 'icon-summary', name: ['CreateSummary'] },
        { title: 'ユーザー管理', to: '/users/list', icon: 'icon-user', active_icon: 'icon-user-active', name: ['ListUser', 'CreateUser', 'EditUser'] }
    ],
    sidebarListForGeneral: [
        {
            title: 'メインメニュー',
            to: '/',
            icon: 'icon-home',
            active_icon: 'icon-home-active',
            name: ['MainMenu']
        },
        {
            title: 'サマリ一覧表示',
            to: '/summary/search-summary',
            icon: 'icon-search',
            active_icon: 'icon-search-active',
            name: ['SearchSummary', 'EditSummary', 'ViewSummary']
        },
        { title: 'サマリ新規作成', to: '/summary/create-summary', active_icon: 'icon-summary-active', icon: 'icon-summary', name: ['CreateSummary'] }
    ],
    // Button
    buttonRouteToCreateUser: '新規作成',
    buttonCreateUser: '保存',
    buttonBackCreateUser: '戻る',

    // Input
    inputUsernameTitle: 'ユーザー名称',
    inputUserIdTitle: 'ユーザーID',
    inputUserIdNote: '(半角数字のみ）',
    inputPasswordTitle: 'パスワード',
    inputRoleTitle: '権限',
    btnResetPassword: 'パスワードをリセット',
    confirmResetPasswordTitle: '「ユーザーID」のパスワードをリセットしてよろしいですか？',
    btnCancelResetPassword: 'いいえ',
    btnSubmitResetPassword: 'はい',
    btnCopyPassword: 'コピー',
    copyPasswordSuccess: 'パスワードがクリップボードにコピーされました。',
    // Select Option
    selectoptionAutority: [
        { title: '一般', value: 0 },
        { title: '管理者', value: 1 }
    ],

    // Warning
    warningUsernameNull: 'ユーザー名称を入力してください。',
    warningPasswordNull: 'パスワードを入力してください。',
    warningUserIdNull: 'ユーザーIDを入力してください。',
    warningAuthorityNull: '権限を選択してください。',

    // Create Summary
    buttonHold: '一時保存',
    buttonSave: '保存',
    // Public
    labelDrugName: '医薬品名',
    labelGeneralInfo: '一般情報',
    labelKana: '医薬品名カナ',
    labelHuman: 'ヒトでの主な情報',
    labelEffectForAnomaly: '先天異常への影響',
    labelEffectForBaby: '先天異常以外の胎児への影響',
    labelOther: 'その他',
    labelPlacentaPassage: '胎盤通過性',
    labelAnimalTest: '動物試験',
    labelAttachment: '添付文書記載内容',
    labelSummarize: 'まとめ',
    labelStatus: 'ステータス',
    labelUpdatedBy: '最終更新者',
    labelUpdatedAt: '最終更新日',
    labelApprovedBy: '最終承認者',
    labelApprovedAt: '最終承認日',
    labelPublic: '公開',
    labelAllInfo: '全情報',
    // All Info
    labelTitle: '医薬品に対する文献情報',
    labelPharmacologicalName: '1. 薬理名',
    labelMajorDrugName: '2. 主要商品名',
    labelClassification: '3. 分類',
    labelReferenceDocuments: '4. 文献的考察',
    labelHumanResearch: '4-1. 人間での疫学研究',
    labelInHumanReported: '4-2. 人間での症例報告',
    labelAnimalResearchResult: '4-3. 動物実験結果',
    labelByInterview: '4-4. インタビューフォーム',
    labelPackageInsert: '4-5. 添付文書',
    labelOtherMaterials: '4-6．その他資料',
    labelGrowthSummary: '5. 成育サマリー',
    labelMotherRiskStatement: '5. マザーリスクステートメント',
    labelModalReference: '文献情報',
    labelModalReferencePage: '参考頁',
    labelModalEndnoteLink: 'EndNoteリンク',
    labelModalUpdateDate: '更新日',
    buttonModalCancel: 'キャンセル',
    buttonModalSave: '完了',
    tableHumanResearchHeader: [
        { title: '掲載誌', field: 'publication_magazine', type: 5, numCol: 6, maxLength: 25, maxLengthEn: 38 },
        { title: 'タイトル', field: 'title', type: 5, classCol: 'col-2', numCol: 2, maxLength: 16 },
        { title: '著者', field: 'author', type: 5, classCol: 'col-2', numCol: 2, maxLength: 16 },
        { title: 'EndNoteリンク', field: 'endnote_link', type: 6, classCol: 'col-2', numCol: 2, maxLength: 15 }
    ],
    tableHumanResearchHeaderInView: [
        { title: '掲載誌', field: 'publication_magazine', type: 5, numCol: 6, maxLength: 25, maxLengthEn: 38 },
        { title: 'タイトル', field: 'title', type: 5, classCol: 'col-2', numCol: 2, maxLength: 16 },
        { title: '著者', field: 'author', type: 5, classCol: 'col-2', numCol: 2, maxLength: 16 },
        { title: 'EndNoteリンク', field: 'endnote_link', type: 6, classCol: 'col-2', numCol: 2, maxLength: 15 }
    ],
    tableHumanReportedHeader: [
        { title: '掲載誌', field: 'publication_magazine', type: 5, numCol: 6, maxLength: 25, maxLengthEn: 38 },
        { title: 'タイトル', field: 'title', type: 5, classCol: 'col-2', numCol: 2, maxLength: 16 },
        { title: '著者', field: 'author', type: 5, classCol: 'col-2', numCol: 2, maxLength: 16 },
        { title: 'EndNoteリンク', field: 'endnote_link', type: 6, classCol: 'col-2', numCol: 2, maxLength: 15 }
    ],
    tableHumanReportedHeaderInView: [
        { title: '掲載誌', field: 'publication_magazine', type: 5, numCol: 6, maxLength: 25, maxLengthEn: 38 },
        { title: 'タイトル', field: 'title', type: 5, classCol: 'col-2', numCol: 2, maxLength: 16 },
        { title: '著者', field: 'author', type: 5, classCol: 'col-2', numCol: 2, maxLength: 16 },
        { title: 'EndNoteリンク', field: 'endnote_link', type: 6, classCol: 'col-2', numCol: 2, maxLength: 15 }
    ],
    tableAnimalTestHeader: [
        { title: '掲載誌', field: 'publication_magazine', type: 5, numCol: 6, maxLength: 25, maxLengthEn: 38 },
        { title: 'タイトル', field: 'title', type: 5, classCol: 'col-2', numCol: 2, maxLength: 16 },
        { title: '著者', field: 'author', type: 5, classCol: 'col-2', numCol: 2, maxLength: 16 },
        { title: 'EndNoteリンク', field: 'endnote_link', type: 6, classCol: 'col-2', numCol: 2, maxLength: 15 }
    ],
    tableAnimalTestHeaderInView: [
        { title: '掲載誌', field: 'publication_magazine', type: 5, numCol: 6, maxLength: 25, maxLengthEn: 38 },
        { title: 'タイトル', field: 'title', type: 5, classCol: 'col-2', numCol: 2, maxLength: 16 },
        { title: '著者', field: 'author', type: 5, classCol: 'col-2', numCol: 2, maxLength: 16 },
        { title: 'EndNoteリンク', field: 'endnote_link', type: 6, classCol: 'col-2', numCol: 2, maxLength: 15 }
    ],
    tablInterviewHeader: [
        { title: '追記年月日', field: 'postscript_date', type: 5, numCol: 4, maxLength: 25 },
        { title: '商品名', field: 'product_name', type: 5, classCol: 'col-3', numCol: 3, maxLength: 16 },
        { title: '版数', field: 'version', type: 5, classCol: 'col-2', numCol: 2, maxLength: 16 },
        { title: 'PDFリンク先', field: 'pdf_link', type: 6, classCol: 'col-3', numCol: 3, maxLength: 25 }
    ],
    tablInterviewHeaderInView: [
        { title: '追記年月日', field: 'postscript_date', type: 5, numCol: 4, maxLength: 25 },
        { title: '商品名', field: 'product_name', type: 5, classCol: 'col-3', numCol: 3, maxLength: 16 },
        { title: '版数', field: 'version', type: 5, classCol: 'col-2', numCol: 2, maxLength: 16 },
        { title: 'PDFリンク先', field: 'pdf_link', type: 6, classCol: 'col-3', numCol: 3, maxLength: 25 }
    ],
    tablePackageInsertHeader: [
        { title: '追記年月日', field: 'postscript_date', type: 5, numCol: 4, maxLength: 25 },
        { title: '参考頁', field: 'reference_page', type: 5, classCol: 'col-3', numCol: 3, maxLength: 16 },
        { title: '版数', field: 'version', type: 5, classCol: 'col-2', numCol: 2, maxLength: 16 },
        { title: 'PDFリンク先', field: 'pdf_link', type: 6, classCol: 'col-3', numCol: 3, maxLength: 25 }
    ],
    tablePackageInsertHeaderInView: [
        { title: '追記年月日', field: 'postscript_date', type: 5, numCol: 4, maxLength: 25 },
        { title: '参考頁', field: 'reference_page', type: 5, classCol: 'col-3', numCol: 3, maxLength: 16 },
        { title: '版数', field: 'version', type: 5, classCol: 'col-2', numCol: 2, maxLength: 16 },
        { title: 'PDFリンク先', field: 'pdf_link', type: 6, classCol: 'col-3', numCol: 3, maxLength: 25 }
    ],
    // Pending (destination_link)
    tableOtherMaterialsHeader: [
        { title: '追記年月日', field: 'postscript_date', type: 5, numCol: 4, maxLength: 12 },
        { title: '資料内容', field: 'document_content', type: 5, classCol: 'col-5', numCol: 5, maxLength: 21 },
        { title: 'リンク先', field: 'destination_link', type: 6, classCol: 'col-3', numCol: 3, maxLength: 25 }
    ],
    tableOtherMaterialsHeaderInView: [
        { title: '追記年月日', field: 'postscript_date', type: 5, numCol: 4, maxLength: 12 },
        { title: '資料内容', field: 'document_content', type: 5, classCol: 'col-5', numCol: 5, maxLength: 21 },
        { title: 'リンク先', field: 'destination_link', type: 6, classCol: 'col-3', numCol: 3, maxLength: 25 }
    ],
    tableMotherRiskStatementHeader: [
        { title: '追記年月日', field: 'postscript_date', type: 5, numCol: 4, maxLength: 25 },
        { title: '年', field: 'year', type: 5, classCol: 'col-2', numCol: 2, maxLength: 16 },
        { title: 'リンク先', field: 'destination_link', type: 6, classCol: 'col-6', numCol: 6, maxLength: 50 }
    ],
    tableMotherRiskStatementHeaderInView: [
        { title: '追記年月日', field: 'postscript_date', type: 5, numCol: 4, maxLength: 25 },
        { title: '年', field: 'year', type: 5, classCol: 'col-2', numCol: 2, maxLength: 16 },
        { title: 'リンク先', field: 'destination_link', type: 6, classCol: 'col-6', numCol: 6, maxLength: 50 }
    ],
    buttonAddRow: '行追加',
    buttonDeleteRow: '行削除',

    // Search Summary
    labelSearchDonotSelect: 'サマリを選択してください。',
    labelSearchTitle: '条件検索',
    labelSearchDrugName: '医薬品名',
    labelSearchPharmacologicalName: '薬理名',
    labelSearchMajorDrugName: '主要商品名',
    labelSearchClassification: '分類',
    labelSearchSort: '並び順',
    labelSearchStatus: 'ステータス',
    labelSearchSelectOptionTitle: '選択',
    buttonSearchClear: 'クリア',
    buttonSearchSearch: '検索',
    labelSearchResult: '検索結果',
    buttonSearchView: '参照',
    buttonSearchEdit: '編集',
    buttonSearchDelete: '削除',
    tableSearchResult: [
        { title: '医薬品名', field: 'drug_name', type: 1, maxLength: 30 },
        { title: '薬理名', field: 'pharmacological_name', type: 1, maxLength: 30 },
        { title: '主要商品名', field: 'major_drug_name', type: 1, maxLength: 30 },
        { title: '分類', field: 'classification', type: 1, maxLength: 30 },
        { title: 'ステータス', field: 'status', type: 1, maxLength: 30 }
    ],
    selectOptionSearch1: [
        { title: '医薬品名', value: 'drug_name' },
        { title: '薬理名', value: 'pharmacological_name' },
        { title: '主要商品名', value: 'major_drug_name' },
        { title: '分類', value: 'classification' }
    ],
    selectOptionSearch2: [
        { title: '昇順', value: 'asc' },
        { title: '降順', value: 'desc' }
    ],
    selectOptionSearchStatus: [
        { title: '公開済み', value: 0 },
        { title: '一時保存', value: 1 },
        { title: '確認待ち', value: 2 },
        { title: 'すべて', value: 3 }
    ],
    selectOptionSummaryInDBStatus: [
        { title: '一時保存', value: 0 },
        { title: '確認待ち', value: 1 },
        { title: '公開済み', value: 2 }
    ],
    // View + Edit Summary (also use label from Create Summary)
    buttonViewSummaryEdit: '編集',
    buttonEditSaveToDraft: '一時保存',
    buttonExportPDF: 'PDF 出力',
    buttonViewSummarySave: '保存',
    buttonViewSummaryConfirm: '確認',
    radioExportPublicInfo: '公開情報のみ',
    radioExportAllInfo: '公開情報＋全情報',
    titleBoxConfirmExportPdf: '出力したいタブを選択してください。',
    btnExportPdf: 'PDF 出力',
    mainMenu: {
        title: '成育サマリ',
        menuSearchSummary: { label: 'サマリ一覧表示', to: '/summary/search-summary' },
        menuCreateSummary: { label: 'サマリ新規作成', to: '/summary/create-summary' },
        menuUser: { label: 'ユーザー管理', to: '/users/list' }
    },
    selectOptionRecruitment: [
        { title: '○', value: true },
        { title: '×', value: false }
    ],

    // Modal
    modalHumanResearch: {
        labelYearCountry: '年・国',
        labelAuthor: '著者',
        labelPublicationMagazine: '掲載誌',
        labelTitle: 'タイトル',
        labelDesign: 'デザイン',
        labelExposureNvsControlN: '暴露N vs コントロールN',
        labelOutcomes: 'アウトカム',
        labelResult: '結果',
        labelRecruitment: '採用',
        labelReason: '理由',
        labelBRTM: 'BRTM',
        labelMemo: 'メモ',
        labelEndNoteLink: 'EndNoteリンク'
    },
    modalHumanReported: {
        labelYearCountry: '年・国',
        labelAuthor: '著者',
        labelPublicationMagazine: '掲載誌',
        labelTitle: 'タイトル',
        labelResult: '結果',
        labelRecruitment: '採用',
        labelJudgmentReason: '判断理由',
        labelBRTM: 'BRTM',
        labelMemo: 'メモ',
        labelEndNoteLink: 'EndNoteリンク'
    },
    modalAnimalResearchResult: {
        labelYearCountry: '年・国',
        labelAuthor: '著者',
        labelPublicationMagazine: '掲載誌',
        labelTitle: 'タイトル',
        labelAnimalSpecies: '動物種',
        labelOutcomes: 'アウトカム',
        labelDose: '用量',
        labelResult: '結果',
        labelBRTM: 'BRTM',
        labelMemo: 'メモ',
        labelEndNoteLink: 'EndNoteリンク'
    },
    modalInterview: {
        labelPostscriptDate: '追記年月日',
        labelProductName: '商品名',
        labelVersion: '版数',
        labelPDFLink: 'PDF リンク先'
    },
    modalPackageInsert: {
        labelPostscriptDate: '追記年月日',
        labelReferences: '参考頁',
        labelVersion: '版数',
        labelPDFLink: 'PDF リンク先'
    },
    modalOtherMaterials: {
        labelPostscriptDate: '追記年月日',
        labelDocument: '資料内容',
        labelDestinationLink: 'リンク先'
    },
    modalMotherRiskStatement: {
        labelPostscriptDate: '追記年月日',
        labelYear: '年',
        labelDestinationLink: 'リンク先'
    },
    selectOptionYear: [
        { title: '1920年', value: '1920' },
        { title: '1921年', value: '1921' },
        { title: '1922年', value: '1922' },
        { title: '1923年', value: '1923' },
        { title: '1924年', value: '1924' },
        { title: '1925年', value: '1925' },
        { title: '1926年', value: '1926' },
        { title: '1927年', value: '1927' },
        { title: '1928年', value: '1928' },
        { title: '1929年', value: '1929' },
        { title: '1930年', value: '1930' },
        { title: '1931年', value: '1931' },
        { title: '1932年', value: '1932' },
        { title: '1933年', value: '1933' },
        { title: '1934年', value: '1934' },
        { title: '1935年', value: '1935' },
        { title: '1936年', value: '1936' },
        { title: '1937年', value: '1937' },
        { title: '1938年', value: '1938' },
        { title: '1939年', value: '1939' },
        { title: '1940年', value: '1940' },
        { title: '1941年', value: '1941' },
        { title: '1942年', value: '1942' },
        { title: '1943年', value: '1943' },
        { title: '1944年', value: '1944' },
        { title: '1945年', value: '1945' },
        { title: '1946年', value: '1946' },
        { title: '1947年', value: '1947' },
        { title: '1948年', value: '1948' },
        { title: '1949年', value: '1949' },
        { title: '1950年', value: '1950' },
        { title: '1951年', value: '1951' },
        { title: '1952年', value: '1952' },
        { title: '1953年', value: '1953' },
        { title: '1954年', value: '1954' },
        { title: '1955年', value: '1955' },
        { title: '1956年', value: '1956' },
        { title: '1957年', value: '1957' },
        { title: '1958年', value: '1958' },
        { title: '1959年', value: '1959' },
        { title: '1960年', value: '1960' },
        { title: '1961年', value: '1961' },
        { title: '1962年', value: '1962' },
        { title: '1963年', value: '1963' },
        { title: '1964年', value: '1964' },
        { title: '1965年', value: '1965' },
        { title: '1966年', value: '1966' },
        { title: '1967年', value: '1967' },
        { title: '1968年', value: '1968' },
        { title: '1969年', value: '1969' },
        { title: '1970年', value: '1970' },
        { title: '1971年', value: '1971' },
        { title: '1972年', value: '1972' },
        { title: '1973年', value: '1973' },
        { title: '1974年', value: '1974' },
        { title: '1975年', value: '1975' },
        { title: '1976年', value: '1976' },
        { title: '1977年', value: '1977' },
        { title: '1978年', value: '1978' },
        { title: '1979年', value: '1979' },
        { title: '1980年', value: '1980' },
        { title: '1981年', value: '1981' },
        { title: '1982年', value: '1982' },
        { title: '1983年', value: '1983' },
        { title: '1984年', value: '1984' },
        { title: '1985年', value: '1985' },
        { title: '1986年', value: '1986' },
        { title: '1987年', value: '1987' },
        { title: '1988年', value: '1988' },
        { title: '1989年', value: '1989' },
        { title: '1990年', value: '1990' },
        { title: '1991年', value: '1991' },
        { title: '1992年', value: '1992' },
        { title: '1993年', value: '1993' },
        { title: '1994年', value: '1994' },
        { title: '1995年', value: '1995' },
        { title: '1996年', value: '1996' },
        { title: '1997年', value: '1997' },
        { title: '1998年', value: '1998' },
        { title: '1999年', value: '1999' },
        { title: '2000年', value: '2000' },
        { title: '2001年', value: '2001' },
        { title: '2002年', value: '2002' },
        { title: '2003年', value: '2003' },
        { title: '2004年', value: '2004' },
        { title: '2005年', value: '2005' },
        { title: '2006年', value: '2006' },
        { title: '2007年', value: '2007' },
        { title: '2008年', value: '2008' },
        { title: '2009年', value: '2009' },
        { title: '2010年', value: '2010' },
        { title: '2011年', value: '2011' },
        { title: '2012年', value: '2012' },
        { title: '2013年', value: '2013' },
        { title: '2014年', value: '2014' },
        { title: '2015年', value: '2015' },
        { title: '2016年', value: '2016' },
        { title: '2017年', value: '2017' },
        { title: '2018年', value: '2018' },
        { title: '2019年', value: '2019' },
        { title: '2020年', value: '2020' },
        { title: '2021年', value: '2021' },
        { title: '2022年', value: '2022' },
        { title: '2023年', value: '2023' },
        { title: '2024年', value: '2024' },
        { title: '2025年', value: '2025' },
        { title: '2026年', value: '2026' },
        { title: '2027年', value: '2027' },
        { title: '2028年', value: '2028' },
        { title: '2029年', value: '2029' },
        { title: '2030年', value: '2030' },
        { title: '2031年', value: '2031' },
        { title: '2032年', value: '2032' },
        { title: '2033年', value: '2033' },
        { title: '2034年', value: '2034' },
        { title: '2035年', value: '2035' },
        { title: '2036年', value: '2036' },
        { title: '2037年', value: '2037' },
        { title: '2038年', value: '2038' },
        { title: '2039年', value: '2039' },
        { title: '2040年', value: '2040' },
        { title: '2041年', value: '2041' },
        { title: '2042年', value: '2042' },
        { title: '2043年', value: '2043' },
        { title: '2044年', value: '2044' },
        { title: '2045年', value: '2045' },
        { title: '2046年', value: '2046' },
        { title: '2047年', value: '2047' },
        { title: '2048年', value: '2048' },
        { title: '2049年', value: '2049' },
        { title: '2050年', value: '2050' }
    ]

}
