<template>
    <ul class="flex flex-row items-center">
        <li v-if="!(firstPageSelected() && hidePrevNext)"
            class="prev-page flex items-center justify-center"
            :class="[firstPageSelected() ? disabledClass : '']"
            @click="prevPage()"
            @keyup.enter="prevPage()"
            :tabindex="firstPageSelected() ? -1 : 0">
            <v-icon icon="icon-prev"></v-icon>
        </li>

        <li v-for="page in pages" :key="page"
            class="page-item flex items-center justify-center"
            :class="[pageClass, page.selected ? activeClass : '', page.disabled ? disabledClass : '', page.breakView ? breakViewClass: '']">
            <a v-if="page.breakView" tabindex="0">
                <slot name="breakViewContent">{{ breakViewText }}</slot>
            </a>
            <a v-else-if="page.disabled" tabindex="0">{{ page.content }}</a>
            <a v-else
               @click="handlePageSelected(page.index + 1)"
               @keyup.enter="handlePageSelected(page.index + 1)"
               tabindex="0">{{ page.content }}</a>
        </li>

        <li v-if="!(lastPageSelected() && hidePrevNext)"
            class="next-page flex items-center justify-center"
            :class="[lastPageSelected() ? disabledClass : '']"
            @click="nextPage()"
            @keyup.enter="nextPage()"
            :tabindex="lastPageSelected() ? -1 : 0">
            <v-icon icon="icon-next"></v-icon>
        </li>
    </ul>
</template>

<script>
import SVGIconComponent from './SVGIconComponent'

/**
 * @module PaginationComponent
 */
export default {
    props: {
        value: {
            type: Number
        },
        pageCount: {
            type: Number,
            required: true
        },
        forcePage: {
            type: Number
        },
        clickHandler: {
            type: Function,
            default: () => { }
        },
        pageRange: {
            type: Number,
            default: 3
        },
        marginPages: {
            type: Number,
            default: 1
        },
        breakViewText: {
            type: String,
            default: '…'
        },
        pageClass: {
            type: String
        },
        breakViewClass: {
            type: String
        },
        activeClass: {
            type: String,
            default: 'active'
        },
        disabledClass: {
            type: String,
            default: 'disabled'
        },
        hidePrevNext: {
            type: Boolean,
            default: false
        }
    },
    beforeUpdate() {
        if (this.forcePage === undefined) return
        if (this.forcePage !== this.selected) {
            this.selected = this.forcePage
        }
    },
    computed: {
        selected: {
            get: function () {
                return this.value || this.innerValue
            },
            set: function (newValue) {
                this.innerValue = newValue
            }
        },
        pages: function () {
            const items = {}
            if (this.pageCount <= this.pageRange) {
                for (let index = 0; index < this.pageCount; index++) {
                    const page = {
                        index: index,
                        content: index + 1,
                        selected: index === (this.selected - 1)
                    }
                    items[index] = page
                }
            } else {
                const halfPageRange = Math.floor(this.pageRange / 2)
                const setPageItem = index => {
                    const page = {
                        index: index,
                        content: index + 1,
                        selected: index === (this.selected - 1)
                    }
                    items[index] = page
                }
                const setBreakView = index => {
                    const breakView = {
                        disabled: true,
                        breakView: true
                    }
                    items[index] = breakView
                }
                // 1st - loop thru low end of margin pages
                for (let i = 0; i < this.marginPages; i++) {
                    setPageItem(i)
                }
                // 2nd - loop thru selected range
                let selectedRangeLow = 0
                if (this.selected - halfPageRange > 0) {
                    selectedRangeLow = this.selected - 1 - halfPageRange
                }
                let selectedRangeHigh = selectedRangeLow + this.pageRange - 1
                if (selectedRangeHigh >= this.pageCount) {
                    selectedRangeHigh = this.pageCount - 1
                    selectedRangeLow = selectedRangeHigh - this.pageRange + 1
                }
                for (let i = selectedRangeLow; i <= selectedRangeHigh && i <= this.pageCount - 1; i++) {
                    setPageItem(i)
                }
                // Check if there is breakView in the left of selected range
                if (selectedRangeLow > this.marginPages) {
                    setBreakView(selectedRangeLow - 1)
                }
                // Check if there is breakView in the right of selected range
                if (selectedRangeHigh + 1 < this.pageCount - this.marginPages) {
                    setBreakView(selectedRangeHigh + 1)
                }
                // 3rd - loop thru high end of margin pages
                for (let i = this.pageCount - 1; i >= this.pageCount - this.marginPages; i--) {
                    setPageItem(i)
                }
            }
            return items
        }
    },
    data() {
        return {
            innerValue: 1
        }
    },
    methods: {
        /**
         * ページインデックスを選択する
         * @param {number} selected - ページインデックス
         */
        handlePageSelected(selected) {
            if (this.selected === selected) return
            this.innerValue = selected
            this.$emit('input', selected)
            this.clickHandler(selected)
        },

        /**
         * 前のページに戻る
         */
        prevPage() {
            if (this.selected <= 1) return
            this.handlePageSelected(this.selected - 1)
        },

        /**
         * 次のページへのルート
         */
        nextPage() {
            if (this.selected >= this.pageCount) return
            this.handlePageSelected(this.selected + 1)
        },

        /**
         * 最初のページへのルート
         */
        firstPageSelected() {
            return this.selected === 1
        },

        /**
         * 最終ページへのルート
         */
        lastPageSelected() {
            return (this.selected === this.pageCount) || (this.pageCount === 0)
        },

        /**
         * 最初のページへのルート
         */
        selectFirstPage() {
            if (this.selected <= 1) return
            this.handlePageSelected(1)
        },

        /**
         * 最初のページへのルート
         */
        selectLastPage() {
            if (this.selected >= this.pageCount) return
            this.handlePageSelected(this.pageCount)
        }
    },
    components: {
        vIcon: SVGIconComponent
    }
}
</script>

<style scoped>
    li.page-item {
        min-width: 24px;
        height: 24px;
        color: #CDCDCD;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        background-color: inherit;
        cursor: pointer;
        margin-right: 5px;
        border-radius: 50%;
        transition: all .2s ease-in;
    }
    li.page-item a {
        display: block;
        width: 100%;
    }
    li.page-item.active{
        color:  #FBFBFB!important;
        background-color: #015DB2!important;
    }
    li.page-item:hover, li.next-page:hover, li.prev-page:hover{
        background-color: #E8F0FF;
        color:  #1F2533;
    }
    li.next-page, li.prev-page {
        cursor: pointer;
        width: 25px;
        height: 25px;
        border-radius: 50%;
    }
    li.next-page{
        margin-left: 10px;
    }
    li.next-page svg{
        margin-left: 3px;
        margin-bottom: 1px;
    }
    li.prev-page{
        margin-right: 10px;
    }
    li.prev-page svg{
        margin-right: 3px;
        margin-bottom: 1px;
    }
    li svg {
        width: 14px;
        height: 14px;
        stroke: #1F2533;
    }
    li.disabled svg {
        stroke: #CDCDCD!important;
        cursor: default;
    }
</style>
